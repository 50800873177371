import classNames from 'classnames';
import { Moment } from 'moment';
import React, { Fragment, useMemo } from 'react';

import { makeStyles } from '@mui/styles';

import {
  AppointmentStatus,
  EncounterMethodOutcome,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PaginationType,
  SkilledItem,
  TOCAcuityLevel,
  TOCItem,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Chip,
  Colors,
  Icon,
  MemberExtraInfo,
  MemberInfo,
  SelectAssignee,
  StatusIndicator,
  StatusVisit,
  Table,
  UNASSIGNED,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import Session from 'dash/src/services/SessionServices';

import {
  TOC_TAB_ALL,
  TOC_TAB_DISCHARGED_INITIAL,
  TOC_TAB_DISCHARGED_POST,
  TOC_TAB_HANDOFF,
  TOC_TAB_INPATIENT,
  TOC_TAB_REHAB,
  TOC_TOGGLE_ITEM_SKILLED,
  TOC_TOGGLE_ITEM_TOC,
} from './types';

interface Props {
  employees?: Employee[];
  items: (TOCItem | SkilledItem)[];
  onChangeAssignee: (row: TOCItem, assignee: Employee) => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (page: number) => void;
  onClickRow: (row: TOCItem | SkilledItem) => void;
  readonly?: boolean;
  toggle: string;
  type: string;
  pagination?: PaginationType;
  pageSize?: number;
}

const getEncounterColor = (status?: string) => {
  switch (status) {
    case EncounterMethodOutcome.SUCCESSFUL.toString():
    case AppointmentStatus.COMP.toString():
      return 'success';
    case EncounterMethodOutcome.INVALID_EMAIL.toString():
    case EncounterMethodOutcome.INVALID_FAX_NUMBER.toString():
    case EncounterMethodOutcome.PHONE_NUMBER_INVALID.toString():
    case EncounterMethodOutcome.UNABLE_TO_CONNECT.toString():
    case AppointmentStatus.N_S.toString():
    case AppointmentStatus.R_S.toString():
    case undefined:
      return 'error';
    default:
      return 'warning';
  }
};

const getHandoffStatusColor = (status?: string) => {
  switch (status) {
    case 'COMPLETED':
      return 'success';
    case 'UNREACHABLE':
    case 'WITHDREW':
      return 'closed';
    default:
      return 'warning';
  }
};

const getDateWithDiffDays = (date?: Moment, days?: number) => (
  <>
    {date?.format(DATE_FORMAT_SHORT) || EMPTY}{' '}
    {date && days !== undefined && (
      <Chip color="action" size="small">
        <span>{days}</span>
      </Chip>
    )}
  </>
);

const TOC_NP_COLUMN = {
  headerName: translate('toc.table.npVisit'),
  component: ({ id, npVisit }: TOCItem) => (
    <StatusVisit
      color={getEncounterColor(npVisit?.details)}
      key={`toc-item-${id}-np-visit`}
      date={npVisit?.dateTime || npVisit?.date}
      tooltip={
        npVisit ? (
          <>
            <p className="flex spaced min-gap no-margin white">
              <span>{translate('toc.table.type')}:</span>
              <span>{npVisit?.type}</span>
            </p>
            <p className="flex spaced min-gap no-margin white">
              <span>{translate('toc.table.status')}:</span>
              <span>{npVisit?.details}</span>
            </p>
          </>
        ) : undefined
      }
    />
  ),
};

const TOC_RN_COLUMN = {
  headerName: translate('toc.table.rnCarePlan'),
  component: ({ id, rnCarePlan }: TOCItem) => (
    <StatusVisit
      color={getEncounterColor(rnCarePlan?.details)}
      key={`toc-item-${id}-rn-visit`}
      date={rnCarePlan?.dateTime || rnCarePlan?.date}
      tooltip={
        rnCarePlan ? (
          <>
            <p className="flex spaced min-gap no-margin white">
              <span>{translate('toc.table.type')}:</span>
              <span>{rnCarePlan?.type}</span>
            </p>
            <p className="flex spaced min-gap no-margin white">
              <span>{translate('toc.table.status')}:</span>
              <span>{rnCarePlan?.details}</span>
            </p>
          </>
        ) : undefined
      }
    />
  ),
};

const TOC_LIST_COLUMNS = (
  toggle: string,
  type: string,
  { employees, onChangeAssignee, readonly }: Props,
) => {
  let columns: Column[] = [
    {
      headerName: translate('common.member'),
      component: ({ member }: TOCItem | SkilledItem) => (
        <MemberInfo
          link={`#/patients/${member?.id}`}
          key={`toc-row-${member?.id}-member-info`}
          member={member}
          showBirthDate={toggle === TOC_TOGGLE_ITEM_TOC}
          showProgramExtensions={toggle === TOC_TOGGLE_ITEM_SKILLED}
          showState
        />
      ),
      width: 200,
    },
  ];

  if (toggle === TOC_TOGGLE_ITEM_SKILLED) {
    columns = [
      ...columns,
      ...[
        {
          headerName: translate('toc.table.referralSource'),
          component: ({ member }: SkilledItem) => (
            <MemberExtraInfo
              key={`toc-row-${member?.id}-extra-member-info`}
              activeReferrals={member?.activeReferrals}
            />
          ),
          width: 200,
        },
        {
          headerName: translate('toc.table.episodeStartDate'),
          component: ({ startDate, episodeDuration }: SkilledItem) => (
            <p className="no-margin">
              {getDateWithDiffDays(startDate, episodeDuration)}
            </p>
          ),
          width: 200,
        },
        {
          headerName: translate('toc.table.episodeEndDate'),
          component: ({ endDate, businessDaysSinceEndDate }: SkilledItem) => (
            <p className="no-margin">
              {getDateWithDiffDays(endDate, businessDaysSinceEndDate)}
            </p>
          ),
          width: 200,
        },
      ],
    ];
  }

  if (toggle === TOC_TOGGLE_ITEM_TOC) {
    if (type === TOC_TAB_ALL) {
      columns.push({
        headerName: translate('toc.table.status'),
        component: ({ id, status, statusUpdatedAt }: TOCItem) => (
          <Fragment key={`toc-item-${id}-status`}>
            <p className="no-margin semi-bold">{status?.description}</p>
            <p className="no-margin">
              {statusUpdatedAt?.format(DATE_FORMAT_SHORT) || EMPTY}
            </p>
          </Fragment>
        ),
      });
    } else if (
      [TOC_TAB_DISCHARGED_INITIAL, TOC_TAB_DISCHARGED_POST].includes(type)
    ) {
      columns.push({
        headerName: translate('toc.table.dischargeDate'),
        component: ({
          id,
          dischargeDate,
          businessDaysSinceDischargeDate,
        }: TOCItem) => (
          <Fragment key={`toc-item-${id}-status`}>
            <p className="no-margin">
              {getDateWithDiffDays(
                dischargeDate,
                businessDaysSinceDischargeDate,
              )}
            </p>
          </Fragment>
        ),
      });
    } else if ([TOC_TAB_INPATIENT, TOC_TAB_REHAB].includes(type)) {
      columns.push({
        headerName: translate('toc.table.admitDate'),
        component: ({ id, admitDate, daysInpatient }: TOCItem) => (
          <Fragment key={`toc-item-${id}-status`}>
            <p className="no-margin">
              {getDateWithDiffDays(admitDate, daysInpatient)}
            </p>
          </Fragment>
        ),
      });
    } else if (TOC_TAB_HANDOFF === type) {
      columns.push({
        headerName: translate('toc.table.handoffDate'),
        component: ({ id, handoffDate }: TOCItem) => (
          <Fragment key={`toc-item-${id}-status`}>
            <p className="no-margin">
              {handoffDate?.format(DATE_FORMAT_SHORT) || EMPTY}
            </p>
          </Fragment>
        ),
      });
    }
    columns.push({
      headerName: translate('toc.table.facility'),
      field: 'facility',
    });
  }

  columns.push({
    headerName: translate(
      `toc.table.${
        toggle === TOC_TOGGLE_ITEM_TOC
          ? 'lastTOCEncounter'
          : 'lastSkilledEncounter'
      }`,
    ),
    component: ({ id, lastTcmEncounter }: TOCItem) =>
      lastTcmEncounter?.details ? (
        <StatusIndicator
          color={getEncounterColor(lastTcmEncounter?.details)}
          key={`toc-item-${id}-last-tcm-encounter`}
          status={lastTcmEncounter?.details.toString()}
          subtitle={
            lastTcmEncounter?.dateTime?.format(DATE_FORMAT_SHORT_WITH_TIME) ||
            lastTcmEncounter?.date.format(DATE_FORMAT_SHORT)
          }
        />
      ) : (
        EMPTY
      ),
    width: 200,
  });

  if (
    toggle === TOC_TOGGLE_ITEM_TOC &&
    ![TOC_TAB_INPATIENT, TOC_TAB_REHAB].includes(type)
  ) {
    columns.push(TOC_RN_COLUMN);
    columns.push(TOC_NP_COLUMN);
  } else if (toggle === TOC_TOGGLE_ITEM_SKILLED) {
    columns.push(TOC_NP_COLUMN);
    columns.push(TOC_RN_COLUMN);
  }

  if (toggle === TOC_TOGGLE_ITEM_TOC && TOC_TAB_HANDOFF === type) {
    columns.push({
      headerName: translate('toc.table.handoffStatus'),
      component: ({ id, handoffDisposition }: TOCItem) =>
        handoffDisposition ? (
          <StatusIndicator
            color={getHandoffStatusColor(handoffDisposition.id)}
            key={`toc-item-${id}-handoff-status`}
            status={handoffDisposition.description}
          />
        ) : (
          EMPTY
        ),
    });
  }

  columns.push({
    headerName: translate('toc.table.assignee'),
    component: (item: TOCItem) => (
      <SelectAssignee
        allowUnassigned
        assignee={item.assignee || UNASSIGNED}
        assignToMe
        currentUser={Session.actingUser}
        key={`toc-list-${item.id}-assignee`}
        items={[...(employees || [])]}
        onChange={async (val?: Employee | EmployeeGroup) => {
          if (val) {
            await onChangeAssignee(item, val as Employee);
          }
        }}
        readOnly={readonly}
        size="xs"
      />
    ),
    width: 225,
  });

  columns.push({
    headerName: ' ',
    component: () => (
      <Icon
        className="fa fa-chevron-right"
        size="xs"
        color="green"
        style={{ padding: '1.4rem 0' }}
      />
    ),
    width: 25,
  });

  return columns;
};

const useStyles = makeStyles({
  noBorder: {
    '&&': {
      borderBottom: '0!important',
    },
  },
  critical: {
    '&&:first-child': {
      boxShadow: `inset 4px 0px 0px -1px ${Colors.purple}, inset 0px -1px 0 0px ${BackgroundColors.gray};`,
      paddingLeft: '1rem',
    },
  },
  severe: {
    '&&:first-child': {
      boxShadow: `inset 4px 0px 0px -1px ${Colors.error}, inset 0px -1px 0 0px ${BackgroundColors.gray};`,
      paddingLeft: '1rem',
    },
  },
  moderate: {
    '&&:first-child': {
      boxShadow: `inset 4px 0px 0px -1px ${Colors.gold}, inset 0px -1px 0 0px ${BackgroundColors.gray};`,
      paddingLeft: '1rem',
    },
  },
  low: {
    '&&:first-child': {
      boxShadow: `inset 4px 0px 0px -1px ${Colors.iconGreen}, inset 0px -1px 0 0px ${BackgroundColors.gray};`,
      paddingLeft: '1rem',
    },
  },
  default: {
    '&&:first-child': {
      boxShadow: `inset 3px 0px 0px 0px ${Colors.darkGray}, inset 0px -1px 0 0px ${BackgroundColors.gray};`,
      paddingLeft: '1rem',
    },
  },
});

export const TOCTable = (props: Props) => {
  const {
    items,
    onChangePage,
    onChangePageSize,
    onClickRow,
    pagination,
    pageSize,
    toggle,
    type,
  } = props;
  const styles = useStyles();
  const columns = useMemo(() => TOC_LIST_COLUMNS(toggle, type, props), [
    toggle,
    type,
    props.employees,
  ]);

  return (
    <Table
      config={{
        columns,
        data: items,
        getRowClass: ({ acuityLevel }: TOCItem, idx: number) => {
          if (toggle === TOC_TOGGLE_ITEM_SKILLED) return '';

          const className = idx < items.length - 1 ? styles.noBorder : '';
          if (acuityLevel?.id === TOCAcuityLevel.CRITICAL)
            return classNames(className, styles.critical);
          if (acuityLevel?.id === TOCAcuityLevel.SEVERE)
            return classNames(className, styles.severe);
          if (acuityLevel?.id === TOCAcuityLevel.MODERATE)
            return classNames(className, styles.moderate);
          if (acuityLevel?.id === TOCAcuityLevel.LOW)
            return classNames(className, styles.low);

          return classNames(className, styles.default);
        },
        getRowBrand: ({ member }: TOCItem | SkilledItem) => member?.brand,
        defaultPageSize: pageSize,
        pagination,
        paginationOptions: [10, 25, 50],
      }}
      fontSize="small"
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
      onClickRow={onClickRow}
    />
  );
};

export default TOCTable;
