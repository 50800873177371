import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ClockIcon from '@mui/icons-material/AccessTime';
import GoalIcon from '@mui/icons-material/Flag';
import HelpIcon from '@mui/icons-material/HelpOutline';
import DoctorIcon from '@mui/icons-material/LocalHospital';
import PeopleIcon from '@mui/icons-material/People';
import StarIcon from '@mui/icons-material/Star';
import VideoIcon from '@mui/icons-material/VideocamOutlined';
import { makeStyles } from '@mui/styles';

import {
  Enum,
  Language,
  MemberStatus,
  ProgramExtensionStatus,
  RPMAdherenceLevel,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PodReportingData,
  PodReportingGroup,
  PodReportingSummary,
  ProgramExtension,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  EMPTY,
  LS_POD_REPORTING_FILTERS,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Chip,
  Colors,
  FilterButton,
  Fonts,
  LastUpdated,
  Panel,
  Select,
  Spinner,
  Summary,
  ToggleButton,
  Tooltip,
} from 'styleguide-v2';

import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import {
  GetPodReportDataParams,
  fetchCCMPodReportingBianualVisit,
  fetchCCMPodReportingGroups,
  fetchCCMPodReportingMemberStatus,
  fetchCCMPodReportingMinutes,
  fetchCCMPodReportingProvider,
  fetchCCMPodReportingRPMAdherence,
  fetchCCMPodReportingRPMStatus,
  fetchCCMPodReportingSummary,
} from 'dash/src/services/CCMServices';
import {
  getAsQuery,
  getBoolean,
  getCustom,
  getStoredFilters,
  saveStoredFilters,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { PodReportingDataTable } from './PodReportingDataTable';
import { PodReportingWorklistTable } from './PodReportingWorklistTable';

const NUMBER_OF_MONTHS = 12;
const ALL_PODS = new Employee({
  id: -1,
  fullName: translate('podReporting.allPods'),
});
const ALL_GROUPS = new EmployeeGroup({
  id: -1,
  name: translate('podReporting.allGroups'),
});
const UNASSIGNED_GROUP = new EmployeeGroup({
  name: translate('global.unassigned'),
});

const POD_REPORTING_TAB_ALL = 'all';
const POD_REPORTING_TAB_COMPLEX_CCM = 'complex-ccm';

const POD_REPORTING_TAB_ITEMS = [
  {
    label: translate('podReporting.tabs.all'),
    value: POD_REPORTING_TAB_ALL,
  },
  {
    label: translate('podReporting.tabs.complexCCM'),
    value: POD_REPORTING_TAB_COMPLEX_CCM,
  },
];

const useStyles = makeStyles({
  borderContainer: {
    gap: 0,
    '& > div': {
      padding: '2rem 3.2rem',
      borderBottom: `1px solid ${BackgroundColors.lightGray}`,
      borderRight: `1px solid ${BackgroundColors.lightGray}`,
    },
  },
  chip: {
    '&&': {
      alignItems: 'center',
      border: `1px solid ${Colors.gray}AA`,
      display: 'flex',
      justifyContent: 'center',
      height: '3rem',
      marginRight: '1rem',
      padding: '0.25rem',
      width: '3rem',
    },
  },
  date: {
    width: '15rem',
  },
  exportButton: {
    fontSize: '0.75em',
    marginLeft: 'auto',
  },
  paddingContainer: {
    padding: '2rem 3.2rem',
  },
  panelWhiteBackground: {
    '&& .heading .tabs': {
      borderColor: BackgroundColors.lightGray,
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${BackgroundColors.lightGray}`,
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 400,
      gap: '0.5rem',
      flexWrap: 'wrap',

      '&& > *': {
        minWidth: 'max-content',
      },
    },
  },
  whiteBackground: {
    backgroundColor: Colors.white,
    transition: 'none!important',
  },
});

const initFilters = (
  query: URLSearchParams,
): { tab: string } & GetPodReportDataParams => {
  const filters = getStoredFilters(LS_POD_REPORTING_FILTERS);

  const tab = query.get('tab') || filters.tab || POD_REPORTING_TAB_ALL;
  const excludedProgramExtensionId = getCustom(
    query.getAll('excludedProgramExtensionId'),
    filters.excludedProgramExtensionId,
    Number,
  ) as number[];

  const language = getCustom(
    query.getAll('language'),
    filters.language,
  ) as string[];

  const memberStatus = getCustom(
    query.getAll('memberStatus'),
    filters.memberStatus,
  ) as string[];

  const npOwnerId = getCustom(
    query.getAll('npOwnerId'),
    filters.npOwnerId,
    Number,
  ) as number[];

  const worklistGroupId = getCustom(
    query.getAll('worklistGroupId'),
    filters.worklistGroupId,
    Number,
  ) as number[];

  return {
    excludedProgramExtensionId: excludedProgramExtensionId?.length
      ? excludedProgramExtensionId
      : [
          ProgramExtension.MEDICAID,
          ProgramExtension.TCM,
          ProgramExtension.VESTA_LITE,
        ],
    language,
    status: memberStatus?.length ? memberStatus : [MemberStatus.ACTIVE.value],
    month: moment(),
    npOwnerId: npOwnerId?.length ? npOwnerId : [ALL_PODS.id],
    isUnreachable: getBoolean(query, filters, 'isUnreachable') || false,
    tab,
    worklistGroupId: worklistGroupId?.length
      ? worklistGroupId
      : [ALL_GROUPS.id],
  };
};

let PodReportingStringifyFilters = '';
let PodReportingProviderStringifyFilters = '';

export const PodReportingDashboard = () => {
  const history = useHistory();
  const styles = useStyles();
  const query = useQueryParams();

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingProvider, setLoadingProvider] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [pods, setPods] = useState<Employee[]>([]);
  const [programExtensions, setProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  const months = [] as Moment[];

  months.push(moment());
  // Last day of month for previous months
  for (let i = NUMBER_OF_MONTHS - 1; i > 0; i -= 1) {
    months.push(
      moment()
        .subtract(NUMBER_OF_MONTHS - i, 'month')
        .endOf('month'),
    );
  }

  const [summary, setSummary] = useState<PodReportingSummary>();
  const [summaryGroups, setSummaryGroups] = useState<PodReportingGroup[]>([]);
  const [summaryMembers, setSummaryMembers] = useState<PodReportingData[]>([]);
  const [summaryMinutes, setSummaryMinutes] = useState<PodReportingData[]>([]);
  const [summaryVirtualVisit, setSummaryVirtualVisit] = useState<
    PodReportingData[]
  >([]);
  const [summaryRPMAdherence, setSummaryRPMAdherence] = useState<
    PodReportingData[]
  >([]);
  const [summaryRPMStatus, setSummaryRPMStatus] = useState<PodReportingData[]>(
    [],
  );
  const [summaryProvider, setSummaryProvider] = useState<PodReportingData[]>(
    [],
  );

  const { tab: initialTab, ...initialFilters } = initFilters(query);
  const [filters, setFilters] = useState<GetPodReportDataParams>(
    initialFilters,
  );
  const [tab, setTab] = useState<string>(initialTab);
  const [filtersString, setFiltersString] = useState<string>('');
  const [filtersStringWithoutGroups, setFiltersStringWithoutGroups] = useState<
    string
  >('');

  const getFiltersData = async () => {
    setLoadingFilters(true);
    const [groups, pods, pes, languages] = await Promise.all([
      CacheServices.getEmployeeGroupsWorklist(),
      CacheServices.getNPs(),
      CacheServices.getAllProgramExtensions(),
      fetchSupportedLanguages(),
    ]);

    setGroups([ALL_GROUPS, UNASSIGNED_GROUP, ...groups]);
    setPods([ALL_PODS, ...pods]);
    setProgramExtensions(pes);
    setLanguages(languages);
    setLoadingFilters(false);
  };

  const getData = async () => {
    setLoading(true);

    PodReportingStringifyFilters = JSON.stringify(filters);

    const [
      summary,
      groups,
      members,
      minutes,
      virtualVisit,
      rpmAdherence,
      rpmStatus,
    ] = await Promise.all([
      fetchCCMPodReportingSummary(filters),
      fetchCCMPodReportingGroups(filters),
      fetchCCMPodReportingMemberStatus(filters),
      fetchCCMPodReportingMinutes(filters),
      fetchCCMPodReportingBianualVisit(filters),
      fetchCCMPodReportingRPMAdherence(filters),
      fetchCCMPodReportingRPMStatus(filters),
    ]);

    if (PodReportingStringifyFilters === JSON.stringify(filters)) {
      setSummary(summary);
      setSummaryGroups(
        groups?.length > 1
          ? [
              new PodReportingGroup({
                worklistName: translate('global.total'),
                summary,
              }),
              ...groups,
            ]
          : groups,
      );
      setSummaryMembers(members);
      setSummaryMinutes(minutes);
      setSummaryVirtualVisit(virtualVisit);
      setSummaryRPMAdherence(rpmAdherence);
      setSummaryRPMStatus(rpmStatus);

      setLoading(false);
    }
  };

  const getProviderData = async () => {
    setLoadingProvider(true);
    const completeFilters = {
      ...filters,
      isComplex: tab === POD_REPORTING_TAB_COMPLEX_CCM,
    };

    PodReportingProviderStringifyFilters = JSON.stringify(completeFilters);

    const provider = await fetchCCMPodReportingProvider(completeFilters);

    if (
      PodReportingProviderStringifyFilters === JSON.stringify(completeFilters)
    ) {
      setSummaryProvider(provider);
      setLoadingProvider(false);
    }
  };

  useEffect(() => {
    getFiltersData();
  }, []);

  useEffect(() => {
    getData();
    getProviderData();
  }, [filters]);

  useEffect(() => {
    getProviderData();
  }, [tab]);

  useEffect(() => {
    if (
      filters.npOwnerId &&
      filters.npOwnerId.length > 1 &&
      filters.npOwnerId?.find((item) => item === ALL_PODS.id)
    ) {
      setFilters({
        ...filters,
        npOwnerId: [ALL_PODS.id],
      });
    }
    if (
      filters.worklistGroupId &&
      filters.worklistGroupId.length > 1 &&
      filters.worklistGroupId?.find((item) => item === ALL_GROUPS.id)
    ) {
      setFilters({
        ...filters,
        worklistGroupId: [ALL_GROUPS.id],
      });
    }
  }, [filters]);

  useEffect(() => {
    const currentFilters = [
      filters.isUnreachable ? 'isUnreachable=true' : '',
      filters.npOwnerId?.filter((id) => id)?.length
        ? filters.npOwnerId
            .filter((id) => id >= 0)
            .map((id) => `npOwnerId=${id}`)
            .join('&')
        : '',
      filters.excludedProgramExtensionId?.length
        ? filters.excludedProgramExtensionId
            .map((id) => `excludedProgramExtensionId=${id}`)
            .join('&')
        : '',
      filters.language?.length
        ? filters.language.map((id) => `language=${id}`).join('&')
        : '',
      filters.language?.length ? 'hasLanguage=true' : '',
    ];
    setFiltersString(
      [
        ...currentFilters,
        filters.worklistGroupId?.filter((id) => id)?.length
          ? filters.worklistGroupId
              .filter((id) => id >= 0)
              .map((id) => `worklistGroupId=${id}`)
              .join('&')
          : '',
      ]
        .filter(Boolean)
        .join('&'),
    );
    setFiltersStringWithoutGroups(currentFilters.filter(Boolean).join('&'));
  }, [filters]);

  useEffect(() => {
    const { month, ...rest } = filters;
    const flatFilters = {
      tab,
      month: month?.format(DATE_FORMAT),
      ...rest,
    };
    const query = getAsQuery(flatFilters);
    history.replace(`?${query}`);
    saveStoredFilters(LS_POD_REPORTING_FILTERS, flatFilters);
  }, [filters, tab]);

  return (
    <Panel className={styles.panelWhiteBackground}>
      <Panel.Heading
        className={styles.borderBottom}
        title={translate('podReporting.title')}
      >
        <FilterButton
          className="filters"
          data-cy="pod-reporting-filters"
          onClick={() => setOpenFilters(!openFilters)}
        />
        <Panel.Actions>
          {summary && (
            <LastUpdated
              lastUpdatedAt={summary.lastUpdatedAt}
              tooltip={translate('podReporting.lastUpdatedTooltip')}
            />
          )}
          <Select
            className={styles.date}
            data-cy="pod-reporting-filters-month"
            disableClearable
            getItemLabel={(date: Moment) =>
              date.format('MMM YYYY').toLocaleUpperCase()
            }
            items={months}
            onChange={(month?: Moment) => {
              setFilters({
                ...filters,
                month,
              });
            }}
            placeholder={translate('podReporting.filters.month')}
            value={filters.month}
          />
        </Panel.Actions>
        <Panel.Collapse open={openFilters}>
          <div className="grid-wrapper fit">
            <Select
              className="grid-span-3"
              data-cy="pod-reporting-filters-worklist"
              disableClearable
              getItemDisabled={({ id }: EmployeeGroup) =>
                !!(
                  id !== ALL_GROUPS.id &&
                  filters.worklistGroupId &&
                  filters.worklistGroupId[0] === ALL_GROUPS.id
                )
              }
              getItemLabel={({ name }: EmployeeGroup) => name}
              items={groups}
              label={translate('podReporting.filters.groups')}
              limitTags={1}
              loading={loadingFilters}
              multiple
              onChange={(worklistGroupId: EmployeeGroup[]) => {
                setFilters({
                  ...filters,
                  worklistGroupId: worklistGroupId?.length
                    ? worklistGroupId.map(({ id }) => id)
                    : undefined,
                });
              }}
              size="small"
              value={groups.filter(({ id }) =>
                (filters.worklistGroupId || []).includes(id),
              )}
            />
            <Select
              className="grid-span-3"
              data-cy="pod-reporting-filters-pod"
              disableClearable
              getItemDisabled={({ id }: Employee) =>
                !!(
                  id !== ALL_PODS.id &&
                  filters.npOwnerId &&
                  filters.npOwnerId[0] === ALL_PODS.id
                )
              }
              getItemLabel={({ fullName }: Employee) => fullName}
              items={pods}
              label={translate('podReporting.filters.pods')}
              limitTags={1}
              loading={loadingFilters}
              multiple
              onChange={(pods: Employee[]) => {
                setFilters({
                  ...filters,
                  npOwnerId: pods?.length
                    ? pods.map(({ id }) => id)
                    : undefined,
                });
              }}
              size="small"
              value={pods.filter(({ id }) =>
                (filters.npOwnerId || []).includes(id),
              )}
            />
            <Select.Choice
              className="grid-span-3"
              data-cy="pod-reporting-filters-unreachable"
              disableClearable
              label={translate('podReporting.filters.unreachable')}
              onChange={(isUnreachable?: boolean) => {
                setFilters({
                  ...filters,
                  isUnreachable,
                });
              }}
              size="small"
              value={filters.isUnreachable}
            />
            <Select
              className="grid-span-3"
              data-cy="pod-reporting-filters-excluded-pe"
              getItemLabel={({ name }: ProgramExtension) => name}
              items={programExtensions}
              label={translate(
                'podReporting.filters.excludedProgramExtensions',
              )}
              limitTags={1}
              loading={loadingFilters}
              multiple
              onChange={(pe: ProgramExtension[]) => {
                setFilters({
                  ...filters,
                  excludedProgramExtensionId: pe?.length
                    ? pe.map(({ id }) => id)
                    : undefined,
                });
              }}
              size="small"
              value={programExtensions.filter(({ id }) =>
                filters.excludedProgramExtensionId?.includes(id),
              )}
            />
            <Select
              className="grid-span-3"
              data-cy="pod-reporting-filters-member-status"
              items={MemberStatus.toSelectable()}
              label={translate('podReporting.filters.memberStatus')}
              limitTags={1}
              loading={loadingFilters}
              multiple
              onChange={(memberStatus: Selectable[]) => {
                setFilters({
                  ...filters,
                  status: memberStatus?.length
                    ? memberStatus.map(({ value }) => value as string)
                    : undefined,
                });
              }}
              size="small"
              value={MemberStatus.toSelectable().filter(({ value }) =>
                filters.status?.includes(value as string),
              )}
            />
            <Select
              className="grid-span-3"
              data-cy="pod-reporting-filters-language"
              grouped={({ preferred }) =>
                preferred
                  ? translate('global.preferred')
                  : translate('global.other')
              }
              items={Language.toSelectable(languages).filter(
                ({ disabled }) => !disabled,
              )}
              label={translate('podReporting.filters.language')}
              limitTags={1}
              loading={loadingFilters}
              multiple
              onChange={(language: Language[]) => {
                setFilters({
                  ...filters,
                  language: language.map(({ value }) => value),
                });
              }}
              size="small"
              value={Enum.toSelectable(
                filters.language?.map((item) => Language.byKey[item]) || [],
              )}
            />
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body
        className={classNames('no-padding', styles.whiteBackground)}
        loading={loading}
      >
        <div
          className={classNames(
            'flex spaced',
            styles.borderBottom,
            styles.paddingContainer,
          )}
        >
          <Summary
            color={Colors.textGreen}
            title={translate('podReporting.summary.total')}
            body={summary?.total !== undefined ? summary.total : EMPTY}
            footer={
              <>
                <span className="bold">{summary ? 100 : EMPTY}%</span>{' '}
                {translate('podReporting.summary.ofPatients')}
              </>
            }
            tooltip={translate('podReporting.tooltips.total')}
          />
          <Summary
            color={Colors.mint}
            title={translate('podReporting.summary.completed')}
            body={summary?.completed !== undefined ? summary.completed : EMPTY}
            footer={
              <>
                <span className="bold">
                  {summary?.total
                    ? Math.round((summary.completed / summary.total) * 1000) /
                      10
                    : EMPTY}
                  %
                </span>{' '}
                {translate('podReporting.summary.ofPatients')}
              </>
            }
            onClick={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&ccmCallStatus=COMPLETED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            tooltip={translate('podReporting.tooltips.completed')}
          />
          <Summary
            color={Colors.gold}
            title={translate('podReporting.summary.attempted')}
            body={summary?.attempted !== undefined ? summary.attempted : EMPTY}
            footer={
              <>
                <span className="bold">
                  {summary?.total
                    ? Math.round((summary.attempted / summary.total) * 1000) /
                      10
                    : EMPTY}
                  %
                </span>{' '}
                {translate('podReporting.summary.ofPatients')}
              </>
            }
            onClick={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&ccmCallStatus=ATTEMPTED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            tooltip={translate('podReporting.tooltips.attempted')}
          />
          <Summary
            color={Colors.darkOrange}
            title={translate('podReporting.summary.notStarted')}
            body={
              summary?.notStarted !== undefined ? summary.notStarted : EMPTY
            }
            footer={
              <>
                <span className="bold">
                  {summary?.total
                    ? Math.round((summary.notStarted / summary.total) * 1000) /
                      10
                    : EMPTY}
                  %
                </span>{' '}
                {translate('podReporting.summary.ofPatients')}
              </>
            }
            onClick={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&ccmCallStatus=NOT_STARTED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            tooltip={translate('podReporting.tooltips.notStarted')}
          />
          <Summary
            color={Colors.gray}
            title={translate('podReporting.summary.businessDays')}
            body={
              summary
                ? `${summary.businessDaysElapsed} / ${summary.businessDaysRemaining}`
                : EMPTY
            }
            footer={
              <i>{translate('podReporting.summary.businessDaysFooter')}</i>
            }
            size="s"
            tooltip={translate('podReporting.tooltips.businessDays')}
          />
          <Summary
            color={Colors.lightGray}
            title={translate('podReporting.summary.patientsPerDay')}
            body={summary ? `${summary.perDay} / ${summary.perDayGoal}` : EMPTY}
            footer={
              <i>{translate('podReporting.summary.patientsPerDayFooter')}</i>
            }
            size="s"
            tooltip={translate('podReporting.tooltips.patientsPerDay')}
          />
        </div>
        <div className={classNames('grid-wrapper', styles.borderContainer)}>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <StarIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                {translate('podReporting.titleMemberStatus')}
              </h2>
            </div>
            <PodReportingDataTable
              boldLastRow
              data={summaryMembers}
              header={translate('podReporting.table.status')}
            />
          </div>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <ClockIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                {translate('podReporting.titleMinutes')}
              </h2>
            </div>
            <PodReportingDataTable
              data={summaryMinutes}
              header={translate('podReporting.table.minutes')}
              onClick={(data) => {
                let minutesFrom = '';
                let minutesTo = '';
                if (data.name.includes('-')) {
                  minutesFrom = data.name.split('-')[0].trim();
                  minutesTo = data.name.split('-')[1].trim();
                } else {
                  minutesFrom = '60';
                }
                window.open(
                  `#/dashboard/ccm?clearFilters=true&totalMinutesFrom=${minutesFrom}&totalMinutesTo=${minutesTo}&${filtersString}`,
                  '_blank',
                  'noreferrer',
                );
              }}
            />
          </div>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <VideoIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                <span className={styles.subtitle}>
                  {translate('podReporting.titleBianualVisit')}
                </span>
                <Tooltip
                  text={translate('podReporting.tooltips.biannualVisit')}
                >
                  <HelpIcon color="action" />
                </Tooltip>
              </h2>
            </div>
            <PodReportingDataTable
              data={summaryVirtualVisit}
              header={translate('podReporting.table.visitStatus')}
              onClick={(data) =>
                window.open(
                  `#/dashboard/ccm?clearFilters=true&isBiannualComplete=${
                    data.name === 'Completed'
                  }&${filtersString}`,
                  '_blank',
                  'noreferrer',
                )
              }
            />
          </div>
          <div className="grid-span-4">
            <div className="flex spaced wrap">
              <div className="flex gap">
                <Chip className={styles.chip} color="action">
                  <DoctorIcon />
                </Chip>
                <h2 className={styles.subtitle}>
                  <span className={styles.subtitle}>
                    {translate('podReporting.titleProviderTime')}
                  </span>
                </h2>
              </div>
              <ToggleButton
                color="info"
                items={POD_REPORTING_TAB_ITEMS}
                onChange={setTab}
                size="xs"
                value={tab}
              />
            </div>
            {loadingProvider && !loading ? (
              <div className="flex center" style={{ marginTop: '1rem' }}>
                <Spinner width={32} />
              </div>
            ) : (
              <PodReportingDataTable
                data={summaryProvider}
                header={translate('podReporting.table.providerTime')}
                onClick={(data) =>
                  window.open(
                    `#/dashboard/ccm?clearFilters=true&from&totalMinutesFrom=${
                      tab === POD_REPORTING_TAB_COMPLEX_CCM ? '90' : ''
                    }&hasProfessionalTime=${
                      data.name === 'Yes'
                    }&${filtersString}`,
                    '_blank',
                    'noreferrer',
                  )
                }
              />
            )}
          </div>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <GoalIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                <span className={styles.subtitle}>
                  {translate('podReporting.titleRPMAdherence')}
                </span>
                <Tooltip text={translate('podReporting.tooltips.rpmAdherence')}>
                  <HelpIcon color="action" />
                </Tooltip>
              </h2>
            </div>
            <PodReportingDataTable
              boldLastRow
              data={summaryRPMAdherence}
              header={translate('podReporting.table.opportunityLevel')}
              onClick={(data) => {
                const item = Enum.toSelectable(RPMAdherenceLevel.asArray).find(
                  ({ label }) => label === data.name,
                );
                if (item) {
                  window.open(
                    `#/dashboard/ccm?clearFilters=true&programExtensionId=${ProgramExtension.RPM}&hasProgramExtensions=true&programExtensionStatus=${ProgramExtensionStatus.ACTIVE.value}&rpmAdherenceId=${item.value}&${filtersString}`,
                    '_blank',
                    'noreferrer',
                  );
                }
              }}
            />
          </div>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <StarIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                <span className={styles.subtitle}>
                  {translate('podReporting.titleRPMStatus')}
                </span>
                <Tooltip text={translate('podReporting.tooltips.rpmStatus')}>
                  <HelpIcon color="action" />
                </Tooltip>
              </h2>
            </div>
            <PodReportingDataTable
              boldLastRow
              data={summaryRPMStatus}
              header={translate('podReporting.table.status')}
              onClick={(data) => {
                const item = Enum.toSelectable(
                  ProgramExtensionStatus.asArray,
                ).find(({ label }) => label === data.name);
                if (item) {
                  window.open(
                    `#/dashboard/ccm?clearFilters=true&programExtensionId=${ProgramExtension.RPM}&programExtensionStatus=${item.value}&${filtersString}`,
                    '_blank',
                    'noreferrer',
                  );
                }
              }}
            />
          </div>
        </div>
        <div className={classNames('grid-wrapper', styles.paddingContainer)}>
          <div className="grid-span-12">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <PeopleIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                <span>{translate('podReporting.titleWorklist')}</span>
                <Tooltip
                  text={translate('podReporting.tooltips.worklistGroups')}
                >
                  <HelpIcon color="action" />
                </Tooltip>
              </h2>
            </div>
            <PodReportingWorklistTable
              data={summaryGroups}
              onClick={(data) =>
                window.open(
                  `#/dashboard/ccm?clearFilters=true&worklistGroupId=${data.worklistId}&${filtersStringWithoutGroups}`,
                  '_blank',
                  'noreferrer',
                )
              }
            />
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default PodReportingDashboard;
