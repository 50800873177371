import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CommunityResource,
  PaginationType,
} from '@vestahealthcare/common/models';

import {
  Button,
  FilterButton,
  IconButton,
  Panel,
  Select,
  TextInput,
} from 'styleguide-v2';

import { CommunityResourceQuickAdd } from 'dash/src/pages/CommunityResources/modals/CommunityResourceQuickAdd';
import {
  CommunityResourceParams,
  createCommunityResource,
  fetchCommunityResourceSpecialities,
  fetchCommunityResources,
  updateCommunityResource,
} from 'dash/src/services/CommunityResourcesServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';

import CommunityResourceEditModal from './CommunityResourceEdit';
import CommunityResourcesTable from './CommunityResourcesTable';

const STATUS_ITEMS: Selectable[] = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
  { value: '', label: 'All' },
];

const PAGE_SIZE = 10;
const STATES_ITEMS: Selectable[] = [
  { value: 'UNASSIGNED', label: translate('global.unknown') },
  ...Enum.toSelectable(States.asArray),
];

export const CommunityResourcesDashboard = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<CommunityResourceParams>({});
  const [loading, setLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>();
  const [id, setId] = useState<string>();
  const [specialities, setSpecialities] = useState<Selectable[]>([]);

  const [openAddCommunityResource, setOpenAddCommunityResource] = useState<
    boolean
  >(false);
  const [openEditCommunityResource, setOpenEditCommunityResource] = useState<
    boolean
  >(false);
  const [editCommunityResource, setEditCommunityResource] = useState<
    CommunityResource
  >();

  const [communityResources, setCommunityResources] = useState<
    CommunityResource[]
  >([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const getInitialData = async () => {
    const specialities = await fetchCommunityResourceSpecialities();
    setSpecialities(
      specialities.map(({ id, description }) => ({
        value: id,
        label: description,
      })),
    );
  };

  const debouncedId = useDebounce(id, 300);
  const debouncedName = useDebounce(name, 300);

  const getCommunityResources = async () => {
    if (page !== 0 && page * pageSize < communityResources?.length) return;
    setLoading(true);
    const { items, pagination } = await fetchCommunityResources({
      ...filters,
      name: debouncedName,
      id: debouncedId,
      offset: page * pageSize,
      limit: pageSize,
    });
    if (page) {
      setCommunityResources([...communityResources, ...items]);
    } else {
      setCommunityResources(items);
    }
    setPagination(pagination);
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getCommunityResources();
  }, [filters, page, pageSize, debouncedId, debouncedName]);

  return (
    <Panel>
      <Panel.Heading title={translate('communityResources.title')}>
        <FilterButton
          className="filters"
          data-cy="community-resources-filters"
          filters={filters}
          onClick={() => setOpen(!isOpen)}
        />
        <Panel.Actions>
          <IconButton
            onClick={() => {
              setEditCommunityResource(undefined);
              setOpenAddCommunityResource(true);
            }}
            tooltip={translate('communityResources.add')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
        <Panel.Collapse open={isOpen}>
          <div className="grid-wrapper fit">
            <TextInput
              className="grid-span-3"
              onChange={setId}
              placeholder={translate('communityResources.searchByID')}
              value={id}
              size="small"
            />
            <TextInput
              className="grid-span-3"
              onChange={setName}
              placeholder={translate('communityResources.searchByName')}
              value={name}
              size="small"
            />
            <Select
              className="grid-span-3"
              items={specialities}
              multiple
              onChange={(value?: Selectable[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  specialities: value?.map(({ value }) => value as string),
                });
              }}
              placeholder={translate('communityResources.specialities')}
              value={specialities.filter(({ value }) =>
                filters.specialities?.includes(value as string),
              )}
              size="small"
            />
            <Select
              className="grid-span-3"
              items={STATUS_ITEMS}
              onChange={(value?: Selectable) => {
                setPage(0);
                setFilters({
                  ...filters,
                  status: value?.value as 'ACTIVE' | 'INACTIVE',
                });
              }}
              placeholder={translate('communityResources.status')}
              value={STATUS_ITEMS.find(({ value }) => filters.status === value)}
              size="small"
            />
            <Select
              className="grid-span-3"
              items={STATES_ITEMS}
              onChange={(value?: Selectable) => {
                setPage(0);
                setFilters({
                  ...filters,
                  state: value?.value as string,
                });
              }}
              placeholder={translate('communityResources.coverageArea')}
              value={STATES_ITEMS.find(({ value }) => filters.state === value)}
              size="small"
            />
            <div className="grid-span-6" />
            <Button
              className="grid-span-3"
              data-cy="employees-clear-filters"
              color="secondary"
              type="outlined"
              onClick={() => {
                setPage(0);
                setFilters({});
              }}
              size="s"
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <CommunityResourcesTable
          communityResources={communityResources}
          onEdit={(cr) => {
            setEditCommunityResource(cr);
            setOpenEditCommunityResource(true);
          }}
          onChangePage={setPage}
          onChangePageSize={(ps) => {
            setPage(0);
            setPageSize(ps);
          }}
          pagination={pagination}
        />
        <CommunityResourceEditModal
          communityResource={editCommunityResource}
          open={openEditCommunityResource}
          onClose={() => setOpenEditCommunityResource(false)}
          onCreate={async (params) => {
            await createCommunityResource(params);
            await getCommunityResources();
          }}
          onEdit={async (id, params) => {
            await updateCommunityResource(id, params);
            await getCommunityResources();
          }}
        />
        <CommunityResourceQuickAdd
          editTitle
          onClose={() => setOpenAddCommunityResource(false)}
          onSubmit={async (params, cr) => {
            setLoading(true);
            if (cr?.id) {
              await updateCommunityResource(cr.id, params);
            } else {
              await createCommunityResource(params);
            }

            await getCommunityResources();
            setLoading(false);
          }}
          open={openAddCommunityResource}
        />
      </Panel.Body>
    </Panel>
  );
};

export default CommunityResourcesDashboard;
