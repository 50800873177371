import React, { useEffect, useState } from 'react';

import {
  Choice,
  CollectionMethod,
  Language,
  MemberContactMethodType,
  MemberRisk,
  MemberStatus,
  ProgramExtensionStatus,
  States,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Employee,
  Organization,
} from '@vestahealthcare/common/models';

import { Button, SearchInput, Select } from 'styleguide-v2';

import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import { MemberViewParams } from 'dash/src/services/PatientServices';
import { getSafeValues } from 'dash/src/utils/filterUtils';

import { ReferralDropdown } from '../ReferralDropdown';

type Props = {
  filters: MemberViewParams;
  hideIdFilter?: boolean;
  onChange: (filters: MemberViewParams) => void;
};

const RISK_LEVEL_OPTION_NONE_VALUE = 'no-risk-level';
const RISK_LEVEL_OPTION_NONE_OPTION = {
  value: RISK_LEVEL_OPTION_NONE_VALUE,
  label: translate('memberBulkEdit.noRiskLevel'),
};
const RISK_LEVEL_OPTIONS = [
  RISK_LEVEL_OPTION_NONE_OPTION,
  ...Enum.toSelectable(MemberRisk.asArray),
];

const UNASSIGNED = { label: translate('global.unassigned'), value: 0 };

interface SelectableOrganization extends Selectable {
  item?: Organization;
}

export const MemberViewFilters = ({
  filters,
  hideIdFilter,
  onChange,
}: Props) => {
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout>();
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const [languages, setLanguages] = useState<Language[]>([]);
  const [activeCareCoordinators, setActiveCareCoordinators] = useState<
    Selectable[]
  >([]);
  const [activeRNs, setActiveRNs] = useState<Selectable[]>([]);
  const [activeNPs, setActiveNPs] = useState<Selectable[]>([]);
  const [activeEngagementOwners, setActiveEngagementOwners] = useState<
    Selectable[]
  >([]);
  const [activeHealthCoaches, setActiveHealthCoaches] = useState<Selectable[]>(
    [],
  );
  const [carePlanGroups, setCarePlanGroups] = useState<Selectable[]>([]);
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [referralSources, setReferralSources] = useState<
    SelectableOrganization[]
  >([]);
  const [reporterStatuses, setReporterStatuses] = useState<BaseEnum[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);

  const mapEmployees = (array: Employee[]) =>
    array
      .map(({ fullName: label, id: value }) => ({
        label,
        value,
      }))
      .sort(({ label: a }, { label: b }) => a.localeCompare(b));

  const fetchFiltersData = async () => {
    setLoadingFilters(true);
    const [l, cpgs, rns, nps, ccs, engs, hcs] = await Promise.all([
      fetchSupportedLanguages(),
      CacheServices.getCarePlanGroups(),
      CacheServices.getRNs(),
      CacheServices.getNPs(),
      CacheServices.getCareCoordinators(),
      CacheServices.getEngagementOwners(),
      CacheServices.getHealthCoaches(),
    ]);

    const [pe, o, rs, b] = await Promise.all([
      CacheServices.getAllProgramExtensions(),
      CacheServices.getOrganizations(),
      CacheServices.getCareTeamPersonStatuses(),
      CacheServices.getOrganizationBrands(),
    ]);
    setLanguages(l);
    setCarePlanGroups([
      UNASSIGNED,
      ...cpgs.map(({ id: value, description: label }) => ({
        value,
        label,
      })),
    ]);

    const activeRNs = mapEmployees(rns);
    const activeNPs = mapEmployees(nps);
    const activeCareCoordinators = mapEmployees(ccs);
    const activeEngagementOwners = mapEmployees(engs);
    const activeHealthCoaches = mapEmployees(hcs);
    const referralsOptions = o.map((item) => ({
      label: item.name,
      value: item.id,
      item,
    }));

    setActiveCareCoordinators([UNASSIGNED, ...activeCareCoordinators]);
    setActiveRNs([UNASSIGNED, ...activeRNs]);
    setActiveNPs([UNASSIGNED, ...activeNPs]);
    setActiveEngagementOwners([UNASSIGNED, ...activeEngagementOwners]);
    setActiveHealthCoaches([UNASSIGNED, ...activeHealthCoaches]);
    setProgramExtensions(
      pe.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    );
    setReferralSources([
      { value: 0, label: translate('global.noReferral') },
      ...referralsOptions,
    ]);
    setReporterStatuses(rs);
    setBrands(
      b.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );
    setLoadingFilters(false);
  };

  useEffect(() => {
    fetchFiltersData();
  }, []);

  useEffect(() => {
    if (filters.referralSourceId && filters.referralSourceId.length > 1) {
      const lastAddedId =
        filters.referralSourceId[filters.referralSourceId.length - 1];
      const lastAdded = referralSources.find(
        ({ item }) => item?.id === lastAddedId,
      );
      const newOrganizations = filters.referralSourceId.filter(
        (child) => !lastAdded?.item?.hasDescendant(child),
      );
      if (newOrganizations.length < filters.referralSourceId.length) {
        onChange({ ...filters, referralSourceId: newOrganizations });
      }
    }
  }, [filters.referralSourceId]);

  return (
    <>
      {!hideIdFilter && (
        <Select
          allowAddNewValue
          className="grid-span-3"
          data-cy="members-filter-id"
          items={[]}
          multiple
          noOptionsText={translate('members.typeToSearch')}
          onChange={(values: Selectable[]) =>
            onChange({
              ...filters,
              id: values.map((v) => Number(v.label)),
            })
          }
          placeholder={translate('members.id')}
          value={filters.id?.map((id) => ({ label: id }))}
          size="small"
        />
      )}
      <SearchInput
        className="grid-span-3"
        label=""
        placeholder={translate('members.name')}
        onChange={(value) => {
          const timer = setTimeout(() => {
            onChange({ ...filters, fullName: value || undefined });
          }, 500);
          if (searchTimer) {
            clearTimeout(searchTimer);
          }
          setSearchTimer(timer);
        }}
        value={filters.fullName}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-status"
        items={MemberStatus.toFilterSelectable()}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            status: values.map((v) => MemberStatus.byKey[v.value]),
          });
        }}
        placeholder={translate('members.status')}
        value={Enum.toSelectable(filters.status || [])}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-care-plan-group"
        items={carePlanGroups}
        onChange={(carePlanGroup: Selectable) => {
          onChange({
            ...filters,
            carePlanGroupId: carePlanGroup?.value as number,
          });
        }}
        placeholder={translate('members.carePlanGroup')}
        value={carePlanGroups.find(
          ({ value }) => value === filters.carePlanGroupId,
        )}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="bulk-edit-filter-state"
        items={Enum.toSelectable(States.asArray)}
        multiple
        onChange={(state: Selectable[]) =>
          onChange({
            ...filters,
            state: state
              .map(({ value }) => States.byKey[value])
              .filter(Boolean),
          })
        }
        placeholder={translate('members.state')}
        value={Enum.toSelectable(filters?.state || [])}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-language"
        grouped={({ preferred }) => (preferred ? 'Preferred' : 'Other')}
        items={Language.toSelectable(languages).filter(
          ({ disabled }) => !disabled,
        )}
        loading={loadingFilters}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            language: values.map((v) => Language.byKey[v.value]),
          });
        }}
        placeholder={translate('members.language')}
        value={Enum.toSelectable(filters.language || [])}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-rn-ownwer"
        items={activeRNs}
        loading={loadingFilters}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            rnOwnerId: values.map(({ value }) => Number(value)),
          });
        }}
        placeholder={translate('members.rnOwner')}
        value={getSafeValues(activeRNs, filters.rnOwnerId)}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-np-ownwer"
        items={activeNPs}
        loading={loadingFilters}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            npOwnerId: values.map(({ value }) => Number(value)),
          });
        }}
        placeholder={translate('members.npOwner')}
        value={getSafeValues(activeNPs, filters.npOwnerId)}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="member-filter-care-coordinator-owner"
        items={activeCareCoordinators || []}
        loading={loadingFilters}
        multiple
        onChange={(careCoordinatorId: Selectable[]) =>
          onChange({
            ...filters,
            careCoordinatorId: careCoordinatorId.map(
              ({ value }) => value as number,
            ),
          })
        }
        placeholder={translate('members.careCoordinators')}
        value={getSafeValues(activeCareCoordinators, filters.careCoordinatorId)}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="member-filter-engagement-owner"
        items={activeEngagementOwners || []}
        loading={loadingFilters}
        multiple
        onChange={(engagementOwnerId: Selectable[]) =>
          onChange({
            ...filters,
            engagementOwnerId: engagementOwnerId.map(
              ({ value }) => value as number,
            ),
          })
        }
        placeholder={translate('members.engagementOwners')}
        value={getSafeValues(activeEngagementOwners, filters.engagementOwnerId)}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="member-filter-health-coach"
        items={activeHealthCoaches || []}
        loading={loadingFilters}
        multiple
        onChange={(healthCoachId: Selectable[]) =>
          onChange({
            ...filters,
            healthCoachId: healthCoachId.map(({ value }) => value as number),
          })
        }
        placeholder={translate('members.healthCoaches')}
        value={getSafeValues(activeHealthCoaches, filters.healthCoachId)}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-program-extensions"
        items={programExtensions}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            programExtensionId: values.map((v) => v.value as number),
          });
        }}
        placeholder={translate('members.programExtensions')}
        value={getSafeValues(programExtensions, filters.programExtensionId)}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-program-extensions-status"
        items={ProgramExtensionStatus.getFilterSelectables()}
        multiple
        onChange={(values: Selectable[]) => {
          const status = values.map(
            (v) => ProgramExtensionStatus.byKey[v.value],
          );
          onChange({
            ...filters,
            programExtensionStatus: status,
          });
        }}
        placeholder={translate('members.programExtensionStatus')}
        value={Enum.toSelectable(filters.programExtensionStatus || [])}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-risk-level"
        items={RISK_LEVEL_OPTIONS}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            riskLevel: values
              .map((v) => MemberRisk.byKey[v.value])
              .filter(Boolean),
            emptyRiskLevel:
              !!values.find(
                ({ value }) => value === RISK_LEVEL_OPTION_NONE_VALUE,
              ) || undefined,
          });
        }}
        placeholder={translate('members.riskLevel')}
        value={[
          ...(filters.emptyRiskLevel ? [RISK_LEVEL_OPTION_NONE_OPTION] : []),
          ...Enum.toSelectable(filters.riskLevel || []),
        ]}
        size="small"
      />
      <ReferralDropdown
        className="grid-span-3"
        data-cy="members-filter-referral-sources"
        getItemDisabled={(child: Selectable) =>
          !!filters.excludedReferralSourceId?.find(
            (value) => value === child.value,
          ) ||
          !!(getSafeValues(referralSources, [
            ...(filters.referralSourceId || []),
            ...(filters.excludedReferralSourceId || []),
          ]) as SelectableOrganization[])?.reduce(
            (acc, item) =>
              acc || !!item.item?.hasDescendant(child.value as number),
            false,
          )
        }
        items={referralSources}
        limitTags={1}
        loading={loadingFilters}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            referralSourceId: values.map((v) => v.value as number),
          });
        }}
        placeholder={translate('members.referralSources')}
        size="small"
        value={getSafeValues(referralSources, filters.referralSourceId)}
      />
      <ReferralDropdown
        className="grid-span-3"
        data-cy="members-filter-excluded-referral-sources"
        getItemDisabled={(child: Selectable) =>
          !!filters.referralSourceId?.find((value) => value === child.value) ||
          !!(getSafeValues(referralSources, [
            ...(filters.referralSourceId || []),
            ...(filters.excludedReferralSourceId || []),
          ]) as SelectableOrganization[])?.reduce(
            (acc, item) =>
              acc || !!item.item?.hasDescendant(child.value as number),
            false,
          )
        }
        items={referralSources.filter(({ value }) => !!value)}
        limitTags={1}
        loading={loadingFilters}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            excludedReferralSourceId: values.map((v) => v.value as number),
          });
        }}
        placeholder={translate('members.excludedReferralSources')}
        size="small"
        value={getSafeValues(referralSources, filters.excludedReferralSourceId)}
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-vitals-level"
        items={CollectionMethod.toVendorSelectable()}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            vitalCollectionMethod: values.map(
              (v) => CollectionMethod.byKey[v.value],
            ),
          });
        }}
        placeholder={translate('members.vitalsCollectionMethod')}
        value={Enum.toSelectable(filters.vitalCollectionMethod || [])}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-has-care-team"
        items={Choice.getChoices()}
        onChange={(item: Selectable) => {
          onChange({
            ...filters,
            hasCareTeam:
              item?.value !== undefined ? Boolean(item.value) : undefined,
          });
        }}
        placeholder={translate('members.hasCareTeam')}
        value={
          filters.hasCareTeam !== undefined &&
          Choice.getChoices()[filters.hasCareTeam ? 0 : 1]
        }
        size="small"
      />

      <Select.Choice
        className="grid-span-3"
        data-cy="members-filter-self-reporter"
        onChange={(hciReporter?: boolean) => {
          onChange({
            ...filters,
            hciReporter,
          });
        }}
        placeholder={translate('members.selfReporter')}
        value={filters.hciReporter}
        size="small"
      />

      <Select
        className="grid-span-3"
        data-cy="members-filter-reporter-status"
        items={reporterStatuses}
        getItemLabel={({ description }: BaseEnum) => description}
        multiple
        onChange={(values: BaseEnum[]) => {
          onChange({
            ...filters,
            hciReporterStatus: values?.map(({ id }) => id),
          });
        }}
        placeholder={translate('members.reporterStatus')}
        value={(reporterStatuses || []).filter(({ id }) =>
          filters.hciReporterStatus?.includes(id),
        )}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="members-filter-reporting-pref"
        items={Enum.toSelectable(MemberContactMethodType.asArray)}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            hciReporterMethod: values?.map(
              ({ value }) => MemberContactMethodType.byKey[value],
            ),
          });
        }}
        placeholder={translate('members.reportingPreference')}
        value={Enum.toSelectable(filters.hciReporterMethod || [])}
        size="small"
      />
      <Select
        className="grid-span-3"
        data-cy="tasks-filter-brand"
        items={brands}
        limitTags={1}
        multiple
        onChange={(brand?: Selectable[]) => {
          onChange({
            ...filters,
            brandId: brand?.map(({ value }) => value as string),
          });
        }}
        placeholder={translate('clinicalDashboard.tasks.filters.brand')}
        size="small"
        value={brands.filter(({ value }) =>
          filters.brandId?.includes(value as string),
        )}
      />

      <div className={`grid-span-${hideIdFilter ? 6 : 3}`} />
      <div className="grid-span-3" />

      <Button
        className="grid-span-3"
        data-cy="members-clear-filters"
        color="secondary"
        type="outlined"
        onClick={() => onChange({})}
        size="s"
      >
        {translate('global.clearFilters')}
      </Button>
    </>
  );
};
