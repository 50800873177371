/* eslint-disable @typescript-eslint/no-implied-eval */
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from '@mui/styles';

import {
  BiometricsType,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/enums';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BiometricsConfig,
  BiometricsRPMAdherence,
  ProgramExtension,
  Task,
} from '@vestahealthcare/common/models';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';

import { EmptyState } from 'styleguide';
import {
  Chip,
  IconButton,
  Panel,
  ToggleButton,
  ToggleDateRange,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { useSelector } from 'dash/src/redux/store';
import {
  addMeasurement,
  fetchEnabledConfigs,
  fetchRPMAdhrerence,
  updateMeasurement,
} from 'dash/src/services/BiometricsServices';
import { fetchAllTasks } from 'dash/src/services/TaskServices';

import { BiometricItemSettings } from './components/BiometricItemSettings';
import { BiometricAddReadingModal } from './components/BiometricsAddReading';
import { BiometricsChart } from './components/BiometricsChart';
import { BiometricSettings } from './components/BiometricsSettings';
import { BiometricsTable } from './components/BiometricsTable';
import { BloodPressureChart } from './components/BloodPressureChart';
import { RPMAdherenceCard } from './components/RPMAdherenceCard';

export const BIOMETRICS_ORDER: BiometricsType[] = [
  BiometricsType.BP_DIASTOLIC,
  BiometricsType.OXYGEN,
  BiometricsType.WEIGHT,
  BiometricsType.PULSE,
  BiometricsType.TEMPERATURE,
  BiometricsType.BLOOD_SUGAR,
];

const useStyles = makeStyles({
  dateRange: {
    marginRight: '1rem',
    height: '4rem',
  },
  toggle: {
    marginRight: '1rem',
    marginTop: '-1.55rem',
  },
  filters: {
    display: 'flex',
    gap: '1rem',
  },
  actions: {
    paddingTop: '1.125rem',
  },
  container: {
    display: 'grid',
    gap: '3rem',
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
});

type Params = {
  patientId: string;
};

export const REFRESH_INTERVAL = 1000 * 60;

const BIOMETRIC_VIEW_CHART = 1;
const BIOMETRIC_VIEW_TABLE = 2;
const BIOMETRIC_VIEW_OPTIONS = [
  {
    label: translate('biometrics.viewOptions.chart'),
    value: BIOMETRIC_VIEW_CHART,
  },
  {
    label: translate('biometrics.viewOptions.table'),
    value: BIOMETRIC_VIEW_TABLE,
  },
];

export const DashboardBiometrics = (props: RouteComponentProps<Params>) => {
  const styles = useStyles();
  const { patientId } = props.match.params;
  const member = useSelector((state) => state.memberInfoSlice.patient);

  const [loading, setLoading] = useState(false);
  const [biometricsSettingOpened, setBiometricsSettingOpened] = useState(false);
  const [configSettings, setConfigSettings] = useState<
    BiometricsConfig | undefined
  >();
  const [configs, setConfigs] = useState<BiometricsConfig[]>();
  const [systolicConfig, setSystolicConfig] = useState<BiometricsConfig>();
  const [viewOptions, setViewOptions] = useState(BIOMETRIC_VIEW_CHART);
  const [interval, setInterval] = useState<[Moment, Moment]>([
    moment().subtract(30, 'd').startOf('day'),
    moment().endOf('day'),
  ]);

  const [addReadingModal, setAddReadingModal] = useState<boolean>(false);
  const [addReadingType, setAddReadingType] = useState<BiometricsType | 'bp'>();

  const [rpmAdherence, setRPMAdherence] = useState<BiometricsRPMAdherence>();
  const [rpmTasks, setRPMTasks] = useState<Task[]>([]);

  const configSorter = (
    { type: typeA }: BiometricsConfig,
    { type: typeB }: BiometricsConfig,
  ) => {
    let orderA = BIOMETRICS_ORDER.indexOf(typeA);
    if (orderA === -1) {
      orderA = Infinity;
    }
    let orderB = BIOMETRICS_ORDER.indexOf(typeB);
    if (orderB === -1) {
      orderB = Infinity;
    }
    return orderA - orderB;
  };

  const fetchConfigs = async () => {
    try {
      const c = await fetchEnabledConfigs(patientId);
      setSystolicConfig(
        c.find(({ type }) => type === BiometricsType.BP_SYSTOLIC),
      );
      setConfigs(
        c
          .filter(({ type }) => type !== BiometricsType.BP_SYSTOLIC)
          .sort(configSorter),
      );
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  const programExtensionRPM = member?.programExtensions.find(
    ({ programExtension: { id } }) => id === ProgramExtension.RPM,
  );

  const getRPMAdhrerence = async () => {
    if (programExtensionRPM) {
      try {
        const rpmAdherence = await fetchRPMAdhrerence(patientId);
        setRPMAdherence(rpmAdherence);
      } catch (e) {
        showGlobalError(e as string);
      }
    } else {
      setRPMAdherence(undefined);
    }
  };

  const getRPMTaskSummary = async () => {
    if (member) {
      try {
        const { items } = await fetchAllTasks({
          limit: 50,
          memberId: [member.id],
          status: [
            TaskStatus.DELAYED.value,
            TaskStatus.ESCALATED.value,
            TaskStatus.IN_PROGRESS.value,
            TaskStatus.NOT_STARTED.value,
            TaskStatus.PENDING_AUTHORIZATION.value,
          ],
          taskDefinitionId: [
            TaskDefinition.RPM_NEW_ORDER_REQUEST,
            TaskDefinition.RPM_TRAINING_REQUEST,
            TaskDefinition.RPM_RETURN_DEVICE,
            TaskDefinition.RPM_TECH_ISSUE,
          ],
          topLevelOnly: true,
        });
        setRPMTasks(
          items.sort(({ status: a }, { status: b }) => {
            if ([TaskStatus.DELAYED, TaskStatus.ESCALATED].includes(a))
              return -1;
            if ([TaskStatus.DELAYED, TaskStatus.ESCALATED].includes(b))
              return 1;
            if (
              [
                TaskStatus.IN_PROGRESS,
                TaskStatus.PENDING_AUTHORIZATION,
              ].includes(a)
            )
              return -1;
            if (
              [
                TaskStatus.IN_PROGRESS,
                TaskStatus.PENDING_AUTHORIZATION,
              ].includes(b)
            )
              return 1;
            return 0;
          }),
        );
      } catch (e) {
        showGlobalError(e as string);
      }
    } else {
      setRPMTasks([]);
    }
  };

  const getAllData = async () => {
    setLoading(true);
    await Promise.all([
      fetchConfigs(),
      getRPMAdhrerence(),
      getRPMTaskSummary(),
    ]);
    setLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, [patientId]);

  return (
    <Panel id="biometrics-section" data-cy="biometrics-section">
      <Panel.Heading
        data-cy="biometrics-title"
        title={translate('biometrics.title')}
      >
        <Panel.Filters className="flex gap wrap">
          {!loading && programExtensionRPM && (
            <Chip
              color={ProgramExtensionStatus.getColorStatus(
                programExtensionRPM.status,
              )}
              size="large"
              type="contained-2"
            >
              <span className="bold">{`RPM: ${programExtensionRPM.status?.toString()}`}</span>
            </Chip>
          )}
          {member && !!rpmTasks?.length && (
            <Chip
              color={TaskStatus.COLOR_MAP.get(rpmTasks[0].status)?.color}
              onClick={() =>
                window.open(
                  `#/patients/${member.id}/tasks?createdDateRange=-1&selectedTab=tab-open`,
                  '_blank',
                  'noreferrer',
                )
              }
              size="large"
              title={
                <>
                  {rpmTasks.map(({ id, taskDefinition, status }) => (
                    <p
                      className="no-margin white"
                      key={`task-${id}-rpm-tooltip`}
                    >
                      {taskDefinition.name} - {status.toString()}
                    </p>
                  ))}
                </>
              }
              type="outlined"
            >
              <span className="bold">
                {translate('biometrics.rpmTasks', { count: rpmTasks.length })}
              </span>
            </Chip>
          )}
        </Panel.Filters>
        <Panel.Actions className={styles.actions}>
          <ToggleDateRange
            className={styles.dateRange}
            defaultValue={30}
            items={[7, 30, 90, 'custom']}
            onChange={(from, to) => {
              if (from && to) {
                // eslint-disable-next-line @typescript-eslint/no-implied-eval
                setInterval([moment(from.getTime()), moment(to.getTime())]);
              }
            }}
          />
          <ToggleButton
            className={styles.toggle}
            data-cy="biometrics-date-range"
            items={BIOMETRIC_VIEW_OPTIONS}
            label={translate('biometrics.view')}
            onChange={setViewOptions}
            value={viewOptions}
            size="small"
          />
          <IconButton
            data-cy="biometrics-settings"
            disabled={!configs?.length && !loading}
            onClick={() => {
              setAddReadingType(undefined);
              setAddReadingModal(true);
            }}
            tooltip={translate('biometrics.addReading')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
          <IconButton
            data-cy="biometrics-settings"
            disabled={!configs?.length && !loading}
            onClick={() => setBiometricsSettingOpened(true)}
            tooltip={translate('biometrics.settings')}
          >
            <SettingsIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <div className={styles.container}>
          {programExtensionRPM && rpmAdherence && rpmAdherence.lastReading && (
            <RPMAdherenceCard
              className="grid-span-12"
              member={member}
              data={rpmAdherence}
              showHistoricalAdherence
            />
          )}
          {programExtensionRPM &&
            rpmAdherence &&
            rpmAdherence.lastReading &&
            viewOptions === BIOMETRIC_VIEW_TABLE && (
              <br className="grid-span-12" />
            )}
          {viewOptions === BIOMETRIC_VIEW_CHART &&
            !!configs?.length &&
            configs?.map((c) => {
              if (c.type === BiometricsType.BP_DIASTOLIC && systolicConfig) {
                return (
                  <BloodPressureChart
                    className="grid-span-6"
                    diastolicConfig={c}
                    systolicConfig={systolicConfig}
                    key={c.id}
                    interval={interval}
                    onClickAdd={() => {
                      setAddReadingModal(true);
                      setAddReadingType('bp');
                    }}
                    onClickSettings={() => setConfigSettings(c)}
                  />
                );
              }
              return (
                <BiometricsChart
                  className="grid-span-6"
                  config={c}
                  key={c.id}
                  interval={interval}
                  onClickAdd={() => {
                    setAddReadingModal(true);
                    setAddReadingType(c.type);
                  }}
                  onClickSettings={() => setConfigSettings(c)}
                />
              );
            })}
        </div>
        {viewOptions === BIOMETRIC_VIEW_TABLE && !!configs?.length && (
          <BiometricsTable
            configs={[...configs, systolicConfig as BiometricsConfig]}
            interval={interval}
            patientId={Number(patientId)}
            onInvalidateReading={async (readingIds) => {
              try {
                const promises: Promise<any>[] = [];
                readingIds.forEach((id) =>
                  promises.push(
                    updateMeasurement(Number(patientId), id, { valid: false }),
                  ),
                );
                await Promise.all(promises);
              } catch (e) {
                showGlobalError(e as string);
              }
            }}
          />
        )}
        {!configs?.length && !loading && (
          <EmptyState>{translate('biometrics.emptyConfigs')}</EmptyState>
        )}
      </Panel.Body>

      <BiometricSettings
        open={biometricsSettingOpened}
        onClose={() => setBiometricsSettingOpened(false)}
        patientId={Number(patientId)}
      />
      <BiometricItemSettings
        config={configSettings}
        onClose={async (update) => {
          if (update) {
            await fetchConfigs();
          }
          setConfigSettings(undefined);
        }}
        patientId={Number(patientId)}
        systolicConfig={systolicConfig}
      />
      <BiometricAddReadingModal
        open={addReadingModal}
        type={addReadingType}
        onClose={() => setAddReadingModal(false)}
        onSubmit={async (params) => {
          try {
            await addMeasurement(patientId, params);
            await getAllData();
          } catch (e) {
            showGlobalError(e as string);
          }
        }}
      />
    </Panel>
  );
};

export default DashboardBiometrics;
