import { Enum } from './Enum';

export class ProgramExtensionStatus extends Enum {
  translationKey = 'programExtensionStatus';

  static asArray: ProgramExtensionStatus[] = [];

  static byKey: { [name: string]: ProgramExtensionStatus } = {};

  static readonly NOT_STARTED = new ProgramExtensionStatus('NOT_STARTED');

  static readonly IN_PROGRESS = new ProgramExtensionStatus('IN_PROGRESS');

  static readonly ACTIVE = new ProgramExtensionStatus('ACTIVE');

  static readonly DELAYED_ESCALATED = new ProgramExtensionStatus(
    'DELAYED_ESCALATED',
  );

  static readonly DELAYED_UNREACHABLE = new ProgramExtensionStatus(
    'DELAYED_UNREACHABLE',
  );

  static readonly DELAYED_TECH_ISSUE = new ProgramExtensionStatus(
    'DELAYED_TECH_ISSUE',
  );

  static readonly HOLD = new ProgramExtensionStatus('HOLD');

  static readonly PENDING_AUTH = new ProgramExtensionStatus('PENDING_AUTH');

  static readonly DISENROLLED = new ProgramExtensionStatus('DISENROLLED');

  static readonly DENIED_DECLINED = new ProgramExtensionStatus(
    'DENIED_DECLINED',
  );

  static getColorStatus(status?: ProgramExtensionStatus) {
    switch (status) {
      case ProgramExtensionStatus.ACTIVE:
        return 'secondary';
      case ProgramExtensionStatus.IN_PROGRESS:
      case ProgramExtensionStatus.PENDING_AUTH:
      case ProgramExtensionStatus.HOLD:
        return 'warning';
      case ProgramExtensionStatus.DELAYED_ESCALATED:
      case ProgramExtensionStatus.DELAYED_TECH_ISSUE:
      case ProgramExtensionStatus.DELAYED_UNREACHABLE:
        return 'error';
      case ProgramExtensionStatus.DENIED_DECLINED:
      case ProgramExtensionStatus.DISENROLLED:
      case ProgramExtensionStatus.NOT_STARTED:
        return 'action';
      default:
        return 'info';
    }
  }

  constructor(public readonly value: string, public readonly filter?: boolean) {
    super();
    ProgramExtensionStatus.asArray.push(this);
    ProgramExtensionStatus.byKey[value] = this;
  }

  static toSelectable() {
    return Enum.toSelectable(
      ProgramExtensionStatus.asArray.filter((item) => !item.filter),
    );
  }

  static getFilterSelectables() {
    return Enum.toSelectable(ProgramExtensionStatus.asArray);
  }
}

export default ProgramExtensionStatus;
