import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
// @ts-ignore
import qs from 'qs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import {
  CollectionMethod,
  Enum,
  Language,
  MemberRisk,
  ProgramExtensionStatus,
  RiskLevel,
  States,
} from '@vestahealthcare/common/enums';
import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  EventDetail,
  EventModel,
  EventModelExtended,
  EventNote,
  EventType,
  IssuePrompt,
  Organization,
  PaginationType,
  Patient,
  ProgramExtension,
} from '@vestahealthcare/common/models';
import { EventIssue } from '@vestahealthcare/common/models/EventIssue';
import EventStatus from '@vestahealthcare/common/models/EventStatus';
import { EventStatusGroup } from '@vestahealthcare/common/models/EventStatusGroup';
import {
  LS_DASHBOARD_EVENT_FILTERS,
  LS_DASHBOARD_EVENT_FILTERS_V2,
} from '@vestahealthcare/common/utils/constants';

import { Toast } from 'styleguide';
import {
  Button,
  CheckboxWithLabel,
  FilterButton,
  FilterItem,
  FilterSelectable,
  Modal,
  Panel,
  Select,
  Tabs,
  ToggleDateRange,
} from 'styleguide-v2';
import { TabsItem } from 'styleguide-v2/src/components/Tabs';

import {
  showGlobalError,
  showGlobalWarning,
} from 'dash/src/components/GlobalMessage';
import { CreateIncidentModal } from 'dash/src/pages/Incidents/CreateIncidentModal';
import {
  MemberEventsDetail,
  RefreshHandle,
} from 'dash/src/pages/MemberProfile/Events/MemberEventsDetail';
import { MemberEventAddModal } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventAdd';
import { MemberEventAddEncounter } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventAddEncounter';
import { MemberEventAddIssueModal } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventAddIssue';
import { MemberEventAddNote } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventAddNote';
import { MemberEventCloseModal } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventClose';
import { MemberEventFollowUpInfo } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventFollowUpInfo';
import { MemberEventHistoryModal } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventHistory';
import { MemberEventInvalidate } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventInvalidate';
import { MemberModifyUrgencyModal } from 'dash/src/pages/MemberProfile/Events/modals/MemberEventModifyUrgency';
import EventActions from 'dash/src/pages/MemberProfile/Events/types/EventActions';
import { AddTaskModal } from 'dash/src/pages/Tasks/AddTaskModal';
import { CacheServices } from 'dash/src/services';
import {
  GetParams,
  UpdateEventParams,
  acceptEventIssuePrompt,
  closeDocumentedEvent,
  closeEvent,
  createEvent,
  createEventEncounter,
  createEventIssue,
  createEventNote,
  fetchEventCounts,
  fetchEventDetail,
  fetchEventsDashboard,
  forceCloseEvent,
  invalidateEvent,
  unlinkIssues,
  updateEvent,
  updateEventNote,
} from 'dash/src/services/EventServices';
import { createIncident } from 'dash/src/services/IncidentServices';
import Session from 'dash/src/services/SessionServices';
import {
  createSkilledRecordFromEvent,
  createTOCRecordFromEvent,
} from 'dash/src/services/TOCServices';
import { transformDateToDaysRange } from 'dash/src/utils/dateUtils';
import {
  getCustom,
  getFilterItem,
  getFiltersEnabled,
  getServerFilters,
  loadNewFiltersFromStorage,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { EventFiltersModal } from './EventFiltersModal';
import { EventTableModel, EventsTable } from './EventsTable';
import { EventRefreshPanel } from './components/EventRefreshPanel';
import {
  EVENTS_PAGE_LIMIT,
  EVENTS_PAGE_TABS,
  EVENTS_PAGE_TAB_ALL,
  EVENTS_PAGE_TAB_ASSIGNED_ME,
  EVENTS_PAGE_TAB_ESCALATED,
  EVENTS_PAGE_TAB_NON_URGENT_FOLLOW_UP,
  EVENTS_PAGE_TAB_OPEN,
  EVENTS_PAGE_TAB_UNASSIGNED,
  EVENTS_PAGE_TAB_URGENT_FOLLOW_UP,
  EVENTS_PAGE_TAB_VITALS_ABNORMAL,
  TabsCount,
} from './constants';

const CHECK_NEW_EVENTS_TIMER = 45000;
const FOLLOW_UP_STATUSES = [
  EventStatus.NON_URGENT_FOLLOW_UP,
  EventStatus.URGENT_FOLLOW_UP,
];

interface SortOption extends Selectable {
  sort: string;
}

const FIRST_LEVEL_FILTERS: KeyGetEventParams[] = ['from', 'to', 'testMember'];

const SORT_OPTIONS: SortOption[] = [
  {
    label: translate('dashboardEvents.sort.createdNewest'),
    value: 'created-newest',
    sort: 'createdAt desc, followUpDatetime asc,  urgencyLevel asc',
  },
  {
    label: translate('dashboardEvents.sort.createdOldest'),
    value: 'created-oldest',
    sort: 'createdAt asc, followUpDatetime desc,  urgencyLevel desc',
  },
  {
    label: translate('dashboardEvents.sort.eventNewest'),
    value: 'event-newest',
    sort: 'eventDatetime desc, followUpDatetime asc,  urgencyLevel asc',
  },
  {
    label: translate('dashboardEvents.sort.eventOldest'),
    value: 'event-oldest',
    sort: 'eventDatetime asc, followUpDatetime desc,  urgencyLevel desc',
  },
  {
    label: translate('dashboardEvents.sort.followUp'),
    value: 'follow-up-desc',
    sort: 'followUpDatetime asc, urgencyLevel desc eventDatetime desc',
  },
];

const useStyles = makeStyles({
  check: {
    marginTop: '1rem',
  },
  dateRange: {
    marginTop: '-2.5rem',
  },
  dateRangeNew: {
    '&&': {
      flex: 'none',
      maxWidth: 'calc(100% - 10rem)',
    },
  },
  filters: {
    display: 'flex',
    gap: '1rem',
  },
  tabsContainer: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'space-between',
  },
  sort: {
    minWidth: '22.5rem',
  },
});

interface EventsStorage extends GetParams {
  defaultToggleRange: number;
  selectedTab: string;
}

const getFilters = (query: URLSearchParams): EventsStorage => {
  const filtersString = localStorage.getItem(LS_DASHBOARD_EVENT_FILTERS) || '';
  const filters = qs.parse(filtersString) || {};
  const dateRange =
    query.get('defaultToggleRange') || filters.defaultToggleRange || 7;
  const defaultToggleRange = isNaN(Number(dateRange))
    ? dateRange
    : Number(dateRange);
  const everEscalatedString =
    query.get('everEscalated') || filters.everEscalated || null;
  const everEscalated =
    everEscalatedString !== null ? everEscalatedString === 'true' : undefined;
  const testMemberString =
    query.get('testMember') || filters.testMember || false;
  const testMember = testMemberString === 'true';
  const pendingIncidentString =
    query.get('pendingIncident') || filters.pendingIncident || null;
  const pendingIncident =
    pendingIncidentString !== null
      ? pendingIncidentString === 'true'
      : undefined;

  return {
    assigneeId: getCustom(
      query.getAll('assigneeId'),
      filters.assigneeId,
      (item) => Number(item),
    ) as number[],
    assigneeGroupId: getCustom(
      query.getAll('assigneeGroupId'),
      filters.assigneeGroupId,
      (item) => Number(item),
    ) as number[],
    brandId: getCustom(query.getAll('brandId'), filters.brandId),
    careCoordinatorId: getCustom(
      query.getAll('careCoordinatorId'),
      filters.careCoordinatorId,
      (item) => Number(item),
    ) as number[],
    everEscalated,
    defaultToggleRange,
    language: getCustom(
      query.getAll('language'),
      filters.language,
      (item) => Language.byKey[item],
    ) as Language[],
    npOwnerId: getCustom(query.getAll('npOwnerId'), filters.npOwnerId, (item) =>
      Number(item),
    ) as number[],
    pendingIncident,
    programExtensionId: getCustom(
      query.getAll('programExtensionId'),
      filters.programExtensionId,
      (item) => Number(item),
    ) as number[],
    programExtensionStatus: getCustom(
      query.getAll('programExtensionStatus'),
      filters.programExtensionStatus,
      (item) => ProgramExtensionStatus.byKey[item],
    ) as ProgramExtensionStatus[],
    referralSourceId: getCustom(
      query.getAll('referralSourceId'),
      filters.referralSourceId,
      (item) => Number(item),
    ) as number[],
    riskLevel: getCustom(
      query.getAll('riskLevel'),
      filters.riskLevel,
      (item) => MemberRisk.byKey[item],
    ) as MemberRisk[],
    rnOwnerId: getCustom(query.getAll('rnOwnerId'), filters.rnOwnerId, (item) =>
      Number(item),
    ) as number[],
    statusId: getCustom(
      query.getAll('statusId'),
      filters.statusId,
      (item) => item,
    ) as string[],
    selectedTab:
      query.get('selectedTab') || filters.selectedTab || EVENTS_PAGE_TAB_OPEN,
    sort: query.get('sort') || filters.sort,
    stateId: getCustom(
      query.getAll('stateId'),
      filters.stateId,
      (item) => States.byKey[item],
    ),
    testMember,
    typeId: getCustom(query.getAll('typeId'), filters.typeId, (item) =>
      Number(item),
    ) as number[],
  };
};

const UNASSIGNED = new Employee({ fullName: translate('global.unassigned') });

let EventsDashboardLastFilters = '';

let pendingAction:
  | {
      action: Selectable;
      data: any;
    }
  | undefined;

type KeyGetEventParams = keyof GetParams;

export const EventsPage = () => {
  const { showIncidents, showFiltersRefactorEvents } = useFlags();
  const query = useQueryParams();
  const history = useHistory();
  const styles = useStyles();
  const {
    selectedTab: st,
    defaultToggleRange: dTR,
    sort: dSort,
    ...rest
  } = getFilters(query);

  const eventsRef = useRef<RefreshHandle[]>([]);
  const { actingUser } = Session;

  const [loading, setLoading] = useState<boolean>(false);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false);
  const [isOpenFilters, setOpenFilters] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(st);
  const [showRefreshPanel, setShowRefreshPanel] = useState<boolean>(false);

  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [eventStatuses, setEventStatuses] = useState<EventStatus[]>([]);
  const [referralSources, setReferralSources] = useState<Organization[]>([]);
  const [programExtensions, setProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroup[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(EVENTS_PAGE_LIMIT);
  const [events, setEvents] = useState<EventModelExtended[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [tabsCount, setTabsCount] = useState<TabsCount>({});
  const [tabs, setTabs] = useState<TabsItem[]>(EVENTS_PAGE_TABS(tabsCount));

  const [filters, setFilters] = useState<GetParams>(rest);
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetEventParams]?: FilterItem;
    }
  >();
  const [disabledFilters, setDisabledFilters] = useState<KeyGetEventParams[]>(
    [],
  );

  const [sort, setSort] = useState<SortOption>(
    SORT_OPTIONS.find(({ value }) => value === dSort) || SORT_OPTIONS[0],
  );
  const { testMember, ...countFilters } = filters;
  const [dateInterval, setDateInterval] = useState<[Date, Date]>();
  const [defaultToggleRange, setDefaultToggleRange] = useState<number | string>(
    dTR,
  );

  const [openAddEvent, setOpenAddEvent] = useState<boolean>(false);
  const [openAddEventIssue, setOpenAddEventIssue] = useState<boolean>(false);
  const [openAddEncounter, setOpenAddEncounter] = useState<boolean>(false);
  const [openAddNote, setOpenAddNote] = useState<boolean>(false);
  const [openAddTask, setOpenAddTask] = useState<boolean>(false);
  const [openCloseEvent, setOpenCloseEvent] = useState<boolean>(false);
  const [openInvalidateEvent, setOpenInvalidateEvent] = useState<boolean>(
    false,
  );
  const [openModifyFollowUp, setOpenModifyFollowUp] = useState<boolean>(false);
  const [followUpStatus, setFollowUpStatus] = useState<EventStatus>();
  const [openModifyUrgency, setOpenModifyUrgency] = useState<boolean>(false);
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [openAddIncident, setOpenAddIncident] = useState<boolean>(false);
  const [eventPromptDetail, setEventPromptDetail] = useState<EventDetail>();
  const [eventIncidentDetail, setEventIncidentDetail] = useState<EventDetail>();
  const [eventModalDetail, setEventModalDetail] = useState<
    EventModelExtended
  >();
  const [eventModalData, setEventModalData] = useState<any>();
  const [eventModalMember, setEventModalMember] = useState<Patient>();

  const CCs = useMemo(
    () => employees.filter(({ isCareCoordinator }) => isCareCoordinator),
    [employees],
  );
  const NPs = useMemo(() => employees.filter(({ isNP }) => isNP), [employees]);
  const RNs = useMemo(() => employees.filter(({ isRN }) => isRN), [employees]);

  const getInitialData = async () => {
    const [
      eventTypes,
      eventStatuses,
      programExtensions,
      referralSources,
      employees,
      employeeGroups,
      languages,
      brands,
    ] = await Promise.all([
      CacheServices.getEventTypes(),
      CacheServices.getEventStatuses(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getOrganizations(),
      CacheServices.getEmployees(),
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getLanguages(),
      CacheServices.getOrganizationBrands(),
    ]);
    setFiltersLoading(true);
    setEventTypes(eventTypes || []);
    setEventStatuses(eventStatuses || []);
    setProgramExtensions(programExtensions);
    setReferralSources(
      referralSources.filter(({ hasValidDate }) => hasValidDate),
    );
    setEmployees(employees);
    setEmployeeGroups(employeeGroups);
    setLanguages(languages || []);
    setBrands(
      brands.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );
    setFiltersLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (newFilters) {
      saveNewFiltersToStorage(LS_DASHBOARD_EVENT_FILTERS_V2, newFilters);
    }
  }, [newFilters]);

  useEffect(() => {
    if (
      brands?.length &&
      CCs?.length &&
      employeeGroups?.length &&
      employees?.length &&
      eventStatuses?.length &&
      eventTypes?.length &&
      languages?.length &&
      NPs?.length &&
      programExtensions?.length &&
      referralSources?.length &&
      RNs?.length
    ) {
      const storedFilters = loadNewFiltersFromStorage(
        LS_DASHBOARD_EVENT_FILTERS_V2,
        {
          assigneeId: {
            data: [
              ...EmployeeGroup.toSelectable(employeeGroups),
              ...Employee.toSelectable(employees),
            ],
            label: translate('dashboardEvents.filters.assignee'),
            multiple: true,
          },
          brandId: {
            data: brands,
            label: translate('dashboardEvents.filters.brand'),
            multiple: true,
          },
          careCoordinatorId: {
            data: Employee.toSelectable(CCs),
            label: translate('dashboardEvents.filters.careCoordinator'),
            multiple: true,
          },
          everEscalated: {
            label: translate('dashboardEvents.filters.everEscalated'),
          },
          language: {
            data: Language.toSelectable(languages),
            label: translate('dashboardEvents.filters.language'),
            multiple: true,
          },
          npOwnerId: {
            data: Employee.toSelectable(NPs),
            label: translate('dashboardEvents.filters.npOwner'),
            multiple: true,
          },
          pendingIncident: {
            label: translate('dashboardEvents.filters.pendingIncident'),
          },
          programExtensionId: {
            data: ProgramExtension.toSelectable(programExtensions),
            label: translate('dashboardEvents.filters.programExtension'),
            multiple: true,
          },
          programExtensionStatus: {
            data: Enum.toSelectable(ProgramExtensionStatus.asArray),
            label: translate('dashboardEvents.filters.programExtensionStatus'),
            multiple: true,
          },
          referralSourceId: {
            data: Organization.toSelectable(referralSources),
            label: translate('dashboardEvents.filters.referralSource'),
            multiple: true,
          },
          riskLevel: {
            data: Enum.toSelectable(RiskLevel.asArray),
            label: translate('dashboardEvents.filters.riskLevel'),
            multiple: true,
          },
          rnOwnerId: {
            data: Employee.toSelectable(RNs),
            label: translate('dashboardEvents.filters.rnOwner'),
            multiple: true,
          },
          stateId: {
            data: Enum.toSelectable(States.asArray),
            label: translate('dashboardEvents.filters.state'),
            multiple: true,
          },
          statusId: {
            data: EventStatus.toSelectable(eventStatuses),
            label: translate('dashboardEvents.filters.status'),
            multiple: true,
          },
          testMember: {
            label: translate('dashboardEvents.filters.testMember'),
          },
          typeId: {
            data: EventType.toSelectable(eventTypes),
            label: translate('dashboardEvents.filters.type'),
            multiple: true,
          },
          vitalCollectionMethod: {
            data: Enum.toSelectable(CollectionMethod.asArray),
            label: translate('dashboardEvents.filters.vitalsCollectionMethod'),
            multiple: true,
          },
        } as { [x in KeyGetEventParams]: any },
      );
      setNewFilters(storedFilters);
    }
  }, [
    brands,
    employeeGroups,
    employees,
    eventStatuses,
    eventTypes,
    languages,
    programExtensions,
    referralSources,
  ]);

  const getFiltersWithTab = (
    tab: string,
  ): Partial<{ [x in KeyGetEventParams]: any }> => {
    if (tab === EVENTS_PAGE_TAB_OPEN) {
      return {
        statusGroupId: [EventStatusGroup.OPEN, EventStatusGroup.IN_PROGRESS],
      };
    }
    if (tab === EVENTS_PAGE_TAB_UNASSIGNED) {
      return {
        assigneeId: [0],
        assigneeGroupId: [0],
        statusGroupId: [EventStatusGroup.OPEN, EventStatusGroup.IN_PROGRESS],
      };
    }
    if (tab === EVENTS_PAGE_TAB_ASSIGNED_ME) {
      return {
        assigneeId: [actingUser.id],
        statusGroupId: [EventStatusGroup.OPEN, EventStatusGroup.IN_PROGRESS],
      };
    }
    if (tab === EVENTS_PAGE_TAB_ESCALATED) {
      return { statusId: [EventStatus.ESCALATED], everEscalated: true };
    }
    if (tab === EVENTS_PAGE_TAB_NON_URGENT_FOLLOW_UP) {
      return {
        statusId: [EventStatus.NON_URGENT_FOLLOW_UP],
      };
    }
    if (tab === EVENTS_PAGE_TAB_URGENT_FOLLOW_UP) {
      return {
        statusId: [EventStatus.URGENT_FOLLOW_UP],
      };
    }
    if (tab === EVENTS_PAGE_TAB_VITALS_ABNORMAL) {
      return {
        statusId: [EventStatus.VITALS_RECHECK_ABNORMAL],
      };
    }
    return {};
  };

  const retrieveEvents = async () => {
    let currentFilters: GetParams;
    if (newFilters && showFiltersRefactorEvents) {
      const { assigneeId, ...restFilters } = newFilters;
      const parsedFilters: {
        [x in KeyGetEventParams]?: FilterItem;
      } = restFilters;
      if (assigneeId) {
        const assigneeIds = (assigneeId?.value as FilterSelectable[])?.filter(
          ({ info }) => info instanceof Employee,
        );
        const assigneeGroupIds = (assigneeId?.value as FilterSelectable[])?.filter(
          ({ info }) => info instanceof EmployeeGroup,
        );
        if (assigneeIds?.length) {
          parsedFilters.assigneeId = {
            ...assigneeId,
            value: assigneeIds,
          };
        }
        if (assigneeGroupIds?.length) {
          parsedFilters.assigneeGroupId = {
            ...assigneeId,
            value: assigneeGroupIds,
          };
        }
      }
      currentFilters = {
        ...getServerFilters(parsedFilters),
        from: moment(dateInterval && dateInterval[0].getTime()),
        to: moment(dateInterval && dateInterval[1].getTime()),
      } as GetParams;
    } else {
      currentFilters = {
        ...filters,
        from: moment(dateInterval && dateInterval[0].getTime()),
        to: moment(dateInterval && dateInterval[1].getTime()),
        testMember: filters.testMember,
      };
    }
    return await fetchEventsDashboard({
      offset: page * pageSize,
      limit: pageSize,
      sort: sort.sort,
      ...currentFilters,
      ...getFiltersWithTab(selectedTab),
    });
  };

  const getEvents = async () => {
    if (showFiltersRefactorEvents) {
      if (!newFilters) return;
    }

    let newEvents: EventModel[] | undefined;
    if (dateInterval) {
      try {
        EventsDashboardLastFilters = JSON.stringify(filters);

        const { items, pagination: newPagination } = await retrieveEvents();

        if (EventsDashboardLastFilters === JSON.stringify(filters)) {
          if (page === 0) {
            newEvents = items;
            setEvents(items);
          } else {
            newEvents = [...events, ...items];
            setEvents(newEvents);
          }
          setPagination(newPagination);
          setShowRefreshPanel(false);
          EventsDashboardLastFilters = '';
        }
      } catch (e) {
        showGlobalError(e as string);
        EventsDashboardLastFilters = '';
      }
      return newEvents;
    }
  };

  const getEventsCount = async () => {
    if (dateInterval) {
      let currentFilters: GetParams;
      if (showFiltersRefactorEvents) {
        if (!newFilters) return;
        const { assigneeId, ...restFilters } = newFilters;
        const parsedFilters: {
          [x in KeyGetEventParams]?: FilterItem;
        } = restFilters;
        if (assigneeId) {
          const assigneeIds = (assigneeId?.value as FilterSelectable[])?.filter(
            ({ info }) => info instanceof Employee,
          );
          const assigneeGroupIds = (assigneeId?.value as FilterSelectable[])?.filter(
            ({ info }) => info instanceof EmployeeGroup,
          );
          if (assigneeIds?.length) {
            parsedFilters.assigneeId = {
              ...assigneeId,
              value: assigneeIds,
            };
          }
          if (assigneeGroupIds?.length) {
            parsedFilters.assigneeGroupId = {
              ...assigneeId,
              value: assigneeGroupIds,
            };
          }
        }
        currentFilters = {
          ...getServerFilters(parsedFilters),
          from: moment(dateInterval[0].getTime()),
          to: moment(dateInterval[1].getTime()),
        };
      } else {
        currentFilters = {
          ...filters,
          from: moment(dateInterval[0].getTime()),
          to: moment(dateInterval[1].getTime()),
          testMember: filters.testMember,
        };
      }

      try {
        const {
          all,
          open,
          unassigned,
          assignedToMe,
          escalated,
          nonUrgentFollowUp,
          urgentFollowUp,
          vitalsAbnormal,
        } = await fetchEventCounts(currentFilters);
        const tabsCount: TabsCount = {
          open,
          all,
          unassigned,
          assigned: assignedToMe,
          escalated,
          nonUrgentFollowUp,
          urgentFollowUp,
          vitalsAbnormal,
        };
        setTabsCount(tabsCount);
        return tabsCount;
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  useEffect(() => {
    setTabs(EVENTS_PAGE_TABS(tabsCount));
  }, [tabsCount]);

  const getEventsDataWithCount = async () => {
    setLoading(true);
    await getEventsCount();
    await getEvents();
    setLoading(false);
  };

  const getOnlyEvents = async () => {
    setLoading(true);
    await getEvents();
    setLoading(false);
  };

  useEffect(() => {
    if (selectedTab && (filters || newFilters) && dateInterval) {
      getEventsDataWithCount();
    }
  }, [
    dateInterval,
    filters.testMember,
    JSON.stringify(newFilters?.testMember),
  ]);

  useEffect(() => {
    if (selectedTab === EVENTS_PAGE_TAB_ALL) {
      const { statusId } = getFilters(query);
      setFilters({
        ...filters,
        statusId,
      });
    } else if (filters?.statusId?.length) {
      setFilters({
        ...filters,
        statusId: [],
      });
    } else if (selectedTab && filters && dateInterval) {
      getOnlyEvents();
    }
  }, [selectedTab, sort]);

  useEffect(() => {
    const result = [] as KeyGetEventParams[];
    if (
      [
        EVENTS_PAGE_TAB_ESCALATED,
        EVENTS_PAGE_TAB_NON_URGENT_FOLLOW_UP,
        EVENTS_PAGE_TAB_URGENT_FOLLOW_UP,
        EVENTS_PAGE_TAB_VITALS_ABNORMAL,
      ].includes(selectedTab)
    ) {
      result.push('statusId');
    }
    if (
      [EVENTS_PAGE_TAB_ASSIGNED_ME, EVENTS_PAGE_TAB_UNASSIGNED].includes(
        selectedTab,
      )
    ) {
      result.push('assigneeId');
    }
    if (EVENTS_PAGE_TAB_ESCALATED === selectedTab) {
      result.push('everEscalated');
    }
    setDisabledFilters(result);
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab && filters && newFilters && dateInterval) {
      getEventsDataWithCount();
    }
  }, [page, pageSize, filters, newFilters]);

  const findDiffEvent = (a: EventModel[], b: EventModel[]) =>
    a.find(
      ({ id, updatedAt }, index) =>
        !b[index] || id !== b[index].id || updatedAt !== b[index].updatedAt,
    );

  const checkForChanges = async () => {
    setTabsCount((count) => {
      if (count && !EventsDashboardLastFilters) {
        getEventsCount().then((newCount) => {
          if (!EventsDashboardLastFilters && newCount) {
            if (
              count.all !== newCount.all ||
              count.open !== newCount.open ||
              count.unassigned !== newCount.unassigned ||
              count.assigned !== newCount.assigned ||
              count.escalated !== newCount.escalated ||
              count.urgentFollowUp !== newCount.urgentFollowUp ||
              count.nonUrgentFollowUp !== newCount.nonUrgentFollowUp
            ) {
              setShowRefreshPanel(() => true);
            }
          }
        });
      }
      return count;
    });

    if (showFiltersRefactorEvents) {
      if (!newFilters) return;
    }

    setEvents((oldEvents) => {
      if (oldEvents?.length && !EventsDashboardLastFilters) {
        retrieveEvents().then(({ items: newEvents }) => {
          if (
            !EventsDashboardLastFilters &&
            newEvents?.length &&
            findDiffEvent(newEvents, oldEvents)
          ) {
            setShowRefreshPanel(() => true);
          }
        });
      }
      return oldEvents;
    });
  };

  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    timer.current = setInterval(
      () => checkForChanges(),
      CHECK_NEW_EVENTS_TIMER,
    );
    return () => {
      setShowRefreshPanel(false);
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [dateInterval, filters, newFilters, selectedTab, sort]);

  useEffect(() => {
    const {
      language,
      programExtensionStatus,
      riskLevel,
      stateId,
      statusId,
      vitalCollectionMethod,
      ...rest
    } = filters;
    const { statusId: storedStatusId } = getFilters(query);

    const flatFilters = {
      ...rest,
      language: (language as Language[])?.map(({ value }) => value),
      programExtensionStatus: (programExtensionStatus as ProgramExtensionStatus[])?.map(
        ({ value }) => value,
      ),
      riskLevel: (riskLevel as RiskLevel[])?.map(({ value }) => value),
      selectedTab,
      sort: sort.value,
      stateId: (stateId as States[])?.map(({ value }) => value),
      statusId: selectedTab === EVENTS_PAGE_TAB_ALL ? statusId : storedStatusId,
      defaultToggleRange,
    };
    const storedQuery = qs.stringify(flatFilters, { arrayFormat: 'repeat' });
    history.replace(`?${storedQuery}`);

    localStorage.setItem(LS_DASHBOARD_EVENT_FILTERS, qs.stringify(flatFilters));
  }, [filters, selectedTab, defaultToggleRange, sort]);

  useEffect(() => {
    if (
      !showFiltersRefactorEvents &&
      filters.referralSourceId &&
      filters.referralSourceId.length > 1
    ) {
      const lastAddedId =
        filters.referralSourceId[filters.referralSourceId.length - 1];
      const lastAdded = referralSources.find(({ id }) => id === lastAddedId);
      const newOrganizations = filters.referralSourceId.filter(
        (child) => !lastAdded?.hasDescendant(child),
      );
      if (newOrganizations.length < filters.referralSourceId.length) {
        setFilters({ ...filters, referralSourceId: newOrganizations });
      }
    } else if (
      showFiltersRefactorEvents &&
      (newFilters?.referralSourceId?.value as FilterSelectable[])?.length > 1
    ) {
      const lastAddedId = (newFilters?.referralSourceId
        ?.value as FilterSelectable[])[
        (newFilters?.referralSourceId?.value as FilterSelectable[]).length - 1
      ]?.value;
      const lastAdded = referralSources.find(({ id }) => id === lastAddedId);
      const newOrganizations = (newFilters?.referralSourceId
        ?.value as FilterSelectable[]).filter(
        (child) => !lastAdded?.hasDescendant(child.value as number),
      );
      if (
        newOrganizations.length <
        (newFilters?.referralSourceId?.value as FilterSelectable[]).length
      ) {
        setNewFilters({
          ...newFilters,
          referralSourceId: getFilterItem(
            translate('dashboardEvents.filters.testMember'),
            newOrganizations,
          ),
        });
      }
    }
  }, [filters.referralSourceId, newFilters?.referralSourceId]);

  const getAssigneeFilterValue = () => {
    if (selectedTab === EVENTS_PAGE_TAB_ASSIGNED_ME) return [actingUser];
    if (selectedTab === EVENTS_PAGE_TAB_UNASSIGNED) return [UNASSIGNED];
    return [
      ...(filters.assigneeGroupId && filters.assigneeGroupId[0] === 0
        ? [UNASSIGNED]
        : []),
      ...employeeGroups?.filter(
        ({ id }) =>
          filters.assigneeGroupId &&
          filters.assigneeGroupId?.indexOf(id) !== -1,
      ),
      ...employees.filter(
        ({ id }) =>
          filters.assigneeId && filters.assigneeId?.indexOf(id) !== -1,
      ),
    ];
  };

  const getStatusFilterItems = () => {
    if (
      selectedTab === EVENTS_PAGE_TAB_OPEN ||
      selectedTab === EVENTS_PAGE_TAB_ASSIGNED_ME ||
      selectedTab === EVENTS_PAGE_TAB_UNASSIGNED
    ) {
      const status = eventStatuses?.filter(({ group: { id } }) =>
        [EventStatusGroup.OPEN, EventStatusGroup.IN_PROGRESS].includes(id),
      );
      return status || [];
    }
    if (selectedTab === EVENTS_PAGE_TAB_ESCALATED) {
      const status = eventStatuses?.find(
        ({ id }) => id === EventStatus.ESCALATED,
      );
      return status ? [status] : [];
    }
    if (selectedTab === EVENTS_PAGE_TAB_NON_URGENT_FOLLOW_UP) {
      const status = eventStatuses?.find(
        ({ id }) => id === EventStatus.NON_URGENT_FOLLOW_UP,
      );
      return status ? [status] : [];
    }
    if (selectedTab === EVENTS_PAGE_TAB_URGENT_FOLLOW_UP) {
      const status = eventStatuses?.find(
        ({ id }) => id === EventStatus.URGENT_FOLLOW_UP,
      );
      return status ? [status] : [];
    }
    if (selectedTab === EVENTS_PAGE_TAB_VITALS_ABNORMAL) {
      const status = eventStatuses?.find(
        ({ id }) => id === EventStatus.VITALS_RECHECK_ABNORMAL,
      );
      return status ? [status] : [];
    }
    return eventStatuses;
  };

  const getStatusFilterValue = () => {
    const items = getStatusFilterItems();
    const filter = items?.filter(({ id }) => filters?.statusId?.includes(id));

    if (!filter?.length && items?.length !== eventStatuses?.length)
      return items;

    return filter;
  };

  const getEverEscalatedFilterValue = () => {
    if (selectedTab === EVENTS_PAGE_TAB_ESCALATED) return true;
    return filters.everEscalated !== undefined
      ? filters.everEscalated
      : undefined;
  };

  const setActionLoading = (index: number, value: boolean) => {
    if (events[index]) {
      (events[index] as EventTableModel).actionLoading = value;
      setEvents([...events]);
    }
  };

  const addDetail = async (evt: EventTableModel, index: number) => {
    if (!evt.open) {
      evt.open = true;
      evt.detail = (
        <MemberEventsDetail
          eventId={evt.id}
          ref={(ref: RefreshHandle) => (eventsRef.current[index] = ref)}
          onEventAction={onEventAction}
          onRetrieveDetail={(detail) => {
            setTimeout(() => {
              evt.detailData = detail;
              setEvents([...events]);
            }, 50);
          }}
          onUnlinkIssue={(event, issues) => {
            setEventModalDetail(event);
            setEventModalData(issues);
            setOpenAddEvent(true);
          }}
          patient={evt.member as Patient}
        />
      );
      setEvents([...events]);
    }
  };

  const getCompleteEvent = async (event: EventModel | EventDetail) => {
    let detail = event as EventDetail;
    if (!(event instanceof EventDetail)) {
      detail = await fetchEventDetail(event.id);
    }
    return detail;
  };

  const doAddNoteEvent = async (
    event: EventModel,
    eventIndex: number,
    modalData?: any,
  ) => {
    setActionLoading(eventIndex, true);
    const detail = await getCompleteEvent(event);
    if (!detail.hasEvaluationOpen() || modalData?.id) {
      setOpenAddNote(true);
      setEventModalData(modalData);
    } else {
      showGlobalWarning({
        title: translate('memberEvents.warningAddNoteTitle'),
        messages: [{ message: translate('memberEvents.warningAddNoteBody') }],
        hideContactUs: true,
      });
    }
    setActionLoading(eventIndex, false);
  };

  const doShowHistoryEvent = async (event: EventModel, eventIndex: number) => {
    setActionLoading(eventIndex, true);
    const detail = await getCompleteEvent(event);
    setEventModalDetail(detail);
    setOpenHistory(true);
    setActionLoading(eventIndex, false);
  };

  const checkMissingDetails = async (
    event: EventModel,
    eventIndex: number,
    currentPendingAction: EventActions,
  ) => {
    const detail = await fetchEventDetail(event.id);

    if (detail.hasMissingDetails) {
      addDetail(events[eventIndex] as EventTableModel, eventIndex);
      const timer = setInterval(() => {
        if (eventsRef.current[eventIndex]) {
          eventsRef.current[eventIndex].missingDetails();
          clearInterval(timer);
        }
      }, 100);

      const issues = detail.getMissingDetails();
      if (issues?.length > 1) {
        showGlobalWarning({
          title: translate('memberEvents.warningMissingFieldsTitle'),
          messages: [
            { message: translate('memberEvents.warningMissingFieldsBody') },
          ],
          hideContactUs: true,
        });
        eventsRef.current[eventIndex].missingDetails();
      } else {
        new Toast({
          title: translate('memberEvents.warningMissingFieldsTitle'),
          body: translate('memberEvents.warningMissingFieldsBody'),
          type: 'warning',
          position: 'bottom-right',
        });
        eventsRef.current[eventIndex].editIssue(issues[0], true);
        pendingAction = {
          action: {
            value: currentPendingAction.value,
            label: currentPendingAction.toString(),
          } as Selectable,
          data: detail,
        };
      }
    }
    return {
      hasMissingDetails: detail.hasMissingDetails,
      detail,
    };
  };

  const doCloseEvent = async (event: EventModel, eventIndex: number) => {
    setActionLoading(eventIndex, true);
    const detail = await getCompleteEvent(event);
    if (detail.canCloseEvent()) {
      const { hasMissingDetails } = await checkMissingDetails(
        event,
        eventIndex,
        EventActions.CLOSE_EVENT,
      );
      if (!hasMissingDetails) {
        setOpenCloseEvent(true);
      }
    } else if (detail.isClosed) {
      setOpenCloseEvent(true);
    } else if (detail.canForceCloseEvent(actingUser)) {
      setActionLoading(eventIndex, true);
      await forceCloseEvent(event.id);
      await getEvents();
      setActionLoading(eventIndex, false);
    } else {
      showGlobalWarning({
        title: translate('memberEvents.warningCloseEventTitle'),
        messages: [
          { message: translate('memberEvents.warningCloseEventBody') },
        ],
        hideContactUs: true,
      });
    }
    setActionLoading(eventIndex, false);
  };

  const doSubmitIncident = async (event: EventModel, eventIndex: number) => {
    if (
      [EventType.FALL, EventType.ER_VISIT, EventType.FACILITY].includes(
        event.type.id,
      )
    ) {
      setActionLoading(eventIndex, true);
      const { hasMissingDetails, detail } = await checkMissingDetails(
        event,
        eventIndex,
        EventActions.SUBMIT_INCIDENT,
      );
      if (!hasMissingDetails) {
        setEventIncidentDetail(detail);
        setOpenAddIncident(true);
      }
    } else {
      setOpenAddIncident(true);
    }
    setActionLoading(eventIndex, false);
  };

  const doSubmitToc = async (event: EventModel, eventIndex: number) => {
    const detail = await getCompleteEvent(event);
    if (!detail?.member?.id) return;

    setActionLoading(eventIndex, true);
    const { id } = await createTOCRecordFromEvent(detail.member.id, event.id);
    setActionLoading(eventIndex, false);
    window.open(`#/dashboard/toc/${id}`, '_blank', 'noreferrer');
  };

  const doSubmitSkilled = async (event: EventModel, eventIndex: number) => {
    const detail = await getCompleteEvent(event);
    if (!detail?.member?.id) return;

    setActionLoading(eventIndex, true);
    const { id } = await createSkilledRecordFromEvent(
      detail.member.id,
      event.id,
    );
    setActionLoading(eventIndex, false);
    window.open(`#/dashboard/skilled/${id}`, '_blank', 'noreferrer');
  };

  const doCloseDocumented = async (event: EventModel, eventIndex: number) => {
    try {
      const { hasMissingDetails } = await checkMissingDetails(
        event,
        eventIndex,
        EventActions.CLOSE_DOCUMENTED,
      );
      if (!hasMissingDetails) {
        await closeDocumentedEvent(event.id);
        await getEvents();
      }
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  const onEventAction = async (
    { value }: Selectable,
    event: EventModelExtended | EventDetail,
    index?: number,
    modalData?: any,
  ) => {
    const eventIndex = index || events?.findIndex(({ id }) => id === event.id);
    setEventModalMember(event.member as Patient);
    setEventModalDetail(event);
    if (value === EventActions.ADD_ADDENDUM.value) {
      setOpenAddNote(true);
      setEventModalData(new EventNote({ addendum: true }));
    } else if (value === EventActions.ADD_ISSUE.value) {
      setOpenAddEventIssue(true);
    } else if (value === EventActions.ADD_ENCOUNTER.value) {
      setOpenAddEncounter(true);
    } else if (value === EventActions.ADD_NOTE.value) {
      await doAddNoteEvent(event, eventIndex, modalData);
    } else if (value === EventActions.ADD_TASK.value) {
      setOpenAddTask(true);
    } else if (value === EventActions.SHOW_HISTORY.value) {
      doShowHistoryEvent(event, eventIndex);
    } else if (value === EventActions.CLOSE_EVENT.value) {
      await doCloseEvent(event, eventIndex);
    } else if (value === EventActions.MODIFY_FOLLOW_UP.value) {
      setOpenModifyFollowUp(true);
      setEventModalData(modalData);
    } else if (value === EventActions.INVALIDATE_EVENT.value) {
      setOpenInvalidateEvent(true);
    } else if (value === EventActions.CLOSE_DOCUMENTED.value) {
      await doCloseDocumented(event, eventIndex);
    } else if (value === EventActions.MODIFY_URGENCY.value) {
      setOpenModifyUrgency(true);
    } else if (value === EventActions.UNLINK_ISSUE.value) {
      addDetail(events[eventIndex] as EventTableModel, eventIndex);
      const timer = setInterval(() => {
        if (eventsRef.current[eventIndex]) {
          eventsRef.current[eventIndex].unlinkIssues();
          clearInterval(timer);
        }
      }, 100);
    } else if (value === EventActions.SUBMIT_INCIDENT.value) {
      doSubmitIncident(event, eventIndex);
    } else if (value === EventActions.VIEW_INCIDENT.value) {
      window.open(
        `#/admin/incidents/${event.incidentId}`,
        '_blank',
        'noreferrer',
      );
    } else if (value === EventActions.SUBMIT_TOC.value) {
      doSubmitToc(event, eventIndex);
    } else if (value === EventActions.VIEW_TOC.value) {
      const detail = await getCompleteEvent(event);
      window.open(
        `#/dashboard/toc/${detail.tocRecordId}`,
        '_blank',
        'noreferrer',
      );
    } else if (value === EventActions.SUBMIT_SKILLED.value) {
      doSubmitSkilled(event, eventIndex);
    } else if (value === EventActions.VIEW_SKILLED.value) {
      const detail = await getCompleteEvent(event);
      window.open(
        `#/dashboard/skilled/${detail.skilledRecordId}`,
        '_blank',
        'noreferrer',
      );
    } else if (
      value === EventActions.CHECK_PENDING_ACTIONS.value &&
      pendingAction?.action
    ) {
      onEventAction(pendingAction.action, pendingAction.data);
      pendingAction = undefined;
    }
  };

  const refreshDetail = (id: number) => {
    const eventIdx = events.findIndex(({ id: evtId }) => evtId === id);
    if (eventIdx !== -1) {
      eventsRef.current[eventIdx]?.refresh();
    }
  };

  const filtersData = {
    assignee: [
      ...Employee.toSelectable([UNASSIGNED]),
      ...EmployeeGroup.toSelectable(employeeGroups),
      ...Employee.toSelectable(employees),
    ],
    brands,
    ccs: Employee.toSelectable(CCs),
    statusId: EventStatus.toSelectable(getStatusFilterItems()),
    eventTypes: EventType.toSelectable(eventTypes),
    languages: Language.toSelectable(languages),
    nps: Employee.toSelectable(NPs),
    programExtensions: ProgramExtension.toSelectable(programExtensions),
    referralSources: Organization.toSelectable(referralSources),
    rns: Employee.toSelectable(RNs),
  };

  return (
    <Panel>
      <Panel.Heading
        title={translate('dashboardEvents.title')}
        filtersV2={showFiltersRefactorEvents}
      >
        <Panel.Filters className={styles.filters}>
          <ToggleDateRange
            className={styles.dateRange}
            defaultValue={defaultToggleRange}
            items={[1, 7, 30, 90, 'custom']}
            onChange={(from, to) => {
              if (from && to) {
                // eslint-disable-next-line @typescript-eslint/no-implied-eval
                setDateInterval([from, to]);
                setDefaultToggleRange(
                  transformDateToDaysRange(from, to, [1, 7, 30, 90]),
                );
              }
            }}
          />
          {!showFiltersRefactorEvents && (
            <FilterButton
              data-cy="members-filters"
              filters={countFilters}
              onClick={() => setOpenFilters(!isOpenFilters)}
            />
          )}
        </Panel.Filters>
        <Panel.Actions>
          <CheckboxWithLabel
            className={styles.check}
            checked={
              showFiltersRefactorEvents
                ? !!((newFilters?.everEscalated?.value as FilterSelectable)
                    ?.value as boolean)
                : !!filters.testMember
            }
            label={translate('dashboardEvents.filters.testMember')}
            onChange={(value) => {
              setPage(0);
              if (showFiltersRefactorEvents) {
                setNewFilters({
                  ...newFilters,
                  testMember: getFilterItem(
                    translate('dashboardEvents.filters.testMember'),
                    value,
                  ),
                });
              } else {
                setFilters({
                  ...filters,
                  testMember: !filters.testMember,
                });
              }
            }}
          />
        </Panel.Actions>
        {!showFiltersRefactorEvents && (
          <Panel.Collapse open={isOpenFilters}>
            <div className="grid-wrapper fit">
              <Select
                className="grid-span-3"
                data-cy="events-filter-event-type"
                getItemLabel={({ name }: EventType) => name}
                items={eventTypes}
                limitTags={1}
                loading={filtersLoading}
                multiple
                onChange={(typeId: EventType[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    typeId: typeId.map(({ id }) => id),
                  });
                }}
                placeholder={translate('dashboardEvents.filters.type')}
                size="small"
                value={eventTypes.filter(
                  ({ id }) =>
                    filters.typeId && filters.typeId?.indexOf(id) !== -1,
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-event-status"
                disabled={[
                  EVENTS_PAGE_TAB_NON_URGENT_FOLLOW_UP,
                  EVENTS_PAGE_TAB_ESCALATED,
                  EVENTS_PAGE_TAB_VITALS_ABNORMAL,
                ].includes(selectedTab)}
                getItemLabel={({ description }: EventStatus) => description}
                items={getStatusFilterItems()}
                limitTags={1}
                loading={filtersLoading}
                multiple
                onChange={(statusId: EventStatus[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    statusId: statusId.map(({ id }) => id),
                  });
                }}
                placeholder={translate('dashboardEvents.filters.status')}
                size="small"
                value={getStatusFilterValue()}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-assignee"
                disabled={
                  [
                    EVENTS_PAGE_TAB_ASSIGNED_ME,
                    EVENTS_PAGE_TAB_UNASSIGNED,
                  ].indexOf(selectedTab) !== -1
                }
                getItemDisabled={(item?: Employee | EmployeeGroup) => {
                  return !!(
                    item &&
                    item.id !== 0 &&
                    filters.assigneeId &&
                    filters.assigneeId[0] === 0
                  );
                }}
                getItemLabel={(
                  item?: Employee | Selectable | EmployeeGroup,
                ) => {
                  if (item instanceof EmployeeGroup) {
                    return item.name;
                  }
                  if (item instanceof Employee) {
                    return item.fullName;
                  }
                  return item?.label as string;
                }}
                grouped={(item: Employee | EmployeeGroup) => {
                  if (item instanceof EmployeeGroup) {
                    return translate('global.groups');
                  }
                  if (item instanceof Employee && item.id) {
                    return translate('global.employees');
                  }
                  return '';
                }}
                items={[UNASSIGNED, ...employeeGroups, ...employees]}
                isOptionEqualToValue={(
                  a: Employee | EmployeeGroup,
                  b: Employee | EmployeeGroup,
                ) => {
                  if (a instanceof Employee && b instanceof Employee)
                    return a.id === b.id;
                  if (a instanceof EmployeeGroup && b instanceof EmployeeGroup)
                    return a.id === b.id;
                  return false;
                }}
                limitTags={1}
                loading={filtersLoading}
                multiple
                onChange={(assigneeId: (Employee | EmployeeGroup)[]) => {
                  setPage(0);
                  if (assigneeId.find(({ id }) => id === 0)) {
                    setFilters({
                      ...filters,
                      assigneeId: [0],
                      assigneeGroupId: [0],
                    });
                  } else {
                    setFilters({
                      ...filters,
                      assigneeId: assigneeId
                        .filter((item) => item instanceof Employee)
                        .map(({ id }) => id),
                      assigneeGroupId: assigneeId
                        .filter((item) => item instanceof EmployeeGroup)
                        .map(({ id }) => id),
                    });
                  }
                }}
                placeholder={translate('dashboardEvents.filters.assignee')}
                size="small"
                value={getAssigneeFilterValue()}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-referral-source"
                getItemDisabled={(child) =>
                  !!referralSources
                    .filter(({ id }) => filters.referralSourceId?.includes(id))
                    .reduce(
                      (acc, item) => acc || item.hasDescendant(child.id),
                      false,
                    )
                }
                getItemLabel={({ name }: Organization) => name}
                items={referralSources}
                limitTags={1}
                loading={filtersLoading}
                multiple
                onChange={(referralSourceId: Organization[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    referralSourceId: referralSourceId.map(({ id }) => id),
                  });
                }}
                placeholder={translate(
                  'dashboardEvents.filters.referralSource',
                )}
                size="small"
                value={referralSources.filter(
                  ({ id }) =>
                    filters.referralSourceId &&
                    filters.referralSourceId?.indexOf(id) !== -1,
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-rn"
                getItemLabel={({ fullName }: Employee) => fullName}
                grouped
                items={RNs}
                limitTags={1}
                loading={filtersLoading}
                multiple
                onChange={(rnOwnerId: Employee[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    rnOwnerId: rnOwnerId.map(({ id }) => id),
                  });
                }}
                placeholder={translate('dashboardEvents.filters.rnOwner')}
                size="small"
                value={RNs.filter(
                  ({ id }) =>
                    filters.rnOwnerId && filters.rnOwnerId?.indexOf(id) !== -1,
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-np"
                getItemLabel={({ fullName }: Employee) => fullName}
                grouped
                items={NPs}
                limitTags={1}
                loading={filtersLoading}
                multiple
                onChange={(npOwnerId: Employee[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    npOwnerId: npOwnerId.map(({ id }) => id),
                  });
                }}
                placeholder={translate('dashboardEvents.filters.npOwner')}
                size="small"
                value={NPs.filter(
                  ({ id }) =>
                    filters.npOwnerId && filters.npOwnerId?.indexOf(id) !== -1,
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-care-coordinator"
                getItemLabel={({ fullName }: Employee) => fullName}
                grouped
                items={CCs}
                limitTags={1}
                loading={filtersLoading}
                multiple
                onChange={(careCoordinatorId: Employee[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    careCoordinatorId: careCoordinatorId.map(({ id }) => id),
                  });
                }}
                placeholder={translate(
                  'dashboardEvents.filters.careCoordinator',
                )}
                size="small"
                value={CCs.filter(
                  ({ id }) =>
                    filters.careCoordinatorId &&
                    filters.careCoordinatorId?.indexOf(id) !== -1,
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-language"
                grouped={({ preferred }) =>
                  preferred
                    ? translate('global.preferred')
                    : translate('global.other')
                }
                items={Language.toSelectable(languages).filter(
                  ({ disabled }) => !disabled,
                )}
                loading={filtersLoading}
                limitTags={1}
                multiple
                onChange={(language: Language[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    language: language.map((v) => Language.byKey[v.value]),
                  });
                }}
                placeholder={translate('dashboardEvents.filters.language')}
                size="small"
                value={Enum.toSelectable(
                  (filters.language as Language[]) || [],
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-risk-level"
                items={Enum.toSelectable(MemberRisk.asArray)}
                limitTags={1}
                multiple
                onChange={(riskLevel: Selectable[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    riskLevel: riskLevel.map(
                      ({ value }) => MemberRisk.byKey[value],
                    ),
                  });
                }}
                placeholder={translate('dashboardEvents.filters.riskLevel')}
                size="small"
                value={Enum.toSelectable(
                  (filters.riskLevel as MemberRisk[]) || [],
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-pe"
                getItemLabel={({ name }: ProgramExtension) => name}
                items={programExtensions}
                limitTags={1}
                loading={filtersLoading}
                multiple
                onChange={(programExtensionId: ProgramExtension[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    programExtensionId: programExtensionId.map(({ id }) => id),
                  });
                }}
                placeholder={translate(
                  'dashboardEvents.filters.programExtension',
                )}
                size="small"
                value={programExtensions.filter(
                  ({ id }) =>
                    filters.programExtensionId &&
                    filters.programExtensionId?.indexOf(id) !== -1,
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-peStatus"
                items={ProgramExtensionStatus.toSelectable()}
                limitTags={1}
                multiple
                onChange={(programExtensionStatus: Selectable[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    programExtensionStatus: programExtensionStatus.map(
                      ({ value }) => ProgramExtensionStatus.byKey[value],
                    ),
                  });
                }}
                placeholder={translate(
                  'dashboardEvents.filters.programExtensionStatus',
                )}
                size="small"
                value={Enum.toSelectable(
                  (filters.programExtensionStatus as ProgramExtensionStatus[]) ||
                    [],
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-brand"
                items={brands}
                limitTags={1}
                multiple
                onChange={(brand?: Selectable[]) => {
                  setFilters({
                    ...filters,
                    brandId: brand?.map(({ value }) => value as string),
                  });
                  setPage(0);
                }}
                placeholder={translate('dashboardEvents.filters.brand')}
                size="small"
                value={brands.filter(({ value }) =>
                  filters.brandId?.includes(value as string),
                )}
              />
              <Select
                className="grid-span-3"
                data-cy="events-filter-state"
                items={Enum.toSelectable(States.asArray)}
                limitTags={1}
                multiple
                onChange={(state?: Selectable[]) => {
                  setFilters({
                    ...filters,
                    stateId: state?.map(({ value }) => States.byKey[value]),
                  });
                  setPage(0);
                }}
                placeholder={translate('dashboardEvents.filters.state')}
                size="small"
                value={Enum.toSelectable((filters.stateId as States[]) || [])}
              />
              <Select.Choice
                className="grid-span-3"
                data-cy="events-filter-ever-escalated"
                disabled={selectedTab === EVENTS_PAGE_TAB_ESCALATED}
                onChange={(value?: boolean) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    everEscalated: value,
                  });
                }}
                placeholder={translate('dashboardEvents.filters.everEscalated')}
                size="small"
                value={getEverEscalatedFilterValue()}
              />
              {showIncidents && (
                <Select.Choice
                  className="grid-span-3"
                  data-cy="events-filter-pending-incident"
                  onChange={(value?: boolean) => {
                    setPage(0);
                    setFilters({
                      ...filters,
                      pendingIncident: value,
                    });
                  }}
                  placeholder={translate(
                    'dashboardEvents.filters.pendingIncident',
                  )}
                  size="small"
                  value={filters.pendingIncident}
                />
              )}
              {!showIncidents && <div className="grid-span-3" />}
              <Button
                className="grid-span-3"
                data-cy="events-clear-filters"
                color="secondary"
                type="outlined"
                onClick={() => {
                  setPage(0);
                  setFilters({ testMember: filters.testMember });
                }}
                size="s"
              >
                {translate('global.clearFilters')}
              </Button>
            </div>
          </Panel.Collapse>
        )}
        {showFiltersRefactorEvents && (
          <Panel.FilterBar
            onClearFilters={() => {
              setPage(0);
              setNewFilters({});
            }}
            onDeleteFilter={(key: string) => {
              setPage(0);
              setNewFilters({
                ...newFilters,
                [key]: undefined,
              });
            }}
            onOpenFilters={() => setOpenFilters(!isOpenFilters)}
            chips={getFiltersEnabled(newFilters, filtersData, [
              ...FIRST_LEVEL_FILTERS,
              ...disabledFilters,
            ])}
            inputs={
              <>
                <Select
                  data-cy="events-filter-tab-event-type"
                  items={EventType.toSelectable(eventTypes)}
                  limitTags={1}
                  loading={filtersLoading}
                  multiple
                  onChange={(values: Selectable[]) => {
                    setPage(0);
                    setNewFilters({
                      ...newFilters,
                      typeId: getFilterItem(
                        translate('dashboardEvents.filters.type'),
                        values,
                      ),
                    });
                  }}
                  placeholder={translate('dashboardEvents.filters.type')}
                  placeholderV2
                  size="xs"
                  value={newFilters?.typeId?.value || []}
                />
                <Select
                  data-cy="events-filter-tab-event-status"
                  disabled={disabledFilters?.includes('statusId')}
                  items={EventStatus.toSelectable(getStatusFilterItems())}
                  limitTags={1}
                  loading={filtersLoading}
                  multiple
                  onChange={(values: Selectable[]) => {
                    setPage(0);
                    setNewFilters({
                      ...newFilters,
                      statusId: getFilterItem(
                        translate('dashboardEvents.filters.status'),
                        values,
                      ),
                    });
                  }}
                  placeholder={translate('dashboardEvents.filters.status')}
                  placeholderV2
                  size="xs"
                  value={
                    (newFilters?.statusId?.value as FilterSelectable[])?.filter(
                      ({ value }) =>
                        EventStatus.toSelectable(getStatusFilterItems())
                          .map(({ value }) => value)
                          .includes(value as string),
                    ) || []
                  }
                />
                <Select
                  data-cy="events-filter-tab-assignee"
                  disabled={disabledFilters?.includes('assigneeId')}
                  getItemDisabled={(item?: SelectableInfo) => {
                    return !!(
                      item &&
                      item.info?.id !== 0 &&
                      newFilters?.assigneeId?.value &&
                      ((newFilters.assigneeId.value as FilterSelectable[])[0]
                        .value as number) === 0
                    );
                  }}
                  grouped={(item: SelectableInfo) => {
                    if (item.info instanceof EmployeeGroup) {
                      return translate('global.groups');
                    }
                    if (item.info instanceof Employee && item.value) {
                      return translate('global.employees');
                    }
                    return '';
                  }}
                  items={[
                    ...Employee.toSelectable([UNASSIGNED]),
                    ...EmployeeGroup.toSelectable(employeeGroups),
                    ...Employee.toSelectable(employees),
                  ]}
                  isOptionEqualToValue={(
                    { info: a }: SelectableInfo,
                    { info: b }: SelectableInfo,
                  ) => {
                    if (a instanceof Employee && b instanceof Employee)
                      return a.id === b.id;
                    if (
                      a instanceof EmployeeGroup &&
                      b instanceof EmployeeGroup
                    )
                      return a.id === b.id;
                    return false;
                  }}
                  limitTags={1}
                  loading={filtersLoading}
                  multiple
                  onChange={(values: Selectable[]) => {
                    setNewFilters({
                      ...newFilters,
                      assigneeId: getFilterItem(
                        translate('dashboardEvents.filters.assignee'),
                        values,
                      ),
                    });
                  }}
                  placeholder={translate('dashboardEvents.filters.assignee')}
                  placeholderV2
                  size="xs"
                  value={newFilters?.assigneeId?.value}
                />
                <Select
                  data-cy="events-filter-tab-state"
                  items={Enum.toSelectable(States.asArray)}
                  limitTags={1}
                  multiple
                  onChange={(values: Selectable[]) => {
                    setNewFilters({
                      ...newFilters,
                      stateId: getFilterItem(
                        translate('dashboardEvents.filters.state'),
                        values,
                      ),
                    });
                  }}
                  placeholder={translate('dashboardEvents.filters.state')}
                  placeholderV2
                  size="xs"
                  value={newFilters?.stateId?.value || []}
                />
                <Select
                  data-cy="events-filter-tab-pe"
                  items={ProgramExtension.toSelectable(programExtensions)}
                  limitTags={1}
                  multiple
                  onChange={(values: Selectable[]) => {
                    setNewFilters({
                      ...newFilters,
                      programExtensionId: getFilterItem(
                        translate('dashboardEvents.filters.programExtension'),
                        values,
                      ),
                    });
                  }}
                  placeholder={translate(
                    'dashboardEvents.filters.programExtension',
                  )}
                  placeholderV2
                  size="xs"
                  value={newFilters?.programExtensionId?.value || []}
                />
                <Select
                  data-cy="events-filter-tab-referral-sources"
                  getItemDisabled={(child) =>
                    !!(newFilters?.referralSourceId
                      ?.value as SelectableInfo[])?.reduce(
                      (acc, item) =>
                        acc || !!item?.info?.hasDescendant(child.value),
                      false,
                    )
                  }
                  items={Organization.toSelectable(referralSources)}
                  limitTags={1}
                  loading={filtersLoading}
                  multiple
                  onChange={(values: Selectable[]) => {
                    setNewFilters({
                      ...newFilters,
                      referralSourceId: getFilterItem(
                        translate('dashboardEvents.filters.referralSource'),
                        values,
                      ),
                    });
                  }}
                  placeholder={translate(
                    'dashboardEvents.filters.referralSource',
                  )}
                  placeholderV2
                  size="xs"
                  value={newFilters?.referralSourceId?.value || []}
                />
              </>
            }
          />
        )}
        <Panel.Tabs>
          <div className={styles.tabsContainer}>
            <Tabs items={tabs} onChange={setSelectedTab} value={selectedTab} />
            {showRefreshPanel ? (
              <EventRefreshPanel
                onClickRefresh={() => {
                  setShowRefreshPanel(false);
                  getEventsDataWithCount();
                }}
              />
            ) : (
              <Select
                className={styles.sort}
                disableClearable
                flow="horizontal"
                items={SORT_OPTIONS}
                label={translate('dashboardEvents.sortBy')}
                onChange={(sort: SortOption) => {
                  setPage(0);
                  setSort(sort);
                }}
                size="xs"
                value={sort}
              />
            )}
          </div>
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {showFiltersRefactorEvents && (
          <EventFiltersModal
            data={filtersData}
            disabledFilters={disabledFilters}
            filters={newFilters}
            loadingFilters={filtersLoading}
            open={isOpenFilters}
            onChange={(filters) => {
              setPage(0);
              setNewFilters(filters);
            }}
            onClose={() => setOpenFilters(false)}
          />
        )}
        <MemberEventAddModal
          open={openAddEvent}
          event={eventModalDetail as EventDetail}
          issues={eventModalData as EventIssue[]}
          onSubmit={async (params, event, issues) => {
            let result = true;
            try {
              if (event && issues) {
                await unlinkIssues({
                  ...params,
                  issueIds: issues,
                });
              } else {
                const detail = await createEvent(params);
                const prompts = detail.getPendingPrompts();
                if (prompts.length) {
                  setOpenPrompt(true);
                  setEventModalData(prompts[0]);
                  setEventPromptDetail(detail);
                }
              }
              await getEvents();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenAddEvent(false)}
          patient={eventModalMember}
        />
        <MemberEventCloseModal
          open={openCloseEvent}
          event={eventModalDetail as EventDetail}
          onSubmit={async (event, params) => {
            let result = true;
            try {
              let status: EventStatus;
              const { id } = event;
              if (event.isClosed) {
                status = (await updateEvent(id, params as UpdateEventParams))
                  .status;
                const index = events.findIndex(
                  ({ id: listId }) => id === listId,
                );
                if (index !== -1) {
                  events[index].status = status;
                  setEvents([...events]);
                  refreshDetail(id);
                }
              } else {
                await closeEvent(id, params);
                getEvents();
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenCloseEvent(false)}
        />
        <MemberEventInvalidate
          open={openInvalidateEvent}
          event={eventModalDetail as EventDetail}
          onSubmit={async (event, params) => {
            let result = true;
            try {
              const { id } = event;
              await invalidateEvent(id, params);
              getEvents();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenInvalidateEvent(false)}
        />
        <MemberEventAddEncounter
          open={openAddEncounter}
          event={eventModalDetail as EventDetail}
          onSubmit={async ({ id, member }, params) => {
            if (!member) return false;

            let result = true;
            try {
              await createEventEncounter(member?.id, id, params);
              refreshDetail(id);
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenAddEncounter(false)}
          patient={eventModalMember}
        />
        <MemberEventAddNote
          note={eventModalData as EventNote}
          open={!!openAddNote}
          onClose={() => setOpenAddNote(false)}
          onSubmit={async (note) => {
            let result = true;
            try {
              if (eventModalDetail?.id) {
                if (note.id) {
                  await updateEventNote(eventModalDetail?.id, note);
                } else {
                  await createEventNote(eventModalDetail?.id, note);
                }
                refreshDetail(eventModalDetail?.id);
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
        />
        <AddTaskModal
          event={eventModalDetail as EventModel}
          member={eventModalMember}
          open={openAddTask}
          onSubmit={async () => {
            if (eventModalDetail?.id) {
              refreshDetail(eventModalDetail.id);
            }
          }}
          onClose={() => setOpenAddTask(false)}
        />
        <MemberEventAddIssueModal
          event={eventModalDetail}
          open={openAddEventIssue}
          onSubmit={async (id, params) => {
            let result = true;
            try {
              const issue = await createEventIssue(id, params);
              refreshDetail(id);
              if (issue?.pendingPrompts?.length) {
                setOpenPrompt(true);
                setEventModalData(issue.pendingPrompts[0]);
                const event = await fetchEventDetail(id);
                setEventPromptDetail(event);
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenAddEventIssue(false)}
          patient={eventModalMember}
        />
        <MemberEventFollowUpInfo
          event={eventModalDetail}
          open={openModifyFollowUp}
          requireFieldsToClose
          onSubmit={async (id, params) => {
            let result = true;
            try {
              await updateEvent(id, {
                ...params,
                statusId: eventModalDetail ? followUpStatus : undefined,
              });
              await getEventsDataWithCount();
              setFollowUpStatus(undefined);
              setOpenModifyFollowUp(false);
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onCancel={() => {
            if (eventModalDetail) {
              const eventIdx = events.findIndex(
                ({ id: evtId }) => evtId === eventModalDetail.id,
              );
              if (events[eventIdx]) {
                events[eventIdx] = Object.assign(
                  new EventModel({}),
                  eventModalDetail,
                );
              }
              setEvents([...events]);
            }
            setFollowUpStatus(undefined);
            setOpenModifyFollowUp(false);
          }}
        />
        <MemberModifyUrgencyModal
          event={eventModalDetail}
          open={openModifyUrgency}
          onSubmit={async (id, params) => {
            let result = true;
            try {
              await updateEvent(id, params);
              const eventIdx = events.findIndex(
                ({ id: evtId }) => evtId === id,
              );
              if (eventIdx !== -1) {
                events[eventIdx].urgent = !!params.urgent;
                events[eventIdx].urgencyLevel = params.urgencyLevel;
                setEvents([...events]);
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenModifyUrgency(false)}
        />
        <MemberEventHistoryModal
          event={eventModalDetail as EventDetail}
          onClose={() => setOpenHistory(false)}
          open={openHistory}
        />
        <Modal
          open={openPrompt}
          body={
            eventModalData && <p>{(eventModalData as IssuePrompt).message}</p>
          }
          maxWidth="sm"
          onSubmit={async () => {
            try {
              if (eventPromptDetail) {
                const issue = eventPromptDetail.issues?.find(
                  ({ pendingPrompts }) =>
                    !!pendingPrompts?.find(
                      ({ id }) => id === (eventModalData as IssuePrompt).id,
                    ),
                );
                if (issue) {
                  setOpenPrompt(false);
                  await acceptEventIssuePrompt(
                    eventPromptDetail?.id,
                    issue.id,
                    eventModalData.id,
                  );
                  issue.pendingPrompts?.shift();
                  const prompts = eventPromptDetail.getPendingPrompts();
                  if (prompts.length) {
                    setEventModalData(prompts[0]);
                    setOpenPrompt(true);
                  }
                }
              }
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          title={translate('global.warning')}
        />
        {eventModalDetail?.member && (
          <CreateIncidentModal
            defaultType={eventIncidentDetail?.getIncidentTypeAssociated()}
            defaultFields={eventIncidentDetail?.getIncidentData()}
            isEventWorkflow
            member={eventModalDetail.member as Patient}
            open={openAddIncident}
            onClose={() => setOpenAddIncident(false)}
            onCreate={async (params) => {
              await createIncident({
                ...params,
                eventId: eventModalDetail?.id,
              });
              await getEvents();
            }}
          />
        )}
        <EventsTable
          forceShowIncidentAction={!loading && !!filters.pendingIncident}
          events={events}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onClickRow={(evt, idx) => {
            evt.open = !evt.open;
            if (evt.open) {
              evt.detail = (
                <MemberEventsDetail
                  eventId={evt.id}
                  ref={(ref: RefreshHandle) => (eventsRef.current[idx] = ref)}
                  onEventAction={onEventAction}
                  onRetrieveDetail={(detail) => {
                    setTimeout(() => {
                      evt.detailData = detail;
                      setEvents([...events]);
                    }, 250);
                  }}
                  onUnlinkIssue={(event, issues) => {
                    setEventModalDetail(event);
                    setEventModalData(issues);
                    setOpenAddEvent(true);
                  }}
                  patient={evt.member as Patient}
                />
              );
            } else {
              delete evt.detail;
              delete evt.detailData;
            }
            setEvents([...events]);
          }}
          onChangeAssignee={async (
            evtId: number,
            assignee: Employee | EmployeeGroup | Selectable,
          ) => {
            try {
              setLoading(true);
              if (assignee instanceof Employee) {
                await updateEvent(evtId, {
                  assigneeId: assignee,
                  assigneeGroupId: new EmployeeGroup({}),
                });
              } else if (assignee instanceof EmployeeGroup) {
                await updateEvent(evtId, {
                  assigneeId: new Employee({}),
                  assigneeGroupId: assignee,
                });
              } else {
                await updateEvent(evtId, {
                  assigneeId: new Employee({}),
                  assigneeGroupId: new EmployeeGroup({}),
                });
              }
              await getEventsDataWithCount();
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          onChangeStatus={async (evtId: number, status: EventStatus) => {
            const eventIdx = events.findIndex(({ id }) => evtId === id);
            const event = events[eventIdx];
            if (event) {
              if (
                FOLLOW_UP_STATUSES.includes(status.id) &&
                !FOLLOW_UP_STATUSES.includes(event.status.id)
              ) {
                setFollowUpStatus(status);
                setOpenModifyFollowUp(true);
                setEventModalDetail({ ...event } as EventModel);
                event.status = status;
              } else {
                try {
                  event.status = status;
                  await updateEvent(evtId, { statusId: status });
                  await getEventsDataWithCount();
                } catch (e) {
                  showGlobalError(e as string);
                }
              }
              setEvents([...events]);
            }
          }}
          onEventAction={onEventAction}
          pagination={pagination}
        />
      </Panel.Body>
    </Panel>
  );
};
