import moment from 'moment';
import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

import {
  BiometricsTimeOption,
  BiometricsType,
  CollectionMethod,
  RiskLevel,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { BiometricsConfig } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  CollapsableSidebar,
  Colors,
  Fonts,
  IconButton,
  PanelInfo,
  RadioGroup,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  ConfigPostParam,
  addMeasurement,
  createOrUpdateBiometricsConfig,
  createOrUpdateBpConfig,
} from 'dash/src/services/BiometricsServices';
import { updateRPMOrderId } from 'dash/src/services/RPMVendorServices';

const useStyles = makeStyles({
  subtitle: {
    color: Colors.iconGreen,
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 1.5)`,
    fontWeight: 500,
    letterSpacing: 0,
    textTransform: 'none',
  },
  iconOrder: {
    '&&': {
      marginTop: '2rem',
      padding: '0.5rem',
    },
  },
  inputs: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    maxWidth: '35rem',
  },
  smallSubtitle: {
    fontFamily: Fonts.fontFamily,
    fontSize: Fonts.fontSize,
    fontWeight: 500,
    gridColumn: 'span 12',
    letterSpacing: 0,
    textTransform: 'none',
  },
  separator: {
    color: Colors.iconGray,
    fontSize: '36px',
    fontWeight: 700,
    margin: 'auto 1rem 0',
  },
  buttons: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginTop: '4rem',
  },
});

type Props = {
  config?: BiometricsConfig;
  systolicConfig?: BiometricsConfig;
  onClose: (update?: boolean) => Promise<void>;
  patientId: number;
};

const OXYGEN_NORMAL_RISK = 92;
const OXYGEN_HIGH_RISK = 90;

export const BiometricItemSettings = ({
  config,
  systolicConfig,
  onClose,
  patientId,
}: Props) => {
  const styles = useStyles();
  const collectionMethods = [
    {
      label: CollectionMethod.SMART_METER.toString(),
      value: CollectionMethod.SMART_METER.value,
    },
    {
      label: CollectionMethod.CONTACT_HEALTH.toString(),
      value: CollectionMethod.CONTACT_HEALTH.value,
    },
    {
      label: CollectionMethod.LIVECARE.toString(),
      value: CollectionMethod.LIVECARE.value,
    },
    {
      label: CollectionMethod.CLEAR_ARCH.toString(),
      value: CollectionMethod.CLEAR_ARCH.value,
    },
    {
      label: CollectionMethod.MANUAL.toString(),
      value: CollectionMethod.MANUAL.value,
    },
    {
      label: `${CollectionMethod.MAGPIE.toString()} (${translate(
        'biometrics.readOnly',
      )})`,
      value: CollectionMethod.MAGPIE.value,
      disabled: true,
    },
  ];
  const sugarOptions = [
    {
      label: BiometricsTimeOption.BEFORE_MEAL.toString(),
      value: BiometricsTimeOption.BEFORE_MEAL.value,
    },
    {
      label: BiometricsTimeOption.AFTER_MEAL.toString(),
      value: BiometricsTimeOption.AFTER_MEAL.value,
    },
  ];
  const oxigenOptions = [
    {
      label: translate('biometrics.normalRisk'),
      value: RiskLevel.MODERATE.value,
    },
    {
      label: RiskLevel.HIGH.toString(),
      value: RiskLevel.HIGH.value,
    },
  ];

  const [showChangeRPMOrderModal, setShowChangeRPMOrderModal] = useState<
    boolean
  >(false);
  const [rpmOrderId, setRPMOrderId] = useState<string>();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [collectionMethod, setCollectionMethod] = useState<CollectionMethod>();
  const [lowerThresold, setLowerThresold] = useState<number>();
  const [bpLowerThresold, setBPLowerThresold] = useState<
    (number | undefined)[]
  >([]);
  const [upperThresold, setUpperThresold] = useState<number>();
  const [bpUpperThresold, setBPUpperThresold] = useState<
    (number | undefined)[]
  >([]);
  const [baseline, setBaseline] = useState<number>();
  const [bpBaseline, setBPBaseline] = useState<(number | undefined)[]>([]);
  const [timeTaken, setTimeTaken] = useState<string>();
  const [note, setNote] = useState('');

  const setInitData = () => {
    setShowChangeRPMOrderModal(false);
    setRPMOrderId(config?.rpmOrderId);
    setCollectionMethod(config?.collectionMethod);
    setNote(config?.note || '');
    if (
      config &&
      config?.type === BiometricsType.BP_DIASTOLIC &&
      systolicConfig
    ) {
      setBPLowerThresold([
        systolicConfig.lowerLimit || 0,
        config.lowerLimit || 0,
      ]);
      setBPUpperThresold([
        systolicConfig.upperLimit || 0,
        config.upperLimit || 0,
      ]);
      setBPBaseline([
        systolicConfig?.baseline?.value || 0,
        config?.baseline?.value || 0,
      ]);
    } else if (config) {
      if (
        config.type === BiometricsType.BLOOD_SUGAR &&
        config.biometricsTimes[0]
      ) {
        setTimeTaken(config.biometricsTimes[0].option?.value);
      }
      setLowerThresold(config.lowerLimit);
      setUpperThresold(config.upperLimit);
      setBaseline(config?.baseline?.value);
    }
  };

  useEffect(() => {
    setInitData();
  }, [config]);

  const onCancel = () => {
    setInitData();
    onClose();
  };

  const onSave = async () => {
    setButtonLoading(true);
    try {
      if (config?.type === BiometricsType.BP_DIASTOLIC) {
        const dConfig = {
          ...config,
          collectionMethod,
          note,
          lowerLimit: bpLowerThresold[1],
          upperLimit: bpUpperThresold[1],
        } as ConfigPostParam;
        const sConfig = {
          ...systolicConfig,
          collectionMethod,
          note,
          lowerLimit: bpLowerThresold[0],
          upperLimit: bpUpperThresold[0],
        } as ConfigPostParam;
        await createOrUpdateBpConfig(patientId, [dConfig, sConfig]);
      } else {
        const c = {
          ...config,
          note,
          collectionMethod,
          lowerLimit: lowerThresold,
          upperLimit: upperThresold,
        } as ConfigPostParam;
        await createOrUpdateBiometricsConfig(patientId, c);
      }
      if (!config?.baseline) {
        if (
          config?.type === BiometricsType.BP_DIASTOLIC &&
          bpBaseline[0] &&
          bpBaseline[1]
        ) {
          await addMeasurement(patientId, {
            values: new Map([
              [BiometricsType.BP_DIASTOLIC.value, bpBaseline[0]],
              [BiometricsType.BP_SYSTOLIC.value, bpBaseline[1]],
            ]),
            readAt: moment().utc().format(),
          });
        } else if (baseline) {
          await addMeasurement(patientId, {
            values: new Map([[config?.type.value || '', baseline]]),
            readAt: moment().utc().format(),
          });
        }
      }
    } catch (e) {
      showGlobalError(e as string);
    }
    await onClose(true);
    setButtonLoading(false);
  };

  return (
    <>
      <CollapsableSidebar
        open={!!config}
        size={450}
        title={
          <h2>
            {config?.type === BiometricsType.BP_DIASTOLIC
              ? translate('biometrics.bp')
              : config?.type.toString()}{' '}
            {translate('biometrics.settings')}
          </h2>
        }
        onClose={onCancel}
      >
        {config && (
          <CollapsableSidebar.Body>
            <RadioGroup
              columns={2}
              data-cy="biometrics-collection-method"
              items={collectionMethods}
              title={translate('biometrics.collectionMethod')}
              onChange={(value) =>
                setCollectionMethod(CollectionMethod.byKey[value])
              }
              value={collectionMethod?.value}
            />

            {config.collectionMethod === CollectionMethod.SMART_METER &&
              (rpmOrderId || config.rpmDeviceId) && (
                <div className="grid-wrapper">
                  <TextInput
                    className="grid-span-12"
                    label={translate('biometrics.rpmDeviceId')}
                    readOnly
                    value={config.rpmDeviceId || EMPTY}
                  />
                  <TextInput
                    className="grid-span-10"
                    label={translate('biometrics.rpmOrderId')}
                    onChange={setRPMOrderId}
                    readOnly
                    size="xs"
                    value={rpmOrderId || EMPTY}
                  />
                  <div
                    className="grid-span-2 flex"
                    style={{ flexFlow: 'row-reverse' }}
                  >
                    <IconButton
                      className={styles.iconOrder}
                      disabled={
                        collectionMethod !== CollectionMethod.SMART_METER
                      }
                      onClick={() => setShowChangeRPMOrderModal(true)}
                      size="small"
                      tooltip={translate('biometrics.replaceDevice')}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
              )}

            {config.type === BiometricsType.BP_DIASTOLIC ? (
              <div className={styles.inputs}>
                <h3 className={styles.smallSubtitle}>
                  {translate('biometrics.lowerThreshold')}
                </h3>
                <TextInput
                  className="grid-span-5"
                  data-cy="biometrics-bp-lower-0"
                  label={translate('biometrics.systolic')}
                  value={bpLowerThresold[0]?.toString()}
                  onChange={(value) => {
                    if (value) {
                      setBPLowerThresold([Number(value), bpLowerThresold[1]]);
                    } else {
                      setBPLowerThresold([undefined, bpLowerThresold[1]]);
                    }
                  }}
                />
                <span className={styles.separator}>/</span>
                <TextInput
                  className="grid-span-5"
                  data-cy="biometrics-bp-lower-1"
                  label={translate('biometrics.diastolic')}
                  value={bpLowerThresold[1]?.toString()}
                  onChange={(value) => {
                    if (value) {
                      setBPLowerThresold([bpLowerThresold[0], Number(value)]);
                    } else {
                      setBPLowerThresold([bpLowerThresold[0], undefined]);
                    }
                  }}
                />
                <h3 className={styles.smallSubtitle}>
                  {translate('biometrics.upperThreshold')}
                </h3>
                <TextInput
                  className="grid-span-5"
                  data-cy="biometrics-bp-upper-0"
                  label={translate('biometrics.systolic')}
                  value={bpUpperThresold[0]?.toString()}
                  onChange={(value) => {
                    if (value) {
                      setBPUpperThresold([Number(value), bpUpperThresold[1]]);
                    } else {
                      setBPUpperThresold([undefined, bpUpperThresold[1]]);
                    }
                  }}
                />
                <span className={styles.separator}>/</span>
                <TextInput
                  className="grid-span-5"
                  data-cy="biometrics-bp-upper-1"
                  label={translate('biometrics.diastolic')}
                  value={bpUpperThresold[1]?.toString()}
                  onChange={(value) => {
                    if (value) {
                      setBPUpperThresold([bpUpperThresold[0], Number(value)]);
                    } else {
                      setBPUpperThresold([bpUpperThresold[0], undefined]);
                    }
                  }}
                />
                <h3 className={styles.smallSubtitle}>
                  {translate('biometrics.baseline')}
                </h3>
                <TextInput
                  className="grid-span-5"
                  data-cy="biometrics-bp-baseline-0"
                  disabled={!!config.baseline}
                  label={translate('biometrics.systolic')}
                  value={bpBaseline[0]?.toString()}
                  onChange={(value) => {
                    if (value) {
                      setBPBaseline([Number(value), bpBaseline[1]]);
                    } else {
                      setBPBaseline([undefined, bpBaseline[1]]);
                    }
                  }}
                />
                <span className={styles.separator}>/</span>
                <TextInput
                  className="grid-span-5"
                  data-cy="biometrics-bp-baseline-1"
                  disabled={!!systolicConfig?.baseline}
                  label={translate('biometrics.diastolic')}
                  value={bpBaseline[1]?.toString() || ''}
                  onChange={(value) => {
                    if (value) {
                      setBPBaseline([bpBaseline[0], Number(value)]);
                    } else {
                      setBPBaseline([bpBaseline[0], undefined]);
                    }
                  }}
                />
              </div>
            ) : (
              <>
                <div className={styles.inputs} style={{ gap: '1rem' }}>
                  {config.type !== BiometricsType.WEIGHT && (
                    <>
                      <TextInput
                        className="grid-span-6"
                        data-cy={`biometrics-${config.type.value}-lower`}
                        label={translate('biometrics.lowerThreshold')}
                        value={lowerThresold?.toString() || ''}
                        onChange={(value) => {
                          if (value) {
                            setLowerThresold(Number(value));
                          } else {
                            setLowerThresold(undefined);
                          }
                        }}
                      />
                      {config.type === BiometricsType.OXYGEN ? (
                        <div className="grid-span-6" />
                      ) : (
                        <TextInput
                          className="grid-span-6"
                          data-cy={`biometrics-${config.type.value}-upper`}
                          label={translate('biometrics.upperThreshold')}
                          value={upperThresold?.toString() || ''}
                          onChange={(value) => {
                            if (value) {
                              setUpperThresold(Number(value));
                            } else {
                              setUpperThresold(undefined);
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                  <TextInput
                    className="grid-span-6"
                    data-cy={`biometrics-${config.type.value}-baseline`}
                    label={translate('biometrics.baseline')}
                    disabled={!!config.baseline}
                    value={baseline?.toString() || ''}
                    onChange={(value) => {
                      if (value) {
                        setBaseline(Number(value));
                      } else {
                        setBaseline(undefined);
                      }
                    }}
                  />
                </div>
              </>
            )}

            {config.type === BiometricsType.BLOOD_SUGAR && (
              <RadioGroup
                items={sugarOptions}
                title={translate('biometrics.timeTaken')}
                onChange={(value) => setTimeTaken(String(value))}
                value={timeTaken}
              />
            )}

            {config.type === BiometricsType.OXYGEN && (
              <RadioGroup
                items={oxigenOptions}
                title={translate('biometrics.risk')}
                onChange={(value) => {
                  if (value === RiskLevel.HIGH.value) {
                    setLowerThresold(OXYGEN_HIGH_RISK);
                  } else {
                    setLowerThresold(OXYGEN_NORMAL_RISK);
                  }
                }}
                value={
                  lowerThresold && lowerThresold <= OXYGEN_HIGH_RISK
                    ? RiskLevel.HIGH.value
                    : RiskLevel.MODERATE.value
                }
              />
            )}

            <TextArea
              label={translate('biometrics.notes')}
              minRows={3}
              maxRows={5}
              onChange={(newValue) => setNote(newValue || '')}
              value={note}
            />
          </CollapsableSidebar.Body>
        )}
        <CollapsableSidebar.Buttons>
          <Button
            color="tertiary"
            data-cy="biometrics-item-settings-cancel"
            onClick={() => onCancel()}
          >
            {translate('global.cancel')}
          </Button>
          <Button
            color="secondary"
            data-cy="biometrics-item-settings-save"
            loading={buttonLoading}
            onClick={() => onSave()}
          >
            {translate('global.save')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      {config?.collectionMethod === CollectionMethod.SMART_METER && (
        <CollapsableSidebar
          onClose={() => setShowChangeRPMOrderModal(false)}
          open={showChangeRPMOrderModal}
          size={515}
          title={translate('biometrics.editRPMOrderId')}
        >
          <CollapsableSidebar.Body>
            <PanelInfo type="warning">
              {translate('biometrics.warningOrderIdChange')}
            </PanelInfo>
            <TextInput
              onChange={setRPMOrderId}
              value={rpmOrderId}
              label={translate('biometrics.rpmOrderId')}
            />
          </CollapsableSidebar.Body>
          <CollapsableSidebar.Buttons>
            <Button
              color="tertiary"
              onClick={() => setShowChangeRPMOrderModal(false)}
            >
              {translate('global.cancel')}
            </Button>
            <Button
              color="secondary"
              disabled={!rpmOrderId || rpmOrderId === config.rpmOrderId}
              loading={buttonLoading}
              onClick={async () => {
                if (rpmOrderId && config.collectionMethod) {
                  await updateRPMOrderId(config, patientId, {
                    collectionMethod: config.collectionMethod,
                    newOrderNumber: rpmOrderId.trim(),
                    oldOrderNumber: config.rpmOrderId,
                  });
                  config.rpmDeviceId = '';
                  config.rpmOrderId = rpmOrderId.trim();
                  setShowChangeRPMOrderModal(false);
                }
              }}
            >
              {translate('global.save')}
            </Button>
          </CollapsableSidebar.Buttons>
        </CollapsableSidebar>
      )}
    </>
  );
};
