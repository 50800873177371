import { useFlags } from 'launchdarkly-react-client-sdk';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CCMIcon from '@mui/icons-material/AppRegistration';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import CustomSelectionIcon from '@mui/icons-material/GroupAdd';
import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterType,
  Language,
  MemberContactTimeType,
  ProgramExtensionStatus,
  RPMAdherenceLevel,
  RiskLevel,
  States,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BHIMember,
  CCMMember,
  CCMMemberDetail,
  ClinicalMember,
  DBEnum,
  Employee,
  EmployeeGroup,
  InsurancePlan,
  MemberDashboardEvent,
  MemberDashboardTask,
  Organization,
  PaginationType,
  ProgramExtension,
  RPMMember,
  StoredFilter,
} from '@vestahealthcare/common/models';
import { PaginatedResponse } from '@vestahealthcare/common/models/Pagination';
import { LS_DASHBOARD_CCM_FILTERS_V2 } from '@vestahealthcare/common/utils/constants';

import { Toast } from 'styleguide';
import {
  FilterButton,
  FilterItem,
  FilterSelectable,
  IconButton,
  LastUpdated,
  Panel,
  ToggleButton,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { MAX_DASHBOARD_TABLE_ITEMS } from 'dash/src/pages/MemberProfile/Dashboard/contansts';
import AddEncounterModal from 'dash/src/pages/MemberProfile/Encounters/AddEncounterModal';
import {
  onStoredFilterAdd,
  onStoredFilterChange,
  onStoredFilterDeleted,
} from 'dash/src/redux/slices/EmployeeSlice';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import {
  GetCCMMemberParams,
  assignToGroupWorklist,
  downloadCCMDashboard,
  downloadRPMDashboard,
  fecthBHIMembers,
  fecthCCMMembers,
  fecthRPMMembers,
  getMemberInfoData,
} from 'dash/src/services/CCMServices';
import {
  createSelfStoredFilter,
  deleteSelfStoredFilter,
  updateSelfStoredFilter,
} from 'dash/src/services/EmployeeServices';
import {
  fecthMemberDashboardEvents,
  fecthMemberDashboardTasks,
} from 'dash/src/services/MemberDashboardServices';
import Session from 'dash/src/services/SessionServices';
import {
  getServerFilters,
  loadNewFilters,
  loadNewFiltersFromStorage,
  saveNewFilters,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { BHIFiltersBar } from './BHIFiltersBar';
import BHITable from './BHITable';
import {
  CCM_STATUSES,
  RECENT_CYCLES_OPTIONS,
  RISK_LEVEL_OPTION_NONE_VALUE,
  TIME_OPTION_UNKNOWN,
  VESTA_START_DATE_OPTIONS,
} from './CCMData';
import { CCMFilters } from './CCMFilters';
import { CCMFiltersBar } from './CCMFiltersBar';
import { CCMFiltersModal } from './CCMFiltersModal';
import { CCMTable } from './CCMTable';
import { RPMFiltersBar } from './RPMFiltersBar';
import RPMTable from './RPMTable';
import { AssignWorklistModal } from './modals/AssignWorklistModal';
import { InfoCCMModal } from './modals/InfoModal';
import { getFilters, storeFilters } from './utils';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_SORTING = 'lastCCMTouchPointDate asc';

const UNASSIGNED = {
  label: translate('global.unassigned'),
  value: 0,
} as SelectableInfo;
const PROGRAM_EXTENSION_UNASSIGNED = {
  label: translate('global.unassigned'),
  value: -1,
};

const uniq = (array: any[]) => [...new Set(array)];
let CCMDashboardLastFilters = '';

const CCM_TOGGLE_ITEM_CCM = 'ccm-tab-ccm';
const CCM_TOGGLE_ITEM_RPM = 'ccm-tab-rpm';
const CCM_TOGGLE_ITEM_BHI = 'ccm-tab-bhi';
const CCM_TOGGLE_ITEMS = [
  {
    label: translate('ccm.tabs.ccm'),
    value: CCM_TOGGLE_ITEM_CCM,
  },
  {
    label: translate('ccm.tabs.rpm'),
    value: CCM_TOGGLE_ITEM_RPM,
  },
  {
    label: translate('ccm.tabs.bhi'),
    value: CCM_TOGGLE_ITEM_BHI,
  },
];

const useStyles = makeStyles({
  modal: {
    zIndex: '1300!important',
  },
  toggle: {
    '& > div': {
      height: '3rem',

      '& > button': {
        minWidth: '5rem',
      },
    },
  },
});

type KeyGetCCMParams = keyof GetCCMMemberParams;
const CCM_PAGE_FILTER = 'ccm-page';

export const CCMDashboard = () => {
  const { showBhi, showStoredFilters } = useFlags();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();
  const { showFiltersRefactorCcm } = useFlags();
  const { sort: defaultSort, ...currentFilters } = getFilters(query);

  const storedFilters = useSelector((state) =>
    showStoredFilters
      ? state.employeeSlice.storedFilters?.filter(
          StoredFilter.filter(CCM_PAGE_FILTER),
        )
      : undefined,
  );

  const [selectedTab, setSelectedTab] = useState<string>();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [filters, setFilters] = useState<GetCCMMemberParams>(currentFilters);
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetCCMParams]?: FilterItem;
    }
  >();
  const [sort, setSort] = useState<string>(defaultSort || DEFAULT_SORTING);

  const [activeRNs, setActiveRNs] = useState<Selectable[]>([]);
  const [activeNPs, setActiveNPs] = useState<Selectable[]>([]);
  const [activeEngagementOwners, setActiveEngagementOwners] = useState<
    Selectable[]
  >([]);
  const [activeHealthCoaches, setActiveHealthCoaches] = useState<Selectable[]>(
    [],
  );
  const [activeClinicalAssistants, setActiveClinicalAssistants] = useState<
    Selectable[]
  >([]);
  const [brands, setBrands] = useState<Selectable[]>([]);
  const [carePlanGroups, setCarePlanGroups] = useState<Selectable[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<Selectable[]>([]);
  const [insurances, setInsurances] = useState<Selectable[]>([]);
  const [languages, setLanguages] = useState<SelectableInfo[]>([]);
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [reasons, setReasons] = useState<Selectable[]>([]);
  const [referralSources, setReferralSources] = useState<SelectableInfo[]>([]);

  const [ccmMembers, setCCMMembers] = useState<CCMMember[]>([]);
  const [rpmMembers, setRPMMembers] = useState<RPMMember[]>([]);
  const [bhiMembers, setBHIMembers] = useState<BHIMember[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Moment>();

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [openAssignModal, setOpenAssignModal] = useState<boolean>(false);
  const [infoMember, setInfoMember] = useState<ClinicalMember>();
  const [infoDetail, setInfoDetail] = useState<CCMMemberDetail>();
  const [infoDetailEvents, setInfoDetailEvents] = useState<
    PaginatedResponse<MemberDashboardEvent>
  >();
  const [infoDetailTasks, setInfoDetailTasks] = useState<
    PaginatedResponse<MemberDashboardTask>
  >();
  const [openLogCallModal, setOpenLogCallModal] = useState<EncounterType>();
  const [modalData, setModalData] = useState<ClinicalMember>();

  const getInitialData = async () => {
    setLoadingFilters(true);
    const [
      l,
      employees,
      pes,
      cpgs,
      egs,
      o,
      reasons,
      brands,
      ins,
    ] = await Promise.all([
      CacheServices.getLanguages(),
      CacheServices.getEmployees(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getCarePlanGroups(),
      CacheServices.getEmployeeGroupsWorklist(),
      CacheServices.getOrganizations(),
      CacheServices.getCCMTouchpointReasons(),
      CacheServices.getOrganizationBrands(),
      CacheServices.getInsurancePlans(),
    ]);

    setActiveRNs([
      UNASSIGNED,
      ...Employee.toSelectable(
        employees.filter(({ enabled, isRN }) => enabled && isRN),
      ),
    ]);
    setActiveNPs([
      UNASSIGNED,
      ...Employee.toSelectable(
        employees.filter(({ enabled, isNP }) => enabled && isNP),
      ),
    ]);
    setActiveEngagementOwners([
      UNASSIGNED,
      ...Employee.toSelectable(
        employees.filter(
          ({ enabled, isInEngagement }) => enabled && isInEngagement,
        ),
      ),
    ]);
    setActiveHealthCoaches([
      UNASSIGNED,
      ...Employee.toSelectable(
        employees.filter(
          ({ enabled, isInHealthCoaches }) => enabled && isInHealthCoaches,
        ),
      ),
    ]);
    setActiveClinicalAssistants([
      UNASSIGNED,
      ...Employee.toSelectable(
        employees.filter(
          ({ enabled, isInCareCoordinator }) => enabled && isInCareCoordinator,
        ),
      ),
    ]);

    setProgramExtensions([
      PROGRAM_EXTENSION_UNASSIGNED,
      ...ProgramExtension.toSelectable(pes),
    ]);

    setCarePlanGroups([UNASSIGNED, ...DBEnum.toSelectable(cpgs)]);

    setEmployeeGroups([UNASSIGNED, ...EmployeeGroup.toSelectable(egs)]);

    setReferralSources(Organization.toSelectable(o));

    setLanguages([
      UNASSIGNED,
      ...Language.toSelectable(l).filter(({ disabled }) => !disabled),
    ]);

    setReasons(
      reasons.map((item) => ({
        value: item,
        label: item,
      })),
    );
    setBrands(
      brands.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );
    setInsurances(InsurancePlan.toSelectable(ins));

    setLoadingFilters(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getListItems = async () => {
    if (!selectedTab) return;
    if (selectedTab === CCM_TOGGLE_ITEM_RPM) {
      getRPMMembers();
    } else if (selectedTab === CCM_TOGGLE_ITEM_BHI) {
      getBHIMembers();
    } else {
      getCCMMembers();
    }
  };

  const resetCCMMembers = async () => {
    if (page !== 0) {
      setPage(0);
    } else {
      await getListItems();
    }
  };

  const getReferralsWithChildren = (
    referrals: Organization[],
    result = [] as Organization[],
  ) => {
    referrals.forEach((referral) => {
      result.push(referral);
      if (referral.children?.length) {
        getReferralsWithChildren(referral.children, result);
      }
    });
    return result;
  };

  const prepareFilters = () => {
    let currentFilters: GetCCMMemberParams;
    let rpmStartDate;
    let lastReadingDate: number;
    if (showFiltersRefactorCcm) {
      if (!newFilters) return;
      ({ rpmStartDate, lastReadingDate, ...currentFilters } = getServerFilters(
        newFilters,
      ));
      rpmStartDate = parseInt(rpmStartDate?.toString(), 10);
      lastReadingDate = parseInt(lastReadingDate?.toString(), 10);
      currentFilters = {
        ...currentFilters,
        aideScheduleTime: currentFilters?.aideScheduleTime?.filter(
          (item) => !!MemberContactTimeType.byKey[item.valueOf()],
        ),
        contactTime: currentFilters?.contactTime?.filter(
          (item) => !!MemberContactTimeType.byKey[item.valueOf()],
        ),
        excludedReferralSource: getReferralsWithChildren(
          referralSources
            ?.filter(({ value }) =>
              currentFilters?.excludedReferralSource?.includes(value as string),
            )
            ?.map(({ info }) => info as Organization),
        )?.map(({ name }) => name),
        hasAideScheduleInfo: currentFilters?.aideScheduleTime
          ? !currentFilters?.aideScheduleTime?.find(
              (item) => item.valueOf() === TIME_OPTION_UNKNOWN.value,
            )
          : !!currentFilters?.aideScheduleDay?.length || undefined,
        hasContactInfo: currentFilters?.contactTime
          ? !currentFilters?.contactTime?.find(
              (item) => item.valueOf() === TIME_OPTION_UNKNOWN.value,
            )
          : !!currentFilters?.contactDay?.length || undefined,
        hasLanguage: currentFilters?.language
          ? !currentFilters?.language?.find(
              (item) => item.valueOf() === UNASSIGNED.value,
            )
          : undefined,
        hasProgramExtensions: currentFilters?.programExtensionId
          ? !currentFilters?.programExtensionId?.find(
              (item) => item.valueOf() === UNASSIGNED.value,
            )
          : undefined,
        hasRiskLevel: currentFilters?.riskLevel
          ? !currentFilters?.riskLevel?.find(
              (item) => item.valueOf() === RISK_LEVEL_OPTION_NONE_VALUE,
            )
          : undefined,
        insurance: insurances
          ?.filter(({ value }) =>
            currentFilters?.insurance?.includes(value as string),
          )
          ?.map(({ label }) => label as string),
        language: currentFilters?.language?.filter(
          (value) => !!Language.byKey[value.valueOf()],
        ),
        programExtensionId: currentFilters?.programExtensionId?.filter(
          (item) => item.valueOf() !== UNASSIGNED.value,
        ),
        referralSource: getReferralsWithChildren(
          referralSources
            ?.filter(({ value }) =>
              currentFilters?.referralSource?.includes(value as string),
            )
            ?.map(({ info }) => info as Organization),
        )?.map(({ name }) => name),
        riskLevel: currentFilters?.riskLevel?.filter(
          (item) => item.valueOf() !== RISK_LEVEL_OPTION_NONE_VALUE,
        ),
        lastReadingDateFrom: !isNaN(lastReadingDate)
          ? moment().subtract(lastReadingDate, 'days')
          : currentFilters.lastReadingDateFrom,
        lastReadingDateTo: !isNaN(lastReadingDate)
          ? undefined
          : currentFilters.lastReadingDateTo,
        rpmStartDateFrom: !isNaN(rpmStartDate)
          ? moment().subtract(rpmStartDate, 'days')
          : currentFilters.rpmStartDateFrom,
        rpmStartDateTo: !isNaN(rpmStartDate)
          ? undefined
          : currentFilters.rpmStartDateTo,
      };
    } else {
      currentFilters = filters;
    }
    return currentFilters;
  };

  const getCCMMembers = async () => {
    const offset = page * pageSize;
    if (offset !== 0 && offset < ccmMembers?.length) return;

    setLoading(true);

    const currentFilters = prepareFilters();

    CCMDashboardLastFilters = JSON.stringify({
      ...filters,
    });

    try {
      const { items, pagination, lastUpdatedAt } = await fecthCCMMembers({
        ...currentFilters,
        offset,
        limit: pageSize,
        sort,
      });
      if (CCMDashboardLastFilters === JSON.stringify({ ...filters })) {
        if (page > 0) {
          setCCMMembers([...ccmMembers, ...items]);
        } else {
          setCCMMembers(items);
        }
        setLastUpdatedAt(lastUpdatedAt);
        setPagination(pagination);
      }
    } catch (e) {
      showGlobalError(e as string);
    }

    if (selectedTab === CCM_TOGGLE_ITEM_CCM) {
      setLoading(false);
    }
  };

  const getRPMMembers = async () => {
    const offset = page * pageSize;
    if (offset !== 0 && offset < rpmMembers?.length) return;

    setLoading(true);

    const currentFilters = prepareFilters();

    CCMDashboardLastFilters = JSON.stringify({
      ...filters,
    });
    try {
      const { items, pagination, lastUpdatedAt } = await fecthRPMMembers({
        ...currentFilters,
        offset,
        limit: pageSize,
        sort,
      });

      if (CCMDashboardLastFilters === JSON.stringify({ ...filters })) {
        if (page > 0) {
          setRPMMembers([...rpmMembers, ...items]);
        } else {
          setRPMMembers(items);
        }
        setLastUpdatedAt(lastUpdatedAt);
        setPagination(pagination);
      }
    } catch (e) {
      showGlobalError(e as string);
    }
    if (selectedTab === CCM_TOGGLE_ITEM_RPM) {
      setLoading(false);
    }
  };

  const getBHIMembers = async () => {
    const offset = page * pageSize;
    if (offset !== 0 && offset < rpmMembers?.length) return;

    setLoading(true);

    const currentFilters = prepareFilters();

    CCMDashboardLastFilters = JSON.stringify({
      ...filters,
    });
    try {
      const { items, pagination, lastUpdatedAt } = await fecthBHIMembers({
        ...currentFilters,
        offset,
        limit: pageSize,
        sort,
      });
      if (CCMDashboardLastFilters === JSON.stringify({ ...filters })) {
        if (page > 0) {
          setBHIMembers([...bhiMembers, ...items]);
        } else {
          setBHIMembers(items);
        }
      }

      setLastUpdatedAt(lastUpdatedAt);
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const getEncounterChannel = (type?: EncounterType) => {
    if (type === EncounterType.RPM) return EncounterChannel.RPM_ADHERENCE;
    if (type === EncounterType.CCM) return EncounterChannel.CCM_VISIT;
  };

  useEffect(() => {
    if (!showFiltersRefactorCcm) {
      const newQuery = storeFilters(filters, sort);
      history.replace(`?${newQuery}`);
    }
  }, [sort, filters]);

  const storedFiltersConfiguration = useMemo(() => {
    if (
      activeClinicalAssistants?.length &&
      activeEngagementOwners?.length &&
      activeHealthCoaches?.length &&
      activeNPs?.length &&
      activeRNs?.length &&
      brands?.length &&
      carePlanGroups?.length &&
      employeeGroups?.length &&
      languages?.length &&
      programExtensions?.length &&
      reasons?.length &&
      referralSources?.length
    ) {
      return {
        aideScheduleDay: {
          data: Enum.toSelectable(DayOfWeek.asArray),
          label: translate('ccm.common.aideScheduleDays'),
          multiple: true,
        },
        aideScheduleTime: {
          data: Enum.toSelectable(MemberContactTimeType.asArray),
          label: translate('ccm.common.aideScheduleTime'),
          multiple: true,
        },
        bhiMinutesFrom: { label: translate('ccm.common.bhiMinutesFrom') },
        bhiMinutesTo: { label: translate('ccm.common.bhiMinutesTo') },
        brandId: {
          data: brands,
          label: translate('ccm.common.brand'),
          multiple: true,
        },
        careCoordinatorId: {
          data: activeClinicalAssistants,
          label: translate('ccm.common.clinicalAssistant'),
          multiple: true,
        },
        carePlanDateFrom: { label: translate('ccm.common.lastCarePlan') },
        carePlanDue: { label: translate('ccm.common.carePlanDue') },
        carePlanGroupId: {
          data: carePlanGroups,
          label: translate('ccm.common.carePlanGroup'),
          multiple: true,
        },
        careTeamDiscoveryDue: {
          label: translate('ccm.common.careTeamDiscovery'),
        },
        ccmCallStatus: {
          data: CCM_STATUSES,
          label: translate('ccm.common.ccmStatus'),
          multiple: true,
        },
        contactDay: {
          data: Enum.toSelectable(DayOfWeek.asArray),
          label: translate('ccm.common.contactPreferenceDate'),
          multiple: true,
        },
        contactTime: {
          data: Enum.toSelectable(MemberContactTimeType.asArray),
          label: translate('ccm.common.contactPreferenceTime'),
          multiple: true,
        },
        engagementOwnerId: {
          data: activeEngagementOwners,
          label: translate('ccm.common.engagementOwner'),
          multiple: true,
        },
        excludedProgramExtensionId: {
          data: programExtensions,
          label: translate('ccm.common.excludedprogramExtensions'),
          multiple: true,
        },
        excludedReferralSource: {
          data: referralSources,
          label: translate('ccm.common.excludedReferralSources'),
          multiple: true,
        },
        hasProfessionalTime: {
          label: translate('ccm.common.hasProfessionalTime'),
        },
        healthCoachId: {
          data: activeHealthCoaches,
          label: translate('ccm.common.healthCoach'),
          multiple: true,
        },
        isBiannualComplete: {
          label: translate('ccm.common.bianualVVStatus'),
        },
        isComplete: { label: translate('ccm.common.monthlyCCMStatus') },
        initialCPDue: { label: translate('ccm.common.initialCP') },
        isUnreachable: {
          label: translate('ccm.common.unreachable'),
          default: false,
        },
        insurance: {
          data: insurances,
          label: translate('ccm.common.unreachable'),
          multiple: true,
        },
        language: {
          data: languages,
          label: translate('ccm.common.language'),
          multiple: true,
        },
        memberIds: { label: translate('ccm.common.memberId') },
        memberName: { label: translate('ccm.common.memberName') },
        npOwnerId: {
          data: activeNPs,
          label: translate('ccm.common.npOwner'),
          multiple: true,
        },
        numberOfCalls: { label: translate('ccm.common.attempts') },
        programExtensionId: {
          data: programExtensions,
          label: translate('ccm.common.programExtensions'),
          multiple: true,
        },
        programExtensionStatus: {
          data: Enum.toSelectable(ProgramExtensionStatus.asArray),
          label: translate('ccm.common.programExtensionStatus'),
          multiple: true,
        },
        referralSource: {
          data: referralSources,
          label: translate('ccm.common.referralSources'),
          multiple: true,
        },
        riskLevel: {
          data: Enum.toSelectable(RiskLevel.asArray),
          label: translate('ccm.common.riskLevel'),
          multiple: true,
        },
        rpmAdherenceId: {
          data: Enum.toSelectable(RPMAdherenceLevel.asArray),
          label: translate('ccm.common.rpmAdherence'),
          multiple: true,
        },
        rpmMinutesFrom: { label: translate('ccm.common.rpmMinutesFrom') },
        rpmMinutesTo: { label: translate('ccm.common.rpmMinutesTo') },
        recentCyclesMetCount: {
          data: RECENT_CYCLES_OPTIONS,
          label: translate('ccm.common.recentCycles'),
          multiple: true,
        },
        lastReadingDateFrom: {
          label: translate('ccm.common.lastReadingDateFrom'),
        },
        lastReadingDateTo: {
          label: translate('ccm.common.lastReadingDateTo'),
        },
        lastReadingDate: {
          label: translate('ccm.common.lastReading'),
          valueLabel: (option: number) => `Last ${option} days`,
        },
        rpmStartDateFrom: { label: translate('ccm.common.rpmStartDateFrom') },
        rpmStartDateTo: { label: translate('ccm.common.rpmStartDateTo') },
        rpmStartDate: {
          label: translate('ccm.common.rpmStartDate'),
          valueLabel: (option: number) => `Last ${option} days`,
        },
        rnOwnerId: {
          data: activeRNs,
          label: translate('ccm.common.rnOwner'),
          multiple: true,
        },
        startDateFrom: {
          data: VESTA_START_DATE_OPTIONS,
          label: `${translate('ccm.common.vestaStartDate')} ${translate(
            'common.after',
          )}`,
        },
        startDateTo: {
          data: VESTA_START_DATE_OPTIONS,
          label: `${translate('ccm.common.vestaStartDate')} ${translate(
            'common.before',
          )}`,
        },
        state: {
          data: Enum.toSelectable(States.asArray),
          label: translate('ccm.common.state'),
          multiple: true,
        },
        totalMinutesFrom: { label: translate('ccm.common.totalMinutesFrom') },
        totalMinutesTo: { label: translate('ccm.common.totalMinutesTo') },
        touchPointReason: {
          data: reasons,
          label: translate('ccm.common.touchpointReasons'),
          multiple: true,
        },
        worklistGroupId: {
          data: employeeGroups,
          label: translate('ccm.common.employeeGroup'),
          multiple: true,
        },
      } as {
        [x in
          | KeyGetCCMParams
          | 'selectedTab'
          | 'rpmStartDate'
          | 'lastReadingDate']: any;
      };
    }
  }, [
    activeClinicalAssistants,
    activeEngagementOwners,
    activeHealthCoaches,
    activeNPs,
    activeRNs,
    brands,
    carePlanGroups,
    employeeGroups,
    languages,
    programExtensions,
    reasons,
    referralSources,
  ]);

  useEffect(() => {
    if (storedFiltersConfiguration) {
      const clearFilters = query.get('clearFilters');
      const storedFilters = loadNewFiltersFromStorage(
        clearFilters ? '' : LS_DASHBOARD_CCM_FILTERS_V2,
        storedFiltersConfiguration,
        query,
      );
      setNewFilters(storedFilters);
    }
  }, [storedFiltersConfiguration]);

  useEffect(() => {
    const clearFilters = query.get('clearFilters');
    if (clearFilters) {
      setSelectedTab(CCM_TOGGLE_ITEM_CCM);
    } else {
      const { selectedTab } = loadNewFiltersFromStorage(
        LS_DASHBOARD_CCM_FILTERS_V2,
        {
          selectedTab: { label: '' },
        },
      );

      setSelectedTab(
        ((selectedTab?.value as FilterSelectable)?.value as string) ||
          CCM_TOGGLE_ITEM_CCM,
      );
    }
  }, []);

  useEffect(() => {
    if (showFiltersRefactorCcm && newFilters && selectedTab) {
      history.replace('?');
      saveNewFiltersToStorage(LS_DASHBOARD_CCM_FILTERS_V2, {
        ...newFilters,
        selectedTab: {
          label: '',
          value: { label: '', value: selectedTab },
        },
      });
    }
  }, [newFilters, selectedTab]);

  useEffect(() => {
    if (!selectedTab || !programExtensions?.length || !newFilters) return;

    if (selectedTab === CCM_TOGGLE_ITEM_RPM && programExtensions) {
      setNewFilters({
        ...newFilters,
        programExtensionId: {
          label: translate('ccm.common.programExtensions'),
          value: (programExtensions.filter(
            ({ value }) => value === ProgramExtension.RPM,
          ) as unknown) as Selectable,
        },
        programExtensionStatus: {
          label: translate('ccm.common.programExtensionStatus'),
          value: Enum.toSelectable([ProgramExtensionStatus.ACTIVE]),
        },
      });
    } else if (selectedTab === CCM_TOGGLE_ITEM_BHI && programExtensions) {
      setNewFilters({
        ...newFilters,
        programExtensionId: {
          label: translate('ccm.common.programExtensions'),
          value: (programExtensions.filter(
            ({ value }) => value === ProgramExtension.BHI,
          ) as unknown) as Selectable,
        },
        programExtensionStatus: {
          label: translate('ccm.common.programExtensionStatus'),
          value: Enum.toSelectable([ProgramExtensionStatus.ACTIVE]),
        },
      });
    } else {
      setNewFilters({
        ...newFilters,
        programExtensionId: undefined,
        programExtensionStatus: undefined,
      });
    }

    setLoading(true);
    setPage(0);
    setCCMMembers([]);
    setRPMMembers([]);
    setBHIMembers([]);
  }, [selectedTab, programExtensions]);

  useEffect(() => {
    getListItems();
  }, [
    JSON.stringify(filters),
    JSON.stringify(newFilters),
    sort,
    page,
    pageSize,
  ]);

  const selectedMembers = selectAll
    ? (pagination?.total || 0) - selectedIds.length
    : selectedIds.length;

  const { sort: s, ...countFilters } = filters;
  const filtersCount: number = Object.entries(countFilters || {}).reduce(
    (acc, [key, value]) => {
      let count = 0;
      if (key === 'hasLanguage') {
        count = value === false && filters?.language?.length === 0 ? 1 : 0;
      } else if (key === 'hasProgramExtensions') {
        count =
          value === false && filters?.programExtensionId?.length === 0 ? 1 : 0;
      } else if (key === 'hasRiskLevel') {
        count = value === false && filters?.riskLevel?.length === 0 ? 1 : 0;
      } else if (key === 'hasContactInfo') {
        count = value === false && filters?.contactTime?.length === 0 ? 1 : 0;
      } else if (value instanceof Array) {
        count = value.length ? 1 : 0;
      } else if (typeof value === 'boolean') {
        count = 1;
      } else if (typeof value === 'number') {
        count = 1;
      } else {
        count = value ? 1 : 0;
      }
      return acc + count;
    },
    0,
  );

  const getMoreEvents = async () => {
    if (infoMember) {
      try {
        const { items } = await fecthMemberDashboardEvents(
          infoMember.memberId,
          {
            offset: MAX_DASHBOARD_TABLE_ITEMS,
            limit: infoDetailEvents?.pagination?.total || 100,
          },
        );
        setInfoDetailEvents({
          items: [...(infoDetailEvents?.items || []), ...items],
          pagination: infoDetailEvents?.pagination || ({} as PaginationType),
        });
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const getMoreTasks = async () => {
    if (infoMember) {
      try {
        const { items } = await fecthMemberDashboardTasks(infoMember.memberId, {
          offset: MAX_DASHBOARD_TABLE_ITEMS,
          limit: infoDetailTasks?.pagination?.total || 100,
        });
        setInfoDetailTasks({
          items: [...(infoDetailTasks?.items || []), ...items],
          pagination: infoDetailTasks?.pagination || ({} as PaginationType),
        });
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const getFilterBar = (tab?: string) => {
    if (tab === CCM_TOGGLE_ITEM_BHI)
      return (
        <BHIFiltersBar
          data={{
            languages,
            programExtensions,
          }}
          filters={newFilters}
          loading={loadingFilters}
          onChange={(newFilters) => {
            setPage(0);
            setNewFilters(newFilters);
          }}
        />
      );
    if (tab === CCM_TOGGLE_ITEM_RPM)
      return (
        <RPMFiltersBar
          data={{
            languages,
          }}
          filters={newFilters}
          loading={loadingFilters}
          onChange={(newFilters) => {
            setPage(0);
            setNewFilters(newFilters);
          }}
        />
      );

    return (
      <CCMFiltersBar
        data={{
          languages,
          programExtensions,
          worklistGroups: employeeGroups,
        }}
        loading={loadingFilters}
        filters={newFilters}
        onChange={(newFilters) => {
          setPage(0);
          setNewFilters(newFilters);
        }}
      />
    );
  };

  return (
    <Panel>
      <Panel.Heading
        title={translate('ccm.title')}
        filtersV2={showFiltersRefactorCcm}
      >
        {!showFiltersRefactorCcm && (
          <Panel.Filters className="flex gap">
            <FilterButton
              filtersCount={filtersCount}
              onClick={() => setOpenFilters(!openFilters)}
            />
          </Panel.Filters>
        )}
        {showFiltersRefactorCcm && (
          <Panel.Filters className="flex gap">
            <ToggleButton
              className={styles.toggle}
              items={CCM_TOGGLE_ITEMS.filter(
                (item) => showBhi || item.value !== CCM_TOGGLE_ITEM_BHI,
              )}
              onChange={setSelectedTab}
              value={selectedTab}
            />
          </Panel.Filters>
        )}
        <Panel.Actions>
          {lastUpdatedAt && (
            <LastUpdated
              lastUpdatedAt={lastUpdatedAt}
              tooltip={translate('toc.actions.lastUpdatedTooltip')}
            />
          )}
          {!Session.actingUser.isExternal && (
            <IconButton
              data-cy="member-export"
              tooltip={translate('ccm.actions.download')}
              loading={loadingDownload}
              onClick={async () => {
                setLoadingDownload(true);
                try {
                  if (selectedTab === CCM_TOGGLE_ITEM_RPM) {
                    await downloadRPMDashboard(prepareFilters() || {});
                  } else {
                    await downloadCCMDashboard(prepareFilters() || {});
                  }
                } catch (e) {
                  showGlobalError(e as string);
                }
                setLoadingDownload(false);
              }}
            >
              <DownloadIcon />
            </IconButton>
          )}
          <IconButton
            data-cy="ccm-button"
            disabled={!selectedIds.length && !selectAll}
            onClick={() => setOpenAssignModal(true)}
            tooltip={translate('ccm.actions.ccm')}
          >
            <CCMIcon />
          </IconButton>
        </Panel.Actions>
        {showFiltersRefactorCcm && (
          <Panel.FilterBar
            onClearFilters={() => {
              setPage(0);
              setNewFilters({
                isUnreachable: newFilters?.isUnreachable,
              });
            }}
            onDeleteFilter={(key: string) => {
              setPage(0);
              setNewFilters({
                ...newFilters,
                [key]: undefined,
              });
            }}
            onOpenFilters={() => setOpenFilters(!openFilters)}
            onSelectFilter={(filter) =>
              storedFiltersConfiguration &&
              setNewFilters(
                loadNewFilters(filter.filters, storedFiltersConfiguration),
              )
            }
            chips={{
              ...newFilters,
              isUnreachable: newFilters?.isUnreachable
                ? ({
                    ...newFilters.isUnreachable,
                    readonly: true,
                  } as FilterItem & { readonly: boolean })
                : undefined,
            }}
            inputs={getFilterBar(selectedTab)}
            storedFilters={storedFilters}
          />
        )}
        {!showFiltersRefactorCcm && (
          <Panel.Collapse open={openFilters}>
            <CCMFilters
              className="grid-wrapper fit"
              classNameItem="grid-span-3"
              filters={filters}
              onChange={(f) => {
                setPage(0);
                setFilters(f);
              }}
              size="small"
            />
          </Panel.Collapse>
        )}
        <Panel.Tabs className="flex spaced buttons">
          <span>
            {translate('ccm.selectedItems', {
              count: selectedMembers,
            })}
          </span>
          <div className="flex gap">
            <IconButton
              data-cy="ccm-selection-button"
              disabled={selectAll}
              onClick={() => {
                setSelectedIds([]);
                setSelectAll(!selectAll);
              }}
              tooltip={translate('ccm.actions.selection')}
            >
              <CustomSelectionIcon />
            </IconButton>
            <IconButton
              data-cy="ccm-clear-button"
              disabled={!selectedIds?.length && !selectAll}
              onClick={() => {
                setSelectedIds([]);
                setSelectAll(false);
              }}
              tooltip={translate('ccm.actions.clear')}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading} fixedTableHeader>
        {showFiltersRefactorCcm && (
          <CCMFiltersModal
            data={{
              brands,
              carePlanGroups,
              ccs: activeClinicalAssistants,
              engagementOwners: activeEngagementOwners,
              healthCoaches: activeHealthCoaches,
              insurances,
              languages,
              nps: activeNPs,
              programExtensions,
              reasons,
              referralSources,
              rns: activeRNs,
              worklistGroups: employeeGroups,
            }}
            filters={newFilters}
            loadingFilters={loadingFilters}
            onChange={(newFilters) => {
              setPage(0);
              setNewFilters(newFilters);
            }}
            onClose={() => setOpenFilters(false)}
            onDeleteFilter={async (filter) => {
              await deleteSelfStoredFilter(filter.id);
              dispatch(onStoredFilterDeleted(filter));
            }}
            onEditFilter={async (filter) => {
              await updateSelfStoredFilter(filter.id, filter);
              dispatch(onStoredFilterChange(filter));
            }}
            onSaveFilter={async (filter) => {
              if (filter.id) {
                await updateSelfStoredFilter(filter.id, {
                  filters: saveNewFilters(newFilters || {}),
                });
                dispatch(onStoredFilterChange(filter));
              } else if (newFilters && selectedTab) {
                const updated = await createSelfStoredFilter({
                  description: filter.description,
                  filters: saveNewFilters(newFilters || {}),
                  page: CCM_PAGE_FILTER,
                  preferred: false,
                });
                dispatch(onStoredFilterAdd(updated));
              }
            }}
            open={openFilters}
            storedFilters={storedFilters}
          />
        )}
        {selectedTab === CCM_TOGGLE_ITEM_BHI && (
          <BHITable
            allChecked={
              !!(
                rpmMembers?.length &&
                rpmMembers?.every((member) =>
                  selectedIds.includes(member.memberId),
                )
              )
            }
            blacklist={selectAll}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSort={sort}
            items={bhiMembers}
            onChangePage={setPage}
            onChangePageSize={(ps) => {
              setPage(0);
              setPageSize(ps);
            }}
            onClickRow={async (member) => {
              if (!member) return;

              setLoading(true);

              const detail = await getMemberInfoData(member?.memberId);
              setInfoMember(member);
              setInfoDetail(detail);
              setInfoDetailEvents(detail.recentEvents);
              setInfoDetailTasks(detail.recentTasks);

              setLoading(false);
            }}
            onDefaultSort={() => {
              setPage(0);
              setSort(DEFAULT_SORTING);
            }}
            onLogCall={(member) => {
              setModalData(member);
              setOpenLogCallModal(EncounterType.BHI);
            }}
            onSelectAll={(checked) => {
              const ids = rpmMembers?.map((member) => member.memberId) || [];
              if (!ids.length) return;

              setSelectedIds(
                checked
                  ? selectedIds.filter((id) => !ids.includes(id))
                  : uniq([...selectedIds, ...ids]),
              );
            }}
            onSelectMember={(member) => {
              const idx = selectedIds?.findIndex(
                (id) => id === member.memberId,
              );
              if (idx === -1) {
                selectedIds.push(member.memberId);
              } else {
                selectedIds.splice(idx, 1);
              }
              setSelectedIds([...selectedIds]);
            }}
            onSort={(field, direction) => {
              setPage(0);
              setSort(`${field} ${direction}`);
            }}
            pagination={pagination}
            selectedIds={selectedIds}
          />
        )}
        {selectedTab === CCM_TOGGLE_ITEM_RPM && (
          <RPMTable
            allChecked={
              !!(
                rpmMembers?.length &&
                rpmMembers?.every((member) =>
                  selectedIds.includes(member.memberId),
                )
              )
            }
            blacklist={selectAll}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSort={sort}
            items={rpmMembers}
            onChangePage={setPage}
            onChangePageSize={(ps) => {
              setPage(0);
              setPageSize(ps);
            }}
            onClickRow={async (member) => {
              if (!member) return;

              setLoading(true);

              const detail = await getMemberInfoData(member?.memberId);
              setInfoMember(member);
              setInfoDetail(detail);
              setInfoDetailEvents(detail.recentEvents);
              setInfoDetailTasks(detail.recentTasks);

              setLoading(false);
            }}
            onDefaultSort={() => {
              setPage(0);
              setSort(DEFAULT_SORTING);
            }}
            onLogCall={(member) => {
              setModalData(member);
              setOpenLogCallModal(EncounterType.RPM);
            }}
            onSelectAll={(checked) => {
              const ids = rpmMembers?.map((member) => member.memberId) || [];
              if (!ids.length) return;

              setSelectedIds(
                checked
                  ? selectedIds.filter((id) => !ids.includes(id))
                  : uniq([...selectedIds, ...ids]),
              );
            }}
            onSelectMember={(member) => {
              const idx = selectedIds?.findIndex(
                (id) => id === member.memberId,
              );
              if (idx === -1) {
                selectedIds.push(member.memberId);
              } else {
                selectedIds.splice(idx, 1);
              }
              setSelectedIds([...selectedIds]);
            }}
            onSort={(field, direction) => {
              setPage(0);
              setSort(`${field} ${direction}`);
            }}
            pagination={pagination}
            selectedIds={selectedIds}
          />
        )}
        {selectedTab === CCM_TOGGLE_ITEM_CCM && (
          <CCMTable
            allChecked={
              !!(
                ccmMembers?.length &&
                ccmMembers?.every((member) =>
                  selectedIds.includes(member.memberId),
                )
              )
            }
            blacklist={selectAll}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSort={sort}
            items={ccmMembers}
            onChangePage={setPage}
            onChangePageSize={(ps) => {
              setPage(0);
              setPageSize(ps);
            }}
            onClickRow={async (member) => {
              if (!member) return;

              setLoading(true);

              const detail = await getMemberInfoData(member?.memberId);
              setInfoMember(member);
              setInfoDetail(detail);
              setInfoDetailEvents(detail.recentEvents);
              setInfoDetailTasks(detail.recentTasks);

              setLoading(false);
            }}
            onDefaultSort={() => {
              setPage(0);
              setSort(DEFAULT_SORTING);
            }}
            onLogCall={(member) => {
              setModalData(member);
              setOpenLogCallModal(EncounterType.CCM);
            }}
            onSelectAll={(checked) => {
              const ids = ccmMembers?.map((member) => member.memberId) || [];
              if (!ids.length) return;

              setSelectedIds(
                checked
                  ? selectedIds.filter((id) => !ids.includes(id))
                  : uniq([...selectedIds, ...ids]),
              );
            }}
            onSelectMember={(member) => {
              const idx = selectedIds?.findIndex(
                (id) => id === member.memberId,
              );
              if (idx === -1) {
                selectedIds.push(member.memberId);
              } else {
                selectedIds.splice(idx, 1);
              }
              setSelectedIds([...selectedIds]);
            }}
            onSort={(field, direction) => {
              setPage(0);
              setSort(`${field} ${direction}`);
            }}
            pagination={pagination}
            selectedIds={selectedIds}
          />
        )}
        <AssignWorklistModal
          open={openAssignModal}
          onClose={() => setOpenAssignModal(false)}
          onSubmit={async (params, group) => {
            let result = true;
            try {
              if (selectAll) {
                await assignToGroupWorklist({
                  ...params,
                  filter: {
                    excludedMemberIds: selectedIds,
                    ...(showFiltersRefactorCcm ? prepareFilters() : filters),
                  },
                });
              } else {
                await assignToGroupWorklist({
                  includedMemberIds: selectedIds,
                  ...params,
                });
              }

              new Toast({
                autoClose: 5000,
                position: 'bottom-right',
                title: translate('ccm.info.membersAssignedTitle'),
                body: group.value
                  ? translate('ccm.info.membersAssignedBody', {
                      count: selectedMembers,
                      group: group.label,
                    })
                  : translate('ccm.info.membersUnassignedBody', {
                      count: selectedMembers,
                    }),
                type: 'info',
              });

              setSelectedIds([]);
              setSelectAll(false);
              await resetCCMMembers();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          totalMembers={selectedMembers}
        />
        <InfoCCMModal
          detail={infoDetail}
          events={infoDetailEvents}
          getMoreEvents={getMoreEvents}
          getMoreTasks={getMoreTasks}
          member={infoMember}
          open={!!infoMember}
          onClose={() => setInfoMember(undefined)}
          onLogCall={(member, encounterType) => {
            setModalData(member);
            setOpenLogCallModal(encounterType);
          }}
          tasks={infoDetailTasks}
        />

        <AddEncounterModal
          autofillTime
          avoidRequiredMinutes
          className={styles.modal}
          channel={getEncounterChannel(openLogCallModal)}
          direction={EncounterDirection.OUTBOUND}
          method={EncounterMethod.CALL}
          type={openLogCallModal}
          onClose={() => setOpenLogCallModal(undefined)}
          onSubmit={async () => {
            await resetCCMMembers();
            setOpenLogCallModal(undefined);
          }}
          open={!!openLogCallModal}
          patient={modalData?.memberId}
        />
      </Panel.Body>
    </Panel>
  );
};

export default CCMDashboard;
