import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { translate } from '@vestahealthcare/common/i18n';

import { ThemeProvider } from 'styleguide-v2';
import { defaultToastOptions } from 'styleguide/src/components/Toast';

import config from 'dash/environments';
import ConflictingDataWarning from 'dash/src/components/ConflictingDataWarning';
import GlobalMessage from 'dash/src/components/GlobalMessage';
import Header from 'dash/src/components/Header/Header';
import UserChangedWarning from 'dash/src/components/UserChangedWarning';
import { broadcastError } from 'dash/src/redux/actions/GlobalActions';
import store from 'dash/src/redux/store';
import Api from 'dash/src/services/Api';
import LoginServices from 'dash/src/services/LoginServices';
import Session from 'dash/src/services/SessionServices';

import Page from './Page';
import { ChatProvider } from './contexts/VestaChatContext';
import {
  fetchStoredFilters,
  onEmployeeUpdate,
} from './redux/slices/EmployeeSlice';
import UpdateServices from './services/UpdateServices';
import { datadogRum } from '@datadog/browser-rum';

// Why 55 minutes? The tokens expire in an hour, so we use 55, AWS does the same in their clients
// See: https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html#amazon-cognito-user-pools-using-the-refresh-token
const REFRESH_INTERVAL = 55 * 60 * 1000;

const AUTH_CHECK_INTERVAL = 60 * 1000;

const NEW_VERSION_INTERVAL = 60 * 1000;

datadogRum.init({
  applicationId: '8b557fb6-06e1-4de1-9a51-f7ad19fc028f',
  clientToken: 'pub789fefc08237a5f4015796dc32161911',
  site: 'datadoghq.com',
  service: 'Dash',
  env: config.env,
  sampleRate: 100,
  trackInteractions: true,
});

class App extends Component {
  refreshInterval?: number;

  authInterval?: number;

  newVersionInterval?: number;

  state = {
    isAuthorized: LoginServices.isAuthorized,
    isAuthorizing: !Session.actingUser || LoginServices.isAuthorizing,
  };

  async authCheck() {
    LoginServices.authCheck()
      .then(() =>
        this.setState({
          isAuthorized: LoginServices.isAuthorized,
          isAuthorizing: !Session.actingUser || LoginServices.isAuthorizing,
        }),
      )
      .catch((error) => {
        store.dispatch(broadcastError(error));
        this.refreshTokens();
      });
  }

  async componentDidMount() {
    const actingUser = await this.authCheck();

    store.dispatch(onEmployeeUpdate(actingUser));
    store.dispatch(fetchStoredFilters());

    // Periodically refresh auth tokens
    this.refreshInterval = window.setInterval(
      () => this.refreshTokens(),
      REFRESH_INTERVAL,
    );
    // Initial refresh to reset interval counters
    this.refreshTokens();
    // TODO: notify user they have been logged out
    this.authInterval = window.setInterval(
      () =>
        Session.checkAuth().catch((error) => {
          store.dispatch(broadcastError(error));
          this.refreshTokens();
        }),
      AUTH_CHECK_INTERVAL,
    );

    UpdateServices.initUpdateVersion();
    this.newVersionInterval = window.setInterval(
      () => UpdateServices.shouldUpdateVersion(),
      NEW_VERSION_INTERVAL,
    );
  }

  refreshTokens() {
    // In integration tests we create the refresh token by a different way and, refresh_token
    // does not validate. We just skip it for now. todo: figure out a way to make it work
    if (LoginServices.hasToken && !LoginServices.isIntegrationTests) {
      Api.postv2JSON('auth/refresh');
    }
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      window.clearInterval(this.refreshInterval);
    }
    if (this.authInterval) {
      window.clearInterval(this.authInterval);
    }
    if (this.newVersionInterval) {
      window.clearInterval(this.newVersionInterval);
    }
  }

  render() {
    if (!this.state.isAuthorized) {
      return (
        <>
          <p>{translate('global.notLoggedIn1')}</p>
          <p>
            <a href={LoginServices.hostedLoginPage}>
              {translate('global.notLoggedIn2')}
            </a>
          </p>
        </>
      );
    }

    if (this.state.isAuthorizing) {
      return <p>{translate('global.loggingIn')}</p>;
    }

    return (
      <ThemeProvider>
        <Provider store={store}>
          <ChatProvider>
            <Header />
            <Page />
          </ChatProvider>
          <div id="modal-container">
            <ToastContainer {...defaultToastOptions} />
            <div id="navigation-prompt" />
          </div>
          <GlobalMessage />
          <UserChangedWarning />
          <ConflictingDataWarning />
        </Provider>
      </ThemeProvider>
    );
  }
}

export default withLDConsumer()(App);
