import React, { useEffect, useState } from 'react';

import { EmployeeRole, Enum, States } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, EmployeeGroup } from '@vestahealthcare/common/models';
import DashPermission from '@vestahealthcare/common/models/DashPermission';
import { getDiff } from '@vestahealthcare/common/utils/api';

import {
  Button,
  CollapsableSidebar,
  Select,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  EmployeeCreateParam,
  EmployeeUpdateParam,
} from 'dash/src/services/EmployeeServices';
import Session from 'dash/src/services/SessionServices';

type Props = {
  employee?: Employee;
  open: boolean;
  onClose: () => void;
  onCreate: (params: EmployeeCreateParam) => Promise<void>;
  onEdit: (id: number, params: Partial<EmployeeUpdateParam>) => Promise<void>;
};

const VESTA_EMAIL = '@vestahealthcare.com';

export const EmployeeEditModal = ({
  employee,
  onClose,
  onCreate,
  onEdit,
  open,
}: Props) => {
  const canEditAllFields = Session.actingUser.role === EmployeeRole.ADMIN;

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [permissions, setPermissions] = useState<DashPermission[]>([]);
  const [nlcStates, setNLCStates] = useState<States[]>([]);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [ecwId, setEcwId] = useState<string>();
  const [sfId, setSfId] = useState<number>();
  const [group, setGroup] = useState<EmployeeGroup[]>([]);
  const [role, setRole] = useState<EmployeeRole>();
  const [permission, setPermission] = useState<DashPermission>();
  const [states, setStates] = useState<States[]>([]);
  const [phi, setPhi] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [nlc, setNLC] = useState<boolean>(false);

  const getInitialData = async () => {
    const groups = await CacheServices.getEmployeeGroupsAssignee();
    setGroups(groups);
    const permissions = await CacheServices.getDashPermissions();
    setPermissions(permissions);
    const nlcStates = await CacheServices.getEmployeeNLCStates();
    setNLCStates(nlcStates);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (open) {
      setSubmitted(false);
      setLoadingButton(false);
    }
  }, [open]);

  useEffect(() => {
    if (employee?.id) {
      setFirstName(employee.firstName);
      setLastName(employee.lastName);
      setEmail(employee.email);
      setGroup(employee.activeGroups || []);
      setRole(employee.role);
      setPermission(
        employee.dashPermissions ? employee.dashPermissions[0] : undefined,
      );
      setStates(employee.licences || []);
      setPhi(employee.phiAccess);
      setEnabled(employee.enabled);
      setEcwId(employee.ecwId);
      setSfId(employee.sfId);
      setNLC(employee.nlc);
    } else {
      setFirstName('');
      setLastName('');
      setEmail('');
      setRole(undefined);
      setEcwId('');
      setSfId(undefined);
      setNLC(false);
    }
  }, [employee]);

  const validateCreate = () => email?.endsWith(VESTA_EMAIL);

  const doSubmit = async () => {
    setSubmitted(true);

    if (!validateCreate()) return;

    if (employee?.id) {
      if (role && permission) {
        setLoadingButton(true);
        try {
          await onEdit(
            employee.id,
            canEditAllFields
              ? getDiff<Partial<EmployeeUpdateParam>>(
                  {
                    dashPermissionId:
                      (employee.dashPermissions &&
                        employee.dashPermissions[0]) ||
                      undefined,
                    employeeGroupIds: employee.employeeGroups,
                    licenceStates: employee.licences,
                    sfId: employee.sfId || 0,
                    ...employee,
                  },
                  {
                    dashPermissionId: permission,
                    enabled,
                    email,
                    employeeGroupIds: group,
                    firstName,
                    lastName,
                    licenceStates: states.filter(
                      (state) => !nlc || !nlcStates.includes(state),
                    ),
                    phiAccess: phi,
                    role,
                    ecwId: ecwId || undefined,
                    sfId: sfId || 0,
                    nlc,
                  },
                )
              : getDiff<Partial<EmployeeUpdateParam>>(
                  { licenceStates: employee.licences, nlc: employee.nlc },
                  {
                    licenceStates: states.filter(
                      (state) => !nlc || !nlcStates.includes(state),
                    ),
                    nlc,
                  },
                ),
          );
          onClose();
        } catch (e) {
          showGlobalError(e as string);
        }
        setLoadingButton(false);
      }
    } else if (firstName && lastName && email && role && permission) {
      setLoadingButton(true);
      try {
        await onCreate({
          dashPermissionId: permission,
          email,
          employeeGroupIds: group,
          firstName,
          lastName,
          licenceStates: states.filter(
            (state) => !nlc || !nlcStates.includes(state),
          ),
          phiAccess: phi,
          role,
          ecwId: ecwId || undefined,
          sfId: sfId || 0,
          nlc,
        });
        onClose();
      } catch (e) {
        showGlobalError(e as string);
      }
      setLoadingButton(false);
    }
  };

  return (
    <CollapsableSidebar
      title={
        <h2>
          {employee?.id
            ? `${translate('global.edit')} ${employee.fullName}`
            : translate('employees.modal.titleAdd')}
        </h2>
      }
      onClose={onClose}
      open={open}
      size={500}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <TextInput
            className="grid-span-6"
            data-cy="employees-modal-first-name"
            disabled={!canEditAllFields}
            error={submitted && !firstName}
            label={translate('employees.modal.firstName')}
            onChange={setFirstName}
            value={firstName}
            required
          />
          <TextInput
            className="grid-span-6"
            data-cy="employees-modal-last-name"
            disabled={!canEditAllFields}
            error={submitted && !lastName}
            label={translate('employees.modal.lastName')}
            onChange={setLastName}
            value={lastName}
            required
          />
          <TextInput
            className="grid-span-12"
            data-cy="employees-modal-email"
            disabled={!canEditAllFields}
            error={
              submitted &&
              (email
                ? !email?.endsWith(VESTA_EMAIL) &&
                  translate('employees.modal.emailError')
                : true)
            }
            label={translate('employees.modal.email')}
            onChange={setEmail}
            value={email}
            required
          />
          <Select
            className="grid-span-12"
            data-cy="employees-modal-role"
            disabled={!canEditAllFields}
            error={submitted && !role}
            items={Enum.toSelectable(
              EmployeeRole.asArray,
            ).sort(({ label: a }, { label: b }) =>
              (a as string).localeCompare(b as string),
            )}
            label={translate('employees.modal.role')}
            onChange={(val: Selectable) =>
              setRole(val ? EmployeeRole.byKey[val.value] : undefined)
            }
            value={role ? Enum.toSelectable([role])[0] : undefined}
            required
          />

          <Select
            className="grid-span-12"
            data-cy="employees-modal-permission"
            disabled={!canEditAllFields}
            error={submitted && !permission}
            getItemLabel={({ name }: DashPermission) =>
              `${name.charAt(0).toLocaleUpperCase()}${name
                .substring(1)
                .toLocaleLowerCase()}`.replace('_', ' ')
            }
            items={permissions}
            label={translate('employees.modal.permission')}
            onChange={setPermission}
            value={permission}
            required
          />

          <Select
            className="grid-span-12"
            data-cy="employees-modal-group"
            disabled={!canEditAllFields}
            getItemLabel={({ name }: EmployeeGroup) => name}
            items={groups}
            multiple
            label={translate('employees.modal.groups')}
            onChange={setGroup}
            value={group}
          />

          <Select
            className="grid-span-12"
            data-cy="employees-modal-state"
            getItemDisabled={(state: Selectable) =>
              nlc &&
              nlcStates
                .map(({ value }) => value)
                .includes(state.value as string)
            }
            items={States.toSelectable(States.asArray)}
            multiple
            label={translate('employees.modal.state')}
            onChange={(val: Selectable[]) =>
              setStates(val?.map(({ value }) => States.byKey[value]) || [])
            }
            value={Enum.toSelectable(
              states.filter((state) => !nlc || !nlcStates.includes(state)),
            )}
          />
          <SwitchGroup
            className="grid-span-12"
            items={[{ label: translate('employees.modal.nlc'), checked: nlc }]}
            onChange={(items) => {
              setNLC(!!items[0].checked);
            }}
          />
          <TextInput
            className="grid-span-6"
            data-cy="employees-modal-ecwId"
            disabled={!canEditAllFields}
            label={translate('employees.modal.ecwId')}
            onChange={setEcwId}
            value={ecwId}
          />
          <TextInput
            className="grid-span-6"
            disabled={!canEditAllFields}
            type="number"
            data-cy="employees-modal-sfId"
            label={translate('employees.modal.sfId')}
            onChange={(newValue?: string) => {
              newValue && setSfId(parseInt(newValue, 10));
            }}
            value={sfId}
          />
          <SwitchGroup
            className="grid-span-12"
            disabled={!canEditAllFields}
            items={
              employee?.id
                ? [
                    { label: translate('employees.modal.phi'), checked: phi },
                    {
                      label: translate('employees.modal.enabled'),
                      checked: enabled,
                    },
                  ]
                : [{ label: translate('employees.modal.phi'), checked: phi }]
            }
            onChange={(items) => {
              setPhi(!!items[0].checked);
              if (employee?.id) {
                setEnabled(!!items[1].checked);
              }
            }}
          />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-group-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-group-submit"
          loading={loadingButton}
          onClick={doSubmit}
        >
          {employee?.id ? translate('global.save') : translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EmployeeEditModal;
