import classNames from 'classnames';
import React, { ChangeEvent, Fragment, ReactNode, useEffect } from 'react';

import SortIcon from '@mui/icons-material/Sort';
import MTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';

import { Brand } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { PaginationType } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Collapse } from 'styleguide-v2/src/components/Collapse';
import { EmptyState } from 'styleguide-v2/src/components/EmptyState';
import { IconButton } from 'styleguide-v2/src/components/IconButton';
import { Spinner } from 'styleguide-v2/src/components/Spinner';
import { Pagination } from 'styleguide-v2/src/components/Table/pagination';
import {
  BackgroundColors,
  BrandColors,
  Colors,
} from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

const useStyles = makeStyles({
  clickableRow: {
    '&&:hover > td': {
      backgroundColor: `${BackgroundColors.lightMint}!important`,
    },
  },
  rowCAH: {
    '&& > td': {
      backgroundColor: `${BrandColors.careAtHome.tableRow}`,
    },
  },
  clickableRowCAH: {
    '&& > td': {
      backgroundColor: `${BrandColors.careAtHome.tableRow}`,
    },

    '&&:hover > td': {
      backgroundColor: `${BrandColors.careAtHome.tableRowHover}`,
    },
  },
  defaultSorting: {
    '&&': {
      padding: '0.5rem 0 0 0',
      right: '1.5rem',
      width: 0,
      display: 'table-cell',
    },
  },
  defaultSortingFixed: {
    '&&': {
      marginLeft: '-1rem',
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  noItems: {
    '&&': {
      borderBottom: 0,
      color: Colors.gray,
      fontWeight: 500,
      width: '100%',
    },
  },
  smallFontSize: {
    '&& thead': {
      '& th': {
        padding: '6px',
      },
    },
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
        padding: '6px',
        '& > button': {
          maxWidth: 'fit-content',
        },
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },

  fixedHeaderTable: {
    paddingTop: '2rem',
    '&& > thead > tr > th.MuiTableCell-root': {
      background: BackgroundColors.gray,
    },
  },
  footer: {
    '&& .MuiTableRow-root .MuiTableCell-root': {
      padding: 0,
      fontSize: '.75em',

      '& .MuiToolbar-root': {
        minHeight: '3rem',
      },
    },
  },
  whiteBackground: {
    '&& thead .MuiTableCell-root': {
      borderBottom: 0,
      color: Colors.iconGray,
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    },
    '&& tbody': {
      border: 0,
      borderRadius: '4px',
      boxShadow: `inset 0 0 0px 1px ${BackgroundColors.grayLine}`,

      '& .MuiTableRow-root:not(:last-child)': {
        borderBottom: `1px solid ${BackgroundColors.grayLine}!important`,
      },
      '& .MuiTableCell-root': {
        background: 'transparent',
        borderBottom: 0,
      },
    },
    '&& tfoot': {
      boxShadow: 'none',

      '& .MuiTableRow-root .MuiTableCell-root': {
        border: 0,
      },
    },
  },
});

type Data = any | { detail?: ReactNode };
export interface TableItem {
  detail?: ReactNode;
  detailData?: any;
  open?: boolean;
}

export type Column = {
  align?: string;
  component?: (params: Data, rowIndex: number) => ReactNode;
  dataCy?: string;
  direction?: 'asc' | 'desc';
  headerName?: ReactNode;
  field?: string;
  sortable?: boolean;
  width?: number | string;
};

type RowsPerPageOption =
  | {
      value: number;
      label: string;
    }
  | number;

type Config = {
  compact?: boolean;
  columns: Column[];
  data: Data[];
  defaultPageSize?: number;
  detail?: boolean;
  noItemsLabel?: string;
  pagination?: PaginationType;
  paginationOptions?: RowsPerPageOption[];
  size?: 'm' | 'l';
  getRowBrand?: (row: Data, idx: number) => Brand | undefined;
  getRowClass?: (row: Data, idx: number) => string;
};

type Props = {
  bottomLoading?: boolean;
  className?: string;
  config: Config;
  'data-cy'?: string;
  empty?: boolean | string;
  emptyLink?: string;
  fixedHeader?: boolean;
  fontSize?: 'small' | 'normal';
  onClickEmptyLink?: () => void;
  onClickRow?: (row: Data, idx: number) => void;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (qty: number) => void;
  onDefaultSort?: () => void;
  onSort?: (column: Column) => void;
  whiteBackground?: boolean;
};

export const Table = ({
  bottomLoading,
  className,
  config,
  'data-cy': dataCyTable,
  empty,
  emptyLink,
  fixedHeader,
  fontSize = 'normal',
  onClickEmptyLink,
  onClickRow,
  onChangePage,
  onChangeRowsPerPage,
  onDefaultSort,
  onSort,
  whiteBackground,
}: Props) => {
  const {
    columns,
    compact,
    data,
    defaultPageSize,
    getRowBrand,
    getRowClass,
    noItemsLabel,
    pagination,
    paginationOptions,
    size,
  } = config;
  const styles = useStyles();
  const [page, setPage] = React.useState(0);
  const [sortableData, setSortableData] = React.useState<Data[]>([]);
  const [sortableColumns, setSortableColumns] = React.useState<Column[]>(
    columns,
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultPageSize || 10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState<
    RowsPerPageOption[]
  >(paginationOptions || [-1]);

  const handleChangePage = (_: React.MouseEvent | null, newPage: number) => {
    setPage(newPage);
    if (onChangePage) onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rows = parseInt(event?.target?.value, 10);
    setRowsPerPage(rows);
    if (onChangeRowsPerPage) onChangeRowsPerPage(rows);
    handleChangePage(null, 0);
  };

  const getSafeField = (row: Data, fields: string) =>
    fields.split('.').reduce((acc, field) => acc && acc[field], row);

  useEffect(() => {
    let options = paginationOptions;
    let items = [...data];
    if (pagination && items.length < pagination.total) {
      items = items.concat(Array(pagination.total - items.length).fill(null));
    }
    if (!options) {
      if (items.length > 50) {
        options = [10, 25, 50, 100];
      } else if (items.length > 25) {
        options = [10, 25, 50];
      } else {
        options = [10, 25];
      }
    }
    setRowsPerPageOptions(options);
    setSortableData(items);

    if (!pagination || !rowsPerPage) {
      setRowsPerPage(
        defaultPageSize ||
          (typeof options[0] === 'number' ? options[0] : options[0].value),
      );
    }

    if (!pagination) {
      setPage(0);
    } else {
      setPage(pagination.offset / pagination.limit);
    }
  }, [data, pagination]);

  useEffect(() => {
    setSortableColumns(columns);
  }, [columns]);

  const classes = classNames(
    className,
    size && `size-${size}`,
    compact && 'compact',
    fontSize === 'small' && styles.smallFontSize,
    onClickRow && 'row-clickable',
    fixedHeader && styles.fixedHeaderTable,
    whiteBackground && styles.whiteBackground,
  );

  if (empty && !data?.length) {
    return (
      <EmptyState
        className={classNames('table-empty', className)}
        label={typeof empty === 'string' ? empty : undefined}
        link={emptyLink}
        onClickLink={onClickEmptyLink}
        whiteBackground={whiteBackground}
      />
    );
  }

  const getRowComputedClass = (rowData: Data, index: number) => {
    if (getRowBrand) {
      const brand = getRowBrand(rowData, index);
      if (brand === Brand.CARE_AT_HOME && onClickRow)
        return styles.clickableRowCAH;
      if (brand === Brand.CARE_AT_HOME) return styles.rowCAH;
    }
    if (onClickRow) return styles.clickableRow;
    return '';
  };

  return (
    <>
      <MTable
        className={classes}
        data-cy={dataCyTable}
        size={compact ? 'small' : 'medium'}
        stickyHeader={fixedHeader}
      >
        <TableHead>
          <tr>
            {sortableColumns.map(
              (
                { align = 'left', direction, headerName, width, sortable },
                index,
              ) =>
                headerName ? (
                  <TableCell
                    width={width}
                    component="th"
                    key={`tableHead-${headerName || index}`}
                    align={align === 'right' ? 'right' : 'left'}
                  >
                    {onSort && sortable ? (
                      <TableSortLabel
                        active={!!direction}
                        direction={direction}
                        key={`table-label-${headerName || index}`}
                        onClick={() => {
                          const newColumns: Column[] = sortableColumns.map(
                            (c, idx) => {
                              const { direction: d, ...rest } = c;
                              if (index !== idx) {
                                return rest;
                              }
                              return {
                                ...rest,
                                direction: d === 'asc' ? 'desc' : 'asc',
                              };
                            },
                          );
                          const column = newColumns[index];
                          onSort(column);
                          setSortableColumns(newColumns);
                        }}
                      >
                        {headerName}
                      </TableSortLabel>
                    ) : (
                      headerName
                    )}
                  </TableCell>
                ) : (
                  <></>
                ),
            )}
            {onDefaultSort &&
              sortableColumns.some(({ direction }) => !!direction) && (
                <TableCell width={0} sx={{ width: 0, padding: 0 }}>
                  <IconButton
                    className={classNames(
                      styles.defaultSorting,
                      fixedHeader && styles.defaultSortingFixed,
                    )}
                    onClick={() => {
                      setSortableColumns(
                        sortableColumns.map(({ direction, ...rest }) => rest),
                      );
                      onDefaultSort();
                    }}
                    size="small"
                    tooltip={translate('components.table.defaultSorting')}
                  >
                    <SortIcon fontSize="small" color="action" />
                  </IconButton>
                </TableCell>
              )}
          </tr>
        </TableHead>
        {!!sortableData.length && (
          <TableBody>
            {(rowsPerPage > 0
              ? sortableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : sortableData
            )
              .filter(Boolean)
              .map((row, index) => (
                <Fragment key={`tableRow-${index}`}>
                  <TableRow
                    className={getRowComputedClass(row, index)}
                    onClick={() => onClickRow && onClickRow(row, index)}
                  >
                    {columns.map(
                      (
                        { align, dataCy, component, field, width },
                        colIndex,
                      ) => (
                        <TableCell
                          align={align === 'right' ? 'right' : 'left'}
                          className={getRowClass ? getRowClass(row, index) : ''}
                          data-cy={dataCy ? `${dataCy}-${index}` : ''}
                          key={`tableCell-${index}-${colIndex}`}
                          scope="row"
                          width={width}
                        >
                          {!component && field && (
                            <span>{getSafeField(row, field) || EMPTY}</span>
                          )}
                          {component && component(row, index)}
                        </TableCell>
                      ),
                    )}
                    {onDefaultSort &&
                      sortableColumns.some(({ direction }) => !!direction) && (
                        <TableCell
                          className={getRowClass ? getRowClass(row, index) : ''}
                          width={0}
                          sx={{ width: 0, padding: 0 }}
                        />
                      )}
                  </TableRow>
                  {config.detail && (
                    <TableRow
                      className="detail"
                      style={{ display: row?.detail ? 'table-row' : 'none' }}
                    >
                      <TableCell
                        colSpan={columns.length}
                        style={{
                          backgroundColor: BackgroundColors.lighterGray,
                        }}
                      >
                        <Collapse open={!!row?.detail}>{row?.detail}</Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
          </TableBody>
        )}
        {!sortableData.length && (
          <TableRow>
            <TableCell colSpan={columns.length} className={styles.noItems}>
              {noItemsLabel || translate('global.noResults')}
            </TableCell>
          </TableRow>
        )}
        {!!data.length && (pagination || rowsPerPageOptions.length > 1) && (
          <TableFooter className={styles.footer}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                colSpan={
                  columns.length +
                  (onDefaultSort &&
                  sortableColumns.some(({ direction }) => !!direction)
                    ? 1
                    : 0)
                }
                count={sortableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={({
                  count,
                  page: componentPage,
                  onPageChange,
                  rowsPerPage: componentRowsPerPage,
                }) => (
                  <Pagination
                    count={count}
                    hideLastPage={!!pagination}
                    page={componentPage}
                    onPageChange={onPageChange}
                    rowsPerPage={componentRowsPerPage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        )}
      </MTable>
      {bottomLoading && (
        <div className={styles.spinner}>
          <Spinner width={40} />
        </div>
      )}
    </>
  );
};

export default Table;
