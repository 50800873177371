import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ProgramExtensionStatus } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient, ProgramExtension } from '@vestahealthcare/common/models';
import MemberProgramExtension from '@vestahealthcare/common/models/MemberProgramExtension';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { CheckboxWithLabel, Select } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchPatientWarnings } from 'dash/src/redux/slices/MemberInfoSlice';
import { CacheServices } from 'dash/src/services';
import { updateProgramExtensions } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  onSubmit: (patient: Patient) => void;
  patient: Patient;
}

export const EditProgramExtensions = ({ patient, onSubmit }: Props) => {
  const dispatch = useDispatch();
  const [programExtensions, setProgramExtensions] = useState(
    patient.programExtensions,
  );
  const [allProgramExtensions, setAllProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [submitted, setSubmitted] = useState(false);

  const fetchData = async () => {
    const [allProgramExtensions] = await Promise.all([
      CacheServices.getAllProgramExtensions(),
    ]);
    setAllProgramExtensions(allProgramExtensions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setProgramExtensions(patient.programExtensions);
  }, [patient.id, patient.programExtensions]);

  const updateProgramExtensionCheck = (
    programExtension: ProgramExtension,
    checked?: boolean,
    status?: String,
  ) => {
    if (checked) {
      setProgramExtensions([
        ...programExtensions,
        new MemberProgramExtension({
          programExtension,
          status,
        }),
      ]);
    } else {
      setProgramExtensions(
        programExtensions.filter(
          ({ programExtension: { id } }) => id !== programExtension.id,
        ),
      );
    }
  };

  const updateProgramExtensionStatus = (
    programExtension: ProgramExtension,
    status: String,
  ) => {
    const proExtAdd = new MemberProgramExtension({
      programExtension,
      status,
    });
    setProgramExtensions([
      ...programExtensions.filter(
        ({ programExtension: { id } }) => id !== programExtension.id,
      ),
      proExtAdd,
    ]);
  };

  const renderDetails = () => {
    if (!patient.programExtensions.length) {
      return EMPTY;
    }

    const sortedProgramExtensions = [
      ...patient.programExtensions,
    ].sort((a: MemberProgramExtension, b: MemberProgramExtension): number =>
      a.programExtension.name.localeCompare(b.programExtension.name),
    );

    return sortedProgramExtensions.map((proExt, index) => (
      <p
        className="no-margin"
        key={proExt.programExtension.id}
        style={{
          lineHeight: '12px',
          paddingTop: index === 0 ? '0.25rem' : undefined,
        }}
      >
        <span>{proExt.programExtension.name}</span>{' '}
        {proExt.status && (
          <span className="size-xs">[{proExt.status.toString()}]</span>
        )}
      </p>
    ));
  };

  const checkUpdatedPEs = () => {
    const oldCCM = patient.programExtensions?.find(
      ({ programExtension }) => programExtension.id === ProgramExtension.CCM,
    );
    const oldRPM = patient.programExtensions?.find(
      ({ programExtension }) => programExtension.id === ProgramExtension.RPM,
    );
    const newCCM = programExtensions?.find(
      ({ programExtension }) => programExtension.id === ProgramExtension.CCM,
    );
    const newRPM = programExtensions?.find(
      ({ programExtension }) => programExtension.id === ProgramExtension.RPM,
    );

    if (!oldCCM !== !newCCM || oldCCM?.status !== newCCM?.status) return true;
    if (!oldRPM !== !newRPM || oldRPM?.status !== newRPM?.status) return true;

    return false;
  };

  const submit = async () => {
    setSubmitted(true);
    if (programExtensions.find(({ status }) => !status)) {
      return false;
    }

    try {
      await updateProgramExtensions(
        patient.id,
        programExtensions.map(({ programExtension, status }) => ({
          programExtension,
          status,
        })),
      );
      if (checkUpdatedPEs()) {
        // TODO: Added timeout because the warning process in the BE is async
        setTimeout(() => {
          dispatch(fetchPatientWarnings(patient.id));
        }, 250);
      }
      await onSubmit(patient);
    } catch (e) {
      showGlobalError(e as string);
    }

    return true;
  };

  const getProgramExtensionStatus = (id: number) =>
    programExtensions.find((proExt) => proExt.programExtension.id === id)
      ?.status;

  return (
    <BaseModal
      details={
        <>
          <h4 className="grid-span-4">
            {translate('personalDetails.programExtensions')}
          </h4>
          <p className="grid-span-7" data-cy="program-extension-details">
            {renderDetails()}
          </p>
        </>
      }
      data-cy="program-extension"
      editable={patient.isEditable()}
      onShowModal={() => setSubmitted(false)}
      onCancel={() => {
        setProgramExtensions(patient.programExtensions);
      }}
      onSubmit={submit}
      title={translate('personalDetails.programExtensions')}
    >
      {allProgramExtensions
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((programExtension) => (
          <div key={programExtension.id} className="grid-wrapper">
            <CheckboxWithLabel
              className="grid-span-7"
              key={programExtension.id}
              data-cy={`${programExtension.abbr.toLowerCase()}-checkbox`}
              checked={programExtensions
                .map(({ programExtension: { id } }) => id)
                .includes(programExtension.id)}
              label={programExtension.name}
              onChange={(checked) =>
                updateProgramExtensionCheck(programExtension, checked)
              }
            />
            {programExtensions
              .map(({ programExtension: { id } }) => id)
              .includes(programExtension.id) && (
              <Select
                className="grid-span-5"
                data-cy="filter-program-ext-status"
                disableClearable
                error={
                  submitted && !getProgramExtensionStatus(programExtension.id)
                }
                placeholder={translate('global.select')}
                onChange={(status: Selectable) =>
                  updateProgramExtensionStatus(
                    programExtension,
                    status.value as string,
                  )
                }
                items={ProgramExtensionStatus.toSelectable()}
                value={
                  getProgramExtensionStatus(programExtension.id)
                    ? Enum.toSelectable([
                        getProgramExtensionStatus(programExtension.id),
                      ] as ProgramExtensionStatus[])[0]
                    : undefined
                }
                required
                size="small"
              />
            )}
            <div className="grid-span-12" />
          </div>
        ))}
    </BaseModal>
  );
};

export default EditProgramExtensions;
