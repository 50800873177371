import { EmployeeRole, States } from '../enums';
import { SelectableInfo } from '../enums/Enum';
import { Account } from './Account';
import { DashPermission } from './DashPermission';
import { EmployeeGroup } from './EmployeeGroup';
import { make, makeArray } from './Model';

// todo: this list is temporary until we switch our permissions into actual individual permissions
export enum PermissionName {
  ADMIN = 'ADMIN',
  CLINICAL = 'CLINICAL',
  CLINICAL_ADMIN = 'CLINICAL_ADMIN',
}

export class Employee {
  account: Account;

  createdAt = 0;

  email = '';

  enabled = false;

  phiAccess = false;

  firstName = '';

  fullName = '';

  hasPicture = false;

  id = 0;

  licences: States[] = [];

  lastName = '';

  phoneNumber = '';

  role: EmployeeRole;

  timeZone = '';

  updatedAt = 0;

  userName = ''; // TODO: Deprecate userName

  zoomMeetingId = 0;

  dashPermissions?: DashPermission[];

  employeeGroups?: EmployeeGroup[];

  ecwId?: '';

  sfId?: 0;

  nlc = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.role = EmployeeRole.byKey[obj.role];
    this.account = make(obj.account, Account);
    this.licences = obj.licences
      ? obj.licences.map(({ state }: any) => States.byKey[state])
      : [];

    // todo: this will come as array from backend
    this.dashPermissions = obj.dashPermission
      ? [make(obj.dashPermission, DashPermission)]
      : [];
    this.employeeGroups = makeArray(obj.employeeGroups, EmployeeGroup);
  }

  static sort({ fullName: a }: Employee, { fullName: b }: Employee) {
    return a.localeCompare(b);
  }

  get firstInitialLastName() {
    if (this.firstName) {
      return `${this.firstName.charAt(0)} ${this.lastName}`;
    }
    return this.lastName;
  }

  get initials() {
    return `${this.firstName.substring(0, 1)}${this.lastName.substring(0, 1)}`;
  }

  get firstNameLastInitial() {
    return `${this.firstName} ${this.lastName.substring(0, 1)}.`;
  }

  get homepage() {
    if (this.isExternal) {
      return '/dashboard/ccm';
    }
    if (!this.isAdmin) {
      return '/dashboard/events';
    }
    return '/';
  }

  get isCareCoordinator() {
    return this.activeGroups?.find((group) => group.isCareCoordinator());
  }

  get isRN() {
    return this.role === EmployeeRole.RN;
  }

  get isNP() {
    return this.role === EmployeeRole.NP;
  }

  // Deprecated
  get isUnknown() {
    return !this.dashPermissions?.length;
  }

  get isClinical() {
    return this.can(PermissionName.CLINICAL, PermissionName.CLINICAL_ADMIN);
  }

  get isAdmin() {
    return this.can(PermissionName.ADMIN, PermissionName.CLINICAL_ADMIN);
  }

  get isInAdmin() {
    return !!this.employeeGroups?.find(({ id }) => id === EmployeeGroup.ADMIN);
  }

  get isExternal() {
    return this.role === EmployeeRole.EXTERNAL;
  }

  get isInEligibility() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.ELIGIBILLITY,
    );
  }

  get isInEligibilityManagement() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.ELIGIBILLITY_MANAGEMENT,
    );
  }

  get isInTOC() {
    return !!this.employeeGroups?.find(({ id }) => id === EmployeeGroup.TOC);
  }

  get isInEnrollment() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.ENROLLMENT,
    );
  }

  get isInBilling() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.BILLING,
    );
  }

  get isInBizOps() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.BIZ_OPS,
    );
  }

  get isInClinicalLeadership() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.CLINICAL_LEADERSHIP,
    );
  }

  get isInIncidentsManagement() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.INCIDENTS_MANAGEMENT,
    );
  }

  get isInLicensureAdministration() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.LICENSURE_ADMINISTRATION,
    );
  }

  get isInRosterIngestionTeam() {
    return !!this.employeeGroups?.find(
      ({ id }) => id === EmployeeGroup.ROSTER_INGESTION,
    );
  }

  get activeGroups() {
    return this.employeeGroups?.filter(({ active }) => active);
  }

  get isInRNs() {
    return !!this.activeGroups?.find((group) => group.isRNOwner());
  }

  get isInNPs() {
    return !!this.activeGroups?.find((group) => group.isNPOwner());
  }

  get isInCareCoordinator() {
    return !!this.activeGroups?.find((group) => group.isCareCoordinator());
  }

  get isInEngagement() {
    return !!this.activeGroups?.find((group) => group.isEngagement());
  }

  get isInHealthCoaches() {
    return !!this.activeGroups?.find((group) => group.isHealthCoach());
  }

  can(...names: PermissionName[]) {
    return !!this.dashPermissions?.find((permission) =>
      names.some((permName) => permName === permission.name),
    );
  }

  get canEditPHIP() {
    return this.isInClinicalLeadership || this.isInRNs || this.isInNPs;
  }

  get canEditVestaTeam() {
    return this.isInClinicalLeadership || this.isInBizOps;
  }

  get canSetMemberInvalidStatus() {
    return this.isInEligibilityManagement;
  }

  get canEditFacilities() {
    return this.isInEligibilityManagement;
  }

  get canEditTOC() {
    return this.isInTOC;
  }

  get canAddCareTeamRoster() {
    return this.isInRosterIngestionTeam;
  }

  allowedInState(state?: States, nlcStates?: States[]) {
    return (
      !state ||
      this.licences.includes(state) ||
      (this.nlc && nlcStates?.includes(state))
    );
  }

  static toSelectable(employee: Employee[]): SelectableInfo[] {
    return employee.map((e) => ({
      value: e.id,
      label: e.fullName,
      info: e,
    }));
  }
}

export default Employee;
