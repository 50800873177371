import moment from 'moment';

import {
  DayOfWeek,
  Language,
  MemberContactTimeType,
  MemberRisk,
  ProgramExtensionStatus,
  States,
} from '@vestahealthcare/common/enums';
import { ProgramExtension } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  LS_DASHBOARD_CCM_FILTERS,
} from '@vestahealthcare/common/utils/constants';

import { GetCCMMemberParams } from 'dash/src/services/CCMServices';
import {
  transformDateToDaysRange,
  transformDaysRangeToDates,
} from 'dash/src/utils/dateUtils';
import {
  getAsQuery,
  getBoolean,
  getCustom,
  getDateRange,
  getStoredFilters,
  saveStoredFilters,
} from 'dash/src/utils/filterUtils';

export const getFilters = (query: URLSearchParams) => {
  const clearFilters = query.get('clearFilters');
  const filters = clearFilters
    ? {}
    : getStoredFilters(LS_DASHBOARD_CCM_FILTERS);
  const isBiannualComplete = getBoolean(query, filters, 'isBiannualComplete');

  const defaultCompletedDateRange =
    getDateRange(query, filters, 'carePlanDateRange') || -1;

  const hasCarePlan =
    getBoolean(query, filters, 'hasCarePlan') === false ? false : undefined;
  const hasAideScheduleInfo = getBoolean(query, filters, 'hasAideScheduleInfo');
  const hasContactInfo = getBoolean(query, filters, 'hasContactInfo');
  const hasLanguage = getBoolean(query, filters, 'hasLanguage');
  const hasProfessionalTime = getBoolean(query, filters, 'hasProfessionalTime');
  const hasProgramExtensions = getBoolean(
    query,
    filters,
    'hasProgramExtensions',
  );
  const hasRiskLevel = getBoolean(query, filters, 'hasRiskLevel');
  const hasValidCCM = getBoolean(query, filters, 'hasValidCCM');
  const isComplete = getBoolean(query, filters, 'isComplete');
  const isUnreachable = getBoolean(query, filters, 'isUnreachable');
  const initialCPDue = getBoolean(query, filters, 'initialCPDue');
  const carePlanDue = getBoolean(query, filters, 'carePlanDue');

  const careTeamDiscoveryDue = getBoolean(
    query,
    filters,
    'careTeamDiscoveryDue',
  );

  const carePlanDate = transformDaysRangeToDates(defaultCompletedDateRange);
  const totalMinutesFromString =
    query.get('totalMinutesFrom') || filters.totalMinutesFrom;
  const totalMinutesFrom =
    totalMinutesFromString !== undefined
      ? Number.parseInt(totalMinutesFromString, 10)
      : undefined;
  const totalMinutesToString =
    query.get('totalMinutesTo') || filters.totalMinutesTo;
  const totalMinutesTo =
    totalMinutesToString !== undefined
      ? Number.parseInt(totalMinutesToString, 10)
      : undefined;

  const numberOfCallsString =
    query.get('numberOfCalls') || filters.numberOfCalls;
  const numberOfCalls =
    numberOfCallsString !== undefined
      ? Number.parseInt(numberOfCallsString, 10)
      : undefined;

  const startDateFromString =
    query.get('startDateFrom') || filters.startDateFrom;
  const startDateFrom = startDateFromString
    ? moment(startDateFromString)
    : undefined;
  const startDateToString = query.get('startDateTo') || filters.startDareTo;
  const startDateTo = startDateToString ? moment(startDateToString) : undefined;

  const programExtensionId = getCustom(
    query.getAll('programExtensionId'),
    filters.programExtensionId,
    (item) => Number(item),
  ) as number[];

  const storedExcludedProgramExtensions = getCustom(
    query.getAll('excludedProgramExtensionId'),
    filters.excludedProgramExtensions,
    (item) => Number(item),
  ) as number[];

  let excludedProgramExtensionId = [] as number[];

  if (storedExcludedProgramExtensions?.length) {
    excludedProgramExtensionId = storedExcludedProgramExtensions;
  } else {
    excludedProgramExtensionId = [
      ProgramExtension.MEDICAID,
      ProgramExtension.TCM,
      ProgramExtension.VESTA_LITE,
    ];
  }

  return {
    aideScheduleDay: getCustom(
      query.getAll('aideScheduleDay'),
      filters.aideScheduleDay,
      (item) => DayOfWeek.byKey[item],
    ) as DayOfWeek[],
    aideScheduleTime: getCustom(
      query.getAll('aideScheduleTime'),
      filters.aideScheduleTime,
      (item) => MemberContactTimeType.byKey[item],
    ) as MemberContactTimeType[],
    brandId: getCustom(query.getAll('brandId'), filters.brandId),
    isBiannualComplete,
    careCoordinatorId: getCustom(
      query.getAll('careCoordinatorId'),
      filters.careCoordinatorId,
      (item) => Number(item),
    ) as number[],
    carePlanDateFrom: carePlanDate[0]
      ? moment(carePlanDate[0].getTime())
      : undefined,
    carePlanDateTo: carePlanDate[1]
      ? moment(carePlanDate[1].getTime())
      : undefined,
    carePlanDue,
    carePlanGroupId: getCustom(
      query.getAll('carePlanGroupId'),
      filters.carePlanGroupId,
      (item) => Number(item),
    ) as number[],
    ccmCallStatus: getCustom(
      query.getAll('ccmCallStatus'),
      filters.ccmCallStatus,
    ) as string[],
    careTeamDiscoveryDue,
    contactDay: getCustom(
      query.getAll('contactDay'),
      filters.contactDay,
      (item) => DayOfWeek.byKey[item],
    ) as DayOfWeek[],
    contactTime: getCustom(
      query.getAll('contactTime'),
      filters.contactTime,
      (item) => MemberContactTimeType.byKey[item],
    ) as MemberContactTimeType[],
    employeeGroupId: getCustom(
      query.getAll('employeeGroupId'),
      filters.employeeGroupId,
      (item) => Number(item),
    ) as number[],
    engagementOwnerId: getCustom(
      query.getAll('engagementOwnerId'),
      filters.engagementOwnerId,
      (item) => Number(item),
    ) as number[],
    excludedProgramExtensionId,
    hasCarePlan,
    hasAideScheduleInfo,
    hasContactInfo,
    hasLanguage,
    hasProfessionalTime,
    hasProgramExtensions,
    hasRiskLevel,
    hasValidCCM,
    healthCoachId: getCustom(
      query.getAll('healthCoachId'),
      filters.healthCoachId,
      (item) => Number(item),
    ) as number[],
    initialCPDue,
    isComplete,
    isUnreachable,
    language: getCustom(
      query.getAll('language'),
      filters.language,
      (item) => Language.byKey[item],
    ) as Language[],
    memberIds: getCustom(query.getAll('memberIds'), filters.memberIds, (item) =>
      Number(item),
    ) as number[],
    memberName: query.get('memberName') || filters.memberName,
    npOwnerId: getCustom(query.getAll('npOwnerId'), filters.npOwnerId, (item) =>
      Number(item),
    ) as number[],
    numberOfCalls,
    programExtensionId,
    programExtensionStatus: getCustom(
      query.getAll('programExtensionStatus'),
      filters.programExtensionStatus,
      (item) => ProgramExtensionStatus.byKey[item],
    ) as ProgramExtensionStatus[],
    referralSource: getCustom(
      query.getAll('referralSource'),
      filters.referralSource instanceof Array
        ? filters.referralSource
        : Object.values(filters.referralSource || {}),
    ) as string[],
    riskLevel: getCustom(
      query.getAll('riskLevel'),
      filters.riskLevel,
      (item) => MemberRisk.byKey[item],
    ) as MemberRisk[],
    rnOwnerId: getCustom(query.getAll('rnOwnerId'), filters.rnOwnerId, (item) =>
      Number(item),
    ) as number[],
    rpmAdherenceId: getCustom(
      query.getAll('rpmAdherenceId'),
      filters.rpmAdherenceId,
    ) as string[],
    sort: query.get('sort') || filters.sort,
    startDateFrom,
    startDateTo,
    state: getCustom(
      query.getAll('state'),
      filters.state,
      (item) => States.byKey[item],
    ) as States[],
    touchPointReason: getCustom(
      query.getAll('touchPointReason'),
      filters.touchPointReason,
    ) as string[],
    totalMinutesFrom,
    totalMinutesTo,
    worklistGroupId: getCustom(
      query.getAll('worklistGroupId'),
      filters.worklistGroupId,
      (item) => Number(item),
    ) as number[],
  };
};

export const storeFilters = (filters: GetCCMMemberParams, sort: string) => {
  const {
    carePlanDateFrom,
    carePlanDateTo,
    aideScheduleDay,
    aideScheduleTime,
    contactDay,
    contactTime,
    language,
    programExtensionStatus,
    riskLevel,
    startDateFrom,
    startDateTo,
    state,
    ...storedFilters
  } = filters;

  const flatFilters = {
    carePlanDateRange: carePlanDateFrom
      ? transformDateToDaysRange(carePlanDateFrom?.toDate(), new Date(), [
          7,
          30,
          90,
        ])
      : -1,
    aideScheduleDay: aideScheduleDay?.map(({ value }) => value),
    aideScheduleTime: aideScheduleTime?.map(({ value }) => value),
    contactDay: contactDay?.map(({ value }) => value),
    contactTime: contactTime?.map(({ value }) => value),
    language: language?.map(({ value }) => value),
    programExtensionStatus: programExtensionStatus?.map(({ value }) => value),
    riskLevel: riskLevel?.map(({ value }) => value),
    startDateFrom: startDateFrom?.format(DATE_FORMAT),
    startDateTo: startDateTo?.format(DATE_FORMAT),
    state: state?.map(({ value }) => value),
    sort,
    ...storedFilters,
  };

  const query = getAsQuery(flatFilters);

  saveStoredFilters(LS_DASHBOARD_CCM_FILTERS, flatFilters);

  return query;
};
