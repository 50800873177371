import {
  EmployeeGroupStatus,
  EmployeeGroupType,
  EmployeeRole,
  States,
} from '@vestahealthcare/common/enums';
import {
  Employee,
  EmployeeGroup,
  StoredFilter,
} from '@vestahealthcare/common/models';
import DashPermission from '@vestahealthcare/common/models/DashPermission';

import Api, { PaginatedResponse } from 'dash/src/services/Api';

export interface EmployeeParams {
  email?: string;
  employeeGroupId?: EmployeeGroup[];
  employeeId?: number;
  enabled?: boolean;
  permission?: DashPermission[];
  phiAccess?: boolean;
  role?: EmployeeRole[];
  searchName?: string;
  sort?: string;
  state?: States[];
  offset?: number;
  limit?: number;
  ecwId?: string;
  sfId?: number;
}

export const fetchSelf = async () => fetchEmployee('self');

export const fetchEmployee = async (employeeId: number | string) => {
  const { employee } = await Api.getv2(`/employees/${employeeId}`);
  return new Employee(employee);
};

const getEmployeeFilters = ({
  employeeGroupId,
  permission,
  role,
  state,
  ...rest
}: EmployeeParams) => ({
  employeeGroupId: employeeGroupId?.map(({ id }) => id),
  permission: permission?.map(({ name }) => name),
  role: role?.map(({ value }) => value),
  state: state?.map(({ value }) => value),
  ...rest,
});

export const fetchEmployees = async (
  params?: EmployeeParams,
): Promise<Employee[]> => {
  const requestParams = {
    sort: 'fullName asc',
    ...(params ? getEmployeeFilters(params) : {}),
  };
  const { employees } = await Api.getv2('/employees', requestParams);
  return employees.items.map((employee: any) => new Employee(employee));
};

export const fetchPaginatedEmployees = async (
  params?: EmployeeParams,
): Promise<PaginatedResponse<Employee>> => {
  const requestParams = {
    sort: 'fullName asc',
    ...(params ? getEmployeeFilters(params) : {}),
  };
  const {
    employees: { items, pagination },
  } = await Api.getv2('/employees', requestParams);
  return {
    items: items.map((employee: any) => new Employee(employee)),
    pagination,
  };
};

export interface EmployeeUpdateParam {
  dashPermissionId?: DashPermission;
  ecwId?: string;
  email: string;
  employeeGroupIds?: EmployeeGroup[];
  enabled: boolean;
  firstName: string;
  lastName: string;
  licenceStates?: States[];
  nlc?: boolean;
  phiAccess: boolean;
  role: EmployeeRole;
  sfId?: number;
}
export type EmployeeCreateParam = Omit<EmployeeUpdateParam, 'enabled'>;

export const createEmployee = async ({
  dashPermissionId,
  employeeGroupIds,
  licenceStates,
  role,
  ...rest
}: EmployeeCreateParam) => {
  const { employee } = await Api.postv2JSON('/employees/', {
    dashPermissionId: dashPermissionId?.id,
    employeeGroupIds: employeeGroupIds?.map(({ id }) => id),
    licenceStates: licenceStates?.map(({ value }) => value),
    role: role.value,
    ...rest,
  });
  return new Employee(employee);
};

export const updateEmployee = async (
  id: number,
  {
    dashPermissionId,
    employeeGroupIds,
    licenceStates,
    role,
    ...rest
  }: Partial<EmployeeUpdateParam>,
) => {
  const { employee } = await Api.patchv2JSON(`/employees/${id}`, {
    dashPermissionId: dashPermissionId?.id,
    employeeGroupIds: employeeGroupIds?.map(({ id }) => id),
    licenceStates: licenceStates?.map(({ value }) => value),
    role: role?.value,
    ...rest,
  });
  return new Employee(employee);
};

export const fetchActiveByRole = async (
  roles: EmployeeRole | EmployeeRole[],
): Promise<Employee[]> => {
  const role = Array.isArray(roles)
    ? roles.map((r) => r.valueOf())
    : [roles.valueOf()];

  const { employees } = await Api.getv2('/employees', {
    limit: -1,
    role,
    enabled: true,
    sort: 'fullName asc',
  });
  return employees.items.map((employee: any) => new Employee(employee));
};

export const fetchActiveByGroup = async (
  groupIds: number | number[],
): Promise<Employee[]> => {
  const employeeGroupId = Array.isArray(groupIds)
    ? groupIds.map((r) => r.valueOf())
    : [groupIds.valueOf()];

  const { employees } = await Api.getv2('/employees', {
    limit: -1,
    employeeGroupId,
    enabled: true,
    sort: 'fullName asc',
  });
  return employees.items.map((employee: any) => new Employee(employee));
};

export const fetchActiveTimeTrackingRNOwners = async (): Promise<
  Employee[]
> => {
  const { employees } = await Api.getv2('/employees/views/active-rns', {
    limit: -1,
  });
  return employees.items.map((employee: any) => new Employee(employee));
};

export const fetchEmployeeGroups = async (
  includeInactive?: boolean,
): Promise<EmployeeGroup[]> => {
  const { employeeGroups } = await Api.getv2('/employees/groups');

  return employeeGroups?.items
    .map((group: any) => new EmployeeGroup(group))
    .filter(
      ({ status }: EmployeeGroup) =>
        includeInactive || status === EmployeeGroupStatus.ACTIVE,
    );
};

export type UpdateEmployeeGroupParams = {
  name?: string;
  status?: EmployeeGroupStatus;
  type?: EmployeeGroupType;
};

export const createEmployeeGroup = async ({
  name,
  status,
  type,
}: UpdateEmployeeGroupParams) => {
  return await Api.postv2JSON('/employees/groups', {
    name,
    status: status?.value,
    type: type?.value,
  });
};

export const updateEmployeeGroup = async (
  id: number,
  { name, status, type }: UpdateEmployeeGroupParams,
) => {
  return await Api.patchv2JSON(`/employees/groups/${id}`, {
    name,
    status: status?.value,
    type: type?.value,
  });
};

export const fetchEmployeeNLCStates = async (): Promise<States[]> => {
  const { nlcStates } = await Api.getv2('/employees/nlc-states');

  return nlcStates.map((state: string) => States.byKey[state]);
};

export const fetchEmployeeStoredFilters = async (
  employeeId: number | string,
) => {
  const {
    employeeFilters: { items },
  } = await Api.getv2(`/employees/${employeeId}/filters`);
  return items?.map((item: any) => new StoredFilter(item));
};

export const fetchSelfStoredFilters = async () => {
  return fetchEmployeeStoredFilters('self');
};

type CreateEmploteeStoredFilter = {
  description: string;
  page: string;
  filters: string;
  preferred: boolean;
};

export const createSelfStoredFilter = async (
  filter: CreateEmploteeStoredFilter,
) => {
  const { employeeFilter } = await Api.postv2JSON(
    '/employees/self/filters',
    filter,
  );

  return new StoredFilter(employeeFilter);
};

export const updateSelfStoredFilter = async (
  filterId: number,
  filter: Partial<CreateEmploteeStoredFilter>,
) => {
  const { employeeFilter } = await Api.patchv2JSON(
    `/employees/self/filters/${filterId}`,
    filter,
  );

  return new StoredFilter(employeeFilter);
};

export const deleteSelfStoredFilter = async (filterId: number) =>
  await Api.deletev2(`/employees/self/filters/${filterId}`);
