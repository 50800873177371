import { SelectableInfo } from '../enums/Enum';
import { BaseEnum } from './BaseEnum';
import { EventStatusGroup } from './EventStatusGroup';
import { make } from './Model';

export class EventStatus extends BaseEnum {
  group: EventStatusGroup;

  constructor(obj: any) {
    super(obj);
    this.group = make(obj.group, EventStatusGroup);
  }

  static NOT_STARTED = 'NOT_STARTED';

  static IN_PROGRESS = 'IN_PROGRESS';

  static VITALS_RECHECK_NORMAL = 'VITALS_RECHECK_NORMAL';

  static VITALS_RECHECK_ABNORMAL = 'VITALS_RECHECK_ABNORMAL';

  static NON_URGENT_FOLLOW_UP = 'NON_URGENT_FOLLOW_UP';

  static ESCALATED = 'ESCALATED';

  static URGENT_FOLLOW_UP = 'URGENT_FOLLOW_UP';

  static COMPLETE = 'COMPLETE';

  static DOCUMENTATION_ONLY = 'DOCUMENTATION_ONLY';

  static INVALID = 'INVALID';

  get isClosedStatus() {
    return this.group.id === EventStatusGroup.CLOSED;
  }

  static getColor(status?: EventStatus | string) {
    const value = typeof status === 'string' ? status : status?.id;
    switch (value) {
      case EventStatus.NOT_STARTED:
        return 'info';
      case EventStatus.VITALS_RECHECK_ABNORMAL:
      case EventStatus.ESCALATED:
      case EventStatus.URGENT_FOLLOW_UP:
        return 'error';
      case EventStatus.COMPLETE:
        return 'success';
      case EventStatus.INVALID:
        return 'closed';
      case EventStatus.DOCUMENTATION_ONLY:
        return 'default';
      case EventStatus.NON_URGENT_FOLLOW_UP:
      case EventStatus.IN_PROGRESS:
      case EventStatus.VITALS_RECHECK_NORMAL:
      default:
        return 'warning';
    }
  }

  static toSelectable(items: BaseEnum[]): SelectableInfo[] {
    return items.map((info) => ({
      value: info.id,
      label: info.description,
      info,
    }));
  }
}

export default EventStatus;
