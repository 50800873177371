import React from 'react';
import classnames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient, Task } from '@vestahealthcare/common/models';
import { Chip, Fonts } from 'styleguide-v2';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  label: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  name: {
    maxWidth: '30rem',
  },
  taskList: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.25rem',
  },
  taskItem: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
  },
});

type Props = {
  className?: string;
  tasks?: Task[];
  patient?: Patient;
};

const TASK_STATUS_COLOR: {
  [key: string]: {
    type: 'outlined' | 'contained';
    color: 'secondary' | 'action' | 'error' | 'info';
  };
} = {
  [TaskStatus.NOT_STARTED.value]: {
    color: 'action',
    type: 'outlined',
  },
  [TaskStatus.ESCALATED.value]: {
    color: 'secondary',
    type: 'outlined',
  },
  [TaskStatus.IN_PROGRESS.value]: {
    color: 'secondary',
    type: 'contained',
  },
  [TaskStatus.COMPLETED.value]: {
    color: 'info',
    type: 'contained',
  },
  [TaskStatus.DELAYED.value]: {
    color: 'error',
    type: 'outlined',
  },
  [TaskStatus.VOID.value]: {
    color: 'error',
    type: 'contained',
  },
};

export const EventDetailTasks = ({ className, tasks, patient }: Props) => {
  const styles = useStyles();

  return !tasks?.length || !patient ? (
    <></>
  ) : (
    <div className={classnames(className, styles.container)}>
      <span className={styles.label}>
        {translate('memberEvents.tasks', {
          count: tasks.length,
        })}
      </span>
      <ul className={styles.taskList}>
        {tasks.map(({ id, taskDefinition, status }) => (
          <li className={styles.taskItem} key={`encounter-id-${id}`}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`/#/patients/${patient?.id}/tasks?id=${id}`}
            >
              <span className={styles.name}>{taskDefinition.name}</span>
            </a>
            <Chip
              color={TASK_STATUS_COLOR[status.value]?.color}
              type={TASK_STATUS_COLOR[status.value]?.type}
              size="small"
            >
              {status.toString()}
            </Chip>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default EventDetailTasks;
