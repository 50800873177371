export const Colors = {
  blue: '#3F8BFA',
  darkGray: '#949494',
  blueCold: '#7FC0E8',
  darkGreen: '#006B64',
  darkOrange: '#DA8160',
  darkerOrange: '#D06842',
  error: '#D32F2F',
  gray: '#BBBBBB',
  green: '#00A384',
  iconGreen: '#25A17F',
  iconGray: '#999999',
  gold: '#FEC25F',
  lightCoral: '#ED9473',
  lighterGray: 'rgba(0, 0, 0, 0.08)',
  lightGray: 'rgba(0, 0, 0, 0.24)',
  lightMint: '#D3F0E9',
  link: '#3f8bf4',
  mint: '#AFE2D6',
  orange: '#ED6318',
  paleOrange: '#FFF7F4',
  paleGray: '#C2C2C2',
  paleGreen: '#F2FFFC',
  pink: '#FFC0BD',
  purple: '#6916BA',
  textOrange: '#ED6318',
  textBlack: '#444444',
  textBlue: '#0E68E8',
  textBlueCold: '#326787',
  textGray: '#666666',
  textRed: '#FF5035',
  textRed2: '#A53120',
  textWhite: '#FFF',
  textGreen: '#154C30',
  textLightGreen: '#008F85',
  white: '#FFF',
  yellow: '#fff3c6',
};

// Only to be used when primary colors are not viable
export const BackgroundColors = {
  lighterBlue: '#0066FF10',
  lightBlue: '#0066FF24',
  lightCoral: '#FAF3E6',
  lightMint: '#F0F8F6',
  lightGreen: '#008F85',
  grayLine: '#D9D9D9',
  gray: '#F7F6F4',
  lightGray: '#D9D9D9',
  lighterGray: '#FBFBFB',
  lightYellow: '#FFF3C6',
};

export const BrandColors = {
  careAtHome: {
    tableRow: '#FEF1E9!important',
    tableRowHover: '#FEE3D4!important',
    tableBorder: '#F37021',
    toastBackground: '#F37021',
    headerBackground: '#FDEADE',
    textColor: '#C25A1A',
  },
};

export default Colors;
