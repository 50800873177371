import { Selectable } from '../enums/Enum';

export class ProgramExtension {
  id = 0;

  name = '';

  abbr = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  static CCM = 2;

  static RPM = 3;

  static VESTA_CORE = 5;

  static VESTA_LITE = 9;

  static TCM = 42;

  static MEDICAID = 43;

  static BHI = 110;

  static toSelectable(o: ProgramExtension[]): Selectable[] {
    return o.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }
}

export default ProgramExtension;
