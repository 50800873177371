import React, { useEffect, useState } from 'react';

import {
  CollectionMethod,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { Panel, Select } from 'styleguide-v2';
import { FilterGroup, FilterItem } from 'styleguide-v2/src/components/Panel';

import { ReferralDropdown } from 'dash/src/components/ReferralDropdown';
import { TaskFetchParams } from 'dash/src/services/TaskServices';
import { getFilterCount, getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetTaskParams = keyof TaskFetchParams;

type Props = {
  data: {
    brands: Selectable[];
    employeeGroups: SelectableInfo[];
    employees: SelectableInfo[];
    languages: Selectable[];
    organizations: Selectable[];
    programExtensions: Selectable[];
    status: Selectable[];
    taskDefinitionId: Selectable[];
  };
  disabledFilters: KeyGetTaskParams[];
  filters?: {
    [x in KeyGetTaskParams]?: FilterItem;
  };
  loadingFilters?: boolean;
  open: boolean;
  onChange: (
    filters: {
      [x in KeyGetTaskParams]?: FilterItem;
    },
  ) => void;
  onClose: () => void;
};

const MEMBER_FILTERS: KeyGetTaskParams[] = [
  'referralSourceId',
  'language',
  'brandId',
];

const TASK_FILTERS: KeyGetTaskParams[] = [
  'taskDefinitionId',
  'status',
  'assigneeId',
  'employeeGroupId',
  'createdById',
];

const PROGRAM_FILTERS: KeyGetTaskParams[] = [
  'programExtensionId',
  'programExtensionStatus',
  'vitalCollectionMethod',
];

export const TaskFiltersModal = ({
  data,
  disabledFilters,
  filters,
  loadingFilters,
  open,
  onChange,
  onClose,
}: Props) => {
  const [pendingFilters, setPendingFilters] = useState<
    {
      [x in KeyGetTaskParams]?: FilterItem;
    }
  >(filters || {});

  useEffect(() => {
    setPendingFilters(filters || {});
  }, [filters]);

  return (
    <Panel.FilterModal
      open={open}
      onClear={() => {
        onChange({});
      }}
      onClose={onClose}
      onSubmit={() => onChange(pendingFilters)}
    >
      <FilterGroup
        title="Member Details"
        count={getFilterCount(pendingFilters, MEMBER_FILTERS)}
      >
        <ReferralDropdown
          data-cy="task-filter-modal-referral-sources"
          items={data.organizations}
          label={translate('clinicalDashboard.tasks.filters.organization')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              referralSourceId: getFilterItem(
                translate('clinicalDashboard.tasks.filters.organization'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.referralSourceId?.value || []}
        />

        <Select
          data-cy="task-filter-modal-language"
          grouped={({ preferred }) =>
            preferred
              ? translate('global.preferred')
              : translate('global.other')
          }
          items={data.languages.filter(({ disabled }) => !disabled)}
          label={translate('clinicalDashboard.tasks.filters.language')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              language: getFilterItem(
                translate('clinicalDashboard.tasks.filters.language'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.language?.value || []}
        />
        <Select
          data-cy="task-filter-modal-brand"
          items={data.brands}
          label={translate('clinicalDashboard.tasks.filters.brand')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              brandId: getFilterItem(
                translate('clinicalDashboard.tasks.filters.brand'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.brandId?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        title="Task Details"
        count={getFilterCount(pendingFilters, TASK_FILTERS)}
      >
        <Select
          data-cy="task-filter-modal-task-type"
          items={data.taskDefinitionId}
          label={translate('clinicalDashboard.tasks.filters.type')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              taskDefinitionId: getFilterItem(
                translate('clinicalDashboard.tasks.filters.type'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.taskDefinitionId?.value || []}
        />

        <Select
          data-cy="task-filter-modal-event-status"
          disabled={disabledFilters.includes('status')}
          items={data.status}
          label={translate('clinicalDashboard.tasks.filters.status')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              status: getFilterItem(
                translate('clinicalDashboard.tasks.filters.status'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.status?.value || []}
        />

        <Select
          data-cy="task-filter-modal-assignee"
          disabled={disabledFilters.includes('assigneeId')}
          items={data.employees}
          label={translate('clinicalDashboard.tasks.filters.assignee')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              assigneeId: getFilterItem(
                translate('clinicalDashboard.tasks.filters.assignee'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.assigneeId?.value || []}
        />

        <Select
          data-cy="task-filter-modal-assignee-group"
          disabled={disabledFilters.includes('employeeGroupId')}
          items={data.employeeGroups}
          label={translate('clinicalDashboard.tasks.filters.group')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              employeeGroupId: getFilterItem(
                translate('clinicalDashboard.tasks.filters.group'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.employeeGroupId?.value || []}
        />

        <Select
          data-cy="task-filter-modal-created-by"
          items={data.employees.filter(({ value }) => !!value)}
          label={translate('clinicalDashboard.tasks.filters.createdBy')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              createdById: getFilterItem(
                translate('clinicalDashboard.tasks.filters.createdBy'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.createdById?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        title="Program Details"
        count={getFilterCount(pendingFilters, PROGRAM_FILTERS)}
      >
        <Select
          data-cy="task-filter-modal-pe"
          items={data.programExtensions}
          label={translate('clinicalDashboard.tasks.filters.programExtensions')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionId: getFilterItem(
                translate('clinicalDashboard.tasks.filters.programExtensions'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionId?.value || []}
        />

        <Select
          data-cy="task-filter-modal-pe"
          items={Enum.toSelectable(ProgramExtensionStatus.asArray)}
          label={translate(
            'clinicalDashboard.tasks.filters.programExtensionStatus',
          )}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionStatus: getFilterItem(
                translate(
                  'clinicalDashboard.tasks.filters.programExtensionStatus',
                ),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionStatus?.value || []}
        />
        <Select
          data-cy="task-filter-modal-vital-collection-method"
          items={CollectionMethod.toVendorSelectable().sort(Enum.sort)}
          label={translate(
            'clinicalDashboard.tasks.filters.vitalCollectionMethod',
          )}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              vitalCollectionMethod: getFilterItem(
                translate(
                  'clinicalDashboard.tasks.filters.vitalCollectionMethod',
                ),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.vitalCollectionMethod?.value || []}
        />
      </FilterGroup>
    </Panel.FilterModal>
  );
};
