import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { ProgramExtensionStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient, ProgramExtension } from '@vestahealthcare/common/models';
import { CCMMemberDetailHeader } from '@vestahealthcare/common/models/CCMMemberDetailHeader';

import { Chip, Colors, Fonts } from 'styleguide-v2';

type Props = {
  className?: string;
  patient: Patient | CCMMemberDetailHeader;
};

const useStyles = makeStyles({
  chip: {
    '&&': {
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontFamily: 'Proxima Nova Condensed',
      letterSpacing: 1,
      margin: '0 0 0 1rem ',
      paddingBottom: '0.3rem',
    },
  },
  core: {
    backgroundColor: Colors.gold,
    borderColor: Colors.gold,
    color: Colors.textBlack,
  },
  lite: {
    backgroundColor: Colors.textRed2,
    borderColor: Colors.textRed2,
    color: Colors.white,
  },
  vpc: {
    backgroundColor: Colors.purple,
    borderColor: Colors.purple,
    color: Colors.white,
  },
  vpcShared: {
    backgroundColor: `${Colors.purple}26`,
    borderColor: `${Colors.purple}26`,
    color: Colors.purple,
  },
  test: {
    backgroundColor: Colors.pink,
    borderColor: Colors.pink,
    color: Colors.textBlack,
  },
});

export const VestaCoreLiteTag = ({ className, patient }: Props) => {
  const styles = useStyles();

  const hasCoreTag = !!patient.programExtensions?.find(
    ({ programExtension: { id }, status }) =>
      ProgramExtension.VESTA_CORE === id &&
      ProgramExtensionStatus.ACTIVE === status,
  );
  const hasLiteTag = !!patient.programExtensions?.find(
    ({ programExtension: { id }, status }) =>
      ProgramExtension.VESTA_LITE === id &&
      ProgramExtensionStatus.ACTIVE === status,
  );

  const hasVPCVestaTag = !!patient.activeReferrals?.find(
    ({ discontinuedAt, organization }) =>
      organization.isVPCVesta &&
      (!discontinuedAt || moment().isAfter(moment.unix(discontinuedAt))),
  );

  const hasVPCTag = !!patient.activeReferrals?.find(
    ({ discontinuedAt, organization }) =>
      organization.isVPC &&
      (!discontinuedAt || moment().isAfter(moment.unix(discontinuedAt))),
  );

  const getChipTitle = () => {
    if (hasCoreTag) return translate('memberProfile.header.coreTitle');
    if (hasLiteTag) return translate('memberProfile.header.liteTitle');
    if (hasVPCVestaTag) return translate('memberProfile.header.vpcVestaTitle');
    if (hasVPCTag) return translate('memberProfile.header.vpcTitle');
  };

  return (
    <>
      {patient.test && (
        <Chip
          className={classNames(className, styles.chip, styles.test)}
          title={translate('memberProfile.header.testTitle')}
        >
          {translate('memberProfile.header.test')}
        </Chip>
      )}
      {(hasCoreTag || hasLiteTag) && (
        <Chip
          className={classNames(
            className,
            styles.chip,
            hasLiteTag && styles.lite,
            !hasLiteTag && hasCoreTag && styles.core,
          )}
          title={getChipTitle()}
        >
          {!hasLiteTag && hasCoreTag && translate('memberProfile.header.core')}
          {hasLiteTag && translate('memberProfile.header.lite')}
        </Chip>
      )}

      {(hasVPCTag || hasVPCVestaTag) && (
        <Chip
          className={classNames(
            className,
            styles.chip,
            hasVPCVestaTag && styles.vpcShared,
            hasVPCTag && !hasVPCVestaTag && styles.vpc,
          )}
          title={getChipTitle()}
        >
          {!hasVPCVestaTag &&
            hasVPCTag &&
            translate('memberProfile.header.vpc')}
          {hasVPCVestaTag && translate('memberProfile.header.vpcShared')}
        </Chip>
      )}
    </>
  );
};
