import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import {
  DayOfWeek,
  Language,
  MemberContactTimeType,
  MemberRisk,
  ProgramExtensionStatus,
  RPMAdherenceLevel,
  States,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, Organization } from '@vestahealthcare/common/models';

import {
  Button,
  DateTimePicker,
  Label,
  SearchInput,
  Select,
  TextInput,
  ToggleDateRange,
  ToggleDateRangeHandle,
} from 'styleguide-v2';

import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import { GetCCMMemberParams } from 'dash/src/services/CCMServices';
import { transformDateToDaysRange } from 'dash/src/utils/dateUtils';
import { useDebounce } from 'dash/src/utils/debounceUtils';
import { getSafeValues } from 'dash/src/utils/filterUtils';

type Props = {
  className?: string;
  classNameItem?: string;
  disabled?: boolean;
  filters: GetCCMMemberParams;
  onChange: (filters: GetCCMMemberParams) => void;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles({
  dateRange: {
    '&&': {
      alignItems: 'inherit',
      width: '100%',

      '& > div': {
        width: '100%',

        '& .MuiButtonBase-root': {
          flex: 1,
        },
      },
    },
  },
  dateInput: {
    '&&': {
      marginTop: '0.5rem',
    },
  },
});

const UNASSIGNED = { label: translate('global.unassigned'), value: 0 };

const RISK_LEVEL_OPTION_NONE_VALUE = 'no-risk-level';
const RISK_LEVEL_OPTION_NONE_OPTION = {
  value: RISK_LEVEL_OPTION_NONE_VALUE,
  label: translate('memberBulkEdit.noRiskLevel'),
};
const RISK_LEVEL_OPTIONS = [
  RISK_LEVEL_OPTION_NONE_OPTION,
  ...Enum.toSelectable(MemberRisk.asArray),
];

const TIME_OPTION_UNKNOWN = {
  label: translate('global.unknown'),
  value: 'UNKNOWN',
};
const TIME_OPTIONS = [
  ...Enum.toSelectable([
    MemberContactTimeType.MORNING,
    MemberContactTimeType.AFTERNOON,
  ]),
  TIME_OPTION_UNKNOWN,
];

const PROGRAM_EXTENSION_UNASSIGNED = {
  label: translate('global.unassigned'),
  value: -1,
};

const VESTA_START_DATE_OPTION_BEFORE = 1;
const VESTA_START_DATE_OPTION_AFTER = 2;
const VESTA_START_DATE_OPTIONS = [
  { label: translate('global.before'), value: VESTA_START_DATE_OPTION_BEFORE },
  { label: translate('global.after'), value: VESTA_START_DATE_OPTION_AFTER },
];

const ATTEMPTS_OPTIONS = [
  { label: translate('global.none'), value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3+', value: 3 },
];

const MONTHLY_CCM_STATUSES = [
  {
    label: translate('ccm.common.monthlyCCMStatusCompleted'),
    value: true,
  },
  {
    label: translate('ccm.common.monthlyCCMStatusRemaining'),
    value: false,
  },
];

const CCM_STATUSES = [
  {
    label: translate('ccm.common.ccmStatusCompleted'),
    value: 'COMPLETED',
  },
  {
    label: translate('ccm.common.ccmStatusAttempted'),
    value: 'ATTEMPTED',
  },
  {
    label: translate('ccm.common.ccmStatusNotStarted'),
    value: 'NOT_STARTED',
  },
];

const BIANUAL_VV_STATUSES = [
  {
    label: translate('ccm.common.bianualVVCompleted'),
    value: true,
  },
  {
    label: translate('ccm.common.bianualVVNotCompleted'),
    value: false,
  },
];

interface SelectableOrganization extends Selectable {
  item?: Organization;
}

interface SelectableLanguage extends Selectable {
  preferred?: Language;
}

export const CCMFilters = ({
  className,
  classNameItem,
  disabled,
  filters,
  onChange,
  size,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const [activeRNs, setActiveRNs] = useState<Selectable[]>([]);
  const [activeNPs, setActiveNPs] = useState<Selectable[]>([]);
  const [activeEngagementOwners, setActiveEngagementOwners] = useState<
    Selectable[]
  >([]);
  const [activeHealthCoaches, setActiveHealthCoaches] = useState<Selectable[]>(
    [],
  );
  const [activeClinicalAssistants, setActiveClinicalAssistants] = useState<
    Selectable[]
  >([]);
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [carePlanGroups, setCarePlanGroups] = useState<Selectable[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<Selectable[]>([]);
  const [referralSources, setReferralSources] = useState<
    SelectableOrganization[]
  >([]);
  const [languages, setLanguages] = useState<SelectableLanguage[]>([]);
  const [reasons, setReasons] = useState<string[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);

  const [vestaStartDateAfter, setVestaStartDateAfter] = useState<number>();
  const [vestaStartDate, setVestaStartDate] = useState<Date>();

  const [totalMinutesFrom, setTotalMinutesFrom] = useState<number | undefined>(
    filters?.totalMinutesFrom,
  );
  const [totalMinutesTo, setTotalMinutesTo] = useState<number | undefined>(
    filters?.totalMinutesTo,
  );

  const [name, setName] = useState<string | undefined>(filters.memberName);

  const refLastCarePlan = useRef<ToggleDateRangeHandle>(null);

  const totalMinutesFromDebounce = useDebounce(totalMinutesFrom, 300);
  const totalMinutesToDebounce = useDebounce(totalMinutesTo, 300);
  const vestaStartDateDebounce = useDebounce(vestaStartDate, 600);

  const mapEmployees = (array: Employee[]) =>
    array
      .map(({ fullName: label, id: value }) => ({
        label,
        value,
      }))
      .sort(({ label: a }, { label: b }) => a.localeCompare(b));

  const getInitialData = async () => {
    setLoading(true);
    const [l, rns, nps, engs, hcs, cas, pes, cpgs, egs, o] = await Promise.all([
      fetchSupportedLanguages(),
      CacheServices.getRNs(),
      CacheServices.getNPs(),
      CacheServices.getEngagementOwners(),
      CacheServices.getHealthCoaches(),
      CacheServices.getClinicalAssistants(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getCarePlanGroups(),
      CacheServices.getEmployeeGroupsWorklist(),
      CacheServices.getOrganizations(),
    ]);

    const [reasons, brands] = await Promise.all([
      CacheServices.getCCMTouchpointReasons(),
      CacheServices.getOrganizationBrands(),
    ]);

    const activeRNs = mapEmployees(rns);
    const activeNPs = mapEmployees(nps);
    const activeEngagementOwners = mapEmployees(engs);
    const activeHealthCoaches = mapEmployees(hcs);
    const activeClinicalAssistants = mapEmployees(cas);

    setActiveRNs([UNASSIGNED, ...activeRNs]);
    setActiveNPs([UNASSIGNED, ...activeNPs]);
    setActiveEngagementOwners([UNASSIGNED, ...activeEngagementOwners]);
    setActiveHealthCoaches([UNASSIGNED, ...activeHealthCoaches]);
    setActiveClinicalAssistants([UNASSIGNED, ...activeClinicalAssistants]);

    setProgramExtensions([
      PROGRAM_EXTENSION_UNASSIGNED,
      ...pes.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    ]);

    setCarePlanGroups([
      UNASSIGNED,
      ...cpgs.map(({ id: value, description: label }) => ({
        value,
        label,
      })),
    ]);

    setEmployeeGroups([
      UNASSIGNED,
      ...egs.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    ]);

    setReferralSources(
      o.map((item) => ({
        label: item.name,
        value: item.name,
        item,
      })),
    );

    setLanguages([
      UNASSIGNED,
      ...Language.toSelectable(l).filter(({ disabled }) => !disabled),
    ]);

    setReasons(reasons);
    setBrands(
      brands.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );

    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (filters.referralSource && filters.referralSource.length > 1) {
      const lastAddedId =
        filters.referralSource[filters.referralSource.length - 1];
      const lastAdded = referralSources.find(
        ({ item }) => item?.name === lastAddedId,
      );
      const newOrganizations = filters.referralSource.filter(
        (child: string) => !lastAdded?.item?.hasDescendantByName(child),
      );
      if (newOrganizations.length < filters.referralSource.length) {
        onChange({ ...filters, referralSource: newOrganizations });
      }
    }
  }, [filters.referralSource]);

  const getStartDateValue = () => {
    if (filters.startDateFrom) return VESTA_START_DATE_OPTIONS[0];
    if (filters.startDateTo) return VESTA_START_DATE_OPTIONS[1];
  };

  useEffect(() => {
    let startDate: Moment | undefined;
    if (
      vestaStartDateDebounce &&
      moment(vestaStartDateDebounce.getTime()).isValid() &&
      moment().diff(moment(vestaStartDateDebounce.getTime()), 'years') < 10
    ) {
      startDate = moment(vestaStartDateDebounce.getTime());
    }

    if (
      !filters.startDateFrom &&
      !filters.startDateTo &&
      (!vestaStartDateDebounce || !startDate)
    )
      return;

    onChange({
      ...filters,
      startDateFrom:
        vestaStartDateAfter === VESTA_START_DATE_OPTION_BEFORE
          ? startDate
          : undefined,
      startDateTo:
        vestaStartDateAfter === VESTA_START_DATE_OPTION_AFTER
          ? startDate
          : undefined,
    });
  }, [vestaStartDateDebounce, vestaStartDateAfter]);

  const getSafeReferrals = (array?: SelectableOrganization[], ids?: string[]) =>
    ids
      ?.map((filterId) => array?.find((item) => item?.item?.name === filterId))
      .filter(Boolean) || [];

  const getDisabledReferral = (child: SelectableOrganization) =>
    !!(getSafeReferrals(
      referralSources,
      filters.referralSource,
    ) as SelectableOrganization[])?.reduce(
      (acc, item) =>
        acc || !!item.item?.hasDescendantByName(child.item?.name || ''),
      false,
    );

  useEffect(() => {
    onChange({
      ...filters,
      memberName: name,
      totalMinutesFrom: totalMinutesFromDebounce,
      totalMinutesTo: totalMinutesToDebounce,
    });
  }, [totalMinutesFromDebounce, totalMinutesToDebounce, name]);

  const doChange = (changes: Partial<GetCCMMemberParams>) => {
    onChange({
      ...filters,
      ...changes,
    });
  };

  return (
    <div className={className}>
      <Select
        allowAddNewValue
        className={classNameItem}
        data-cy="ccm-filter-id"
        inputType="number"
        items={[]}
        multiple
        onChange={(values: Selectable[]) =>
          doChange({
            ...filters,
            memberIds: values.map((v) => Number(v.label)),
          })
        }
        placeholder={translate('ccm.common.memberId')}
        value={filters.memberIds?.map((id) => ({ label: id }))}
        size={size}
      />
      <SearchInput
        className={classNameItem}
        data-cy="ccm-filter-name"
        label=""
        onSearch={setName}
        placeholder={translate('ccm.common.memberName')}
        value={name}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-rn-ownwer"
        disabled={disabled}
        items={activeRNs}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) => {
          doChange({
            ...filters,
            rnOwnerId: values.map(({ value }) => Number(value)),
          });
        }}
        placeholder={translate('ccm.common.rnOwner')}
        value={getSafeValues(activeRNs, filters.rnOwnerId)}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-np-ownwer"
        disabled={disabled}
        items={activeNPs}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) => {
          doChange({
            ...filters,
            npOwnerId: values.map(({ value }) => Number(value)),
          });
        }}
        placeholder={translate('ccm.common.npOwner')}
        value={getSafeValues(activeNPs, filters.npOwnerId)}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-health-coach"
        disabled={disabled}
        items={activeHealthCoaches || []}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(healthCoachId: Selectable[]) =>
          doChange({
            ...filters,
            healthCoachId: healthCoachId.map(({ value }) => value as number),
          })
        }
        placeholder={translate('ccm.common.healthCoach')}
        value={getSafeValues(activeHealthCoaches, filters.healthCoachId)}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-engagement-owner"
        disabled={disabled}
        items={activeEngagementOwners || []}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(engagementOwnerId: Selectable[]) =>
          doChange({
            ...filters,
            engagementOwnerId: engagementOwnerId.map(
              ({ value }) => value as number,
            ),
          })
        }
        placeholder={translate('ccm.common.engagementOwner')}
        value={getSafeValues(activeEngagementOwners, filters.engagementOwnerId)}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-clinical-assistant"
        disabled={disabled}
        items={activeClinicalAssistants || []}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(careCoordinatorId: Selectable[]) =>
          doChange({
            ...filters,
            careCoordinatorId: careCoordinatorId.map(
              ({ value }) => value as number,
            ),
          })
        }
        placeholder={translate('ccm.common.clinicalAssistant')}
        value={getSafeValues(
          activeClinicalAssistants,
          filters.careCoordinatorId,
        )}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filter-risk-level"
        disabled={disabled}
        items={RISK_LEVEL_OPTIONS}
        multiple
        onChange={(values: Selectable[]) => {
          doChange({
            ...filters,
            riskLevel: values
              .map((v) => MemberRisk.byKey[v.value])
              .filter(Boolean),
            hasRiskLevel: values?.length
              ? !values.find(
                  ({ value }) => value === RISK_LEVEL_OPTION_NONE_VALUE,
                )
              : undefined,
          });
        }}
        placeholder={translate('ccm.common.riskLevel')}
        value={[
          ...(filters.hasRiskLevel === false
            ? [RISK_LEVEL_OPTION_NONE_OPTION]
            : []),
          ...Enum.toSelectable(filters.riskLevel || []),
        ]}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filters-preferred-days"
        disabled={disabled}
        items={Enum.toSelectable(DayOfWeek.asArray)}
        multiple
        onChange={(newValue: Selectable[]) => {
          doChange({
            ...filters,
            contactDay: newValue?.length
              ? newValue
                  .map(({ value }) => DayOfWeek.byKey[value])
                  .filter(Boolean)
              : undefined,
          });
        }}
        placeholder={translate('ccm.common.contactPreferenceDate')}
        value={Enum.toSelectable(filters.contactDay || [])}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filters-preferred-time"
        disabled={disabled}
        items={TIME_OPTIONS}
        multiple
        onChange={(newValue: Selectable[]) => {
          doChange({
            ...filters,
            hasContactInfo: newValue?.length
              ? !newValue?.find(
                  ({ value }) => value === TIME_OPTION_UNKNOWN.value,
                )
              : undefined,
            contactTime: newValue?.length
              ? newValue
                  .map(({ value }) => MemberContactTimeType.byKey[value])
                  .filter(Boolean)
              : undefined,
          });
        }}
        placeholder={translate('ccm.common.contactPreferenceTime')}
        value={[
          ...(filters.hasContactInfo === false ? [TIME_OPTION_UNKNOWN] : []),
          ...Enum.toSelectable(filters.contactTime || []),
        ]}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filter-program-extensions"
        disabled={disabled}
        items={programExtensions}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) => {
          doChange({
            ...filters,
            hasProgramExtensions: values?.length
              ? !values.find((v) => v.value === -1)
              : undefined,
            programExtensionId: values
              .map((v) => v.value as number)
              .filter((id) => id !== -1),
          });
        }}
        placeholder={translate('ccm.common.programExtensions')}
        value={[
          ...(filters.hasProgramExtensions === false
            ? [PROGRAM_EXTENSION_UNASSIGNED]
            : []),
          ...getSafeValues(programExtensions, filters.programExtensionId),
        ]}
        size={size}
      />

      <Select
        className="grid-span-3"
        data-cy="ccm-filter-excluded-program-extensions"
        disabled={disabled}
        items={programExtensions}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            excludedProgramExtensionId: values
              .map((v) => v.value as number)
              .filter((id) => id !== -1),
          });
        }}
        placeholder={translate('ccm.common.excludedprogramExtensions')}
        value={getSafeValues(
          programExtensions,
          filters.excludedProgramExtensionId,
        )}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filter-program-extensions-status"
        disabled={disabled}
        items={ProgramExtensionStatus.getFilterSelectables()}
        limitTags={1}
        multiple
        onChange={(values: Selectable[]) => {
          const status = values.map(
            (v) => ProgramExtensionStatus.byKey[v.value],
          );
          doChange({
            ...filters,
            programExtensionStatus: status,
          });
        }}
        placeholder={translate('ccm.common.programExtensionStatus')}
        value={Enum.toSelectable(filters.programExtensionStatus || [])}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-care-plan-group"
        disabled={disabled}
        items={carePlanGroups}
        multiple
        onChange={(carePlanGroup: Selectable[]) => {
          doChange({
            ...filters,
            carePlanGroupId: carePlanGroup?.map(({ value }) => value as number),
          });
        }}
        placeholder={translate('ccm.common.carePlanGroup')}
        value={carePlanGroups.filter(({ value }) =>
          filters.carePlanGroupId?.includes(value as number),
        )}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filter-employee-group"
        disabled={disabled}
        items={employeeGroups}
        limitTags={1}
        multiple
        onChange={(employeeGroup: Selectable[]) => {
          doChange({
            ...filters,
            worklistGroupId: employeeGroup?.map(({ value }) => value as number),
          });
        }}
        placeholder={translate('ccm.common.employeeGroup')}
        value={employeeGroups.filter(({ value }) =>
          filters.worklistGroupId?.includes(value as number),
        )}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filter-referral-sources"
        disabled={disabled}
        getItemDisabled={getDisabledReferral}
        items={referralSources}
        multiple
        onChange={(values: SelectableOrganization[]) => {
          let referralSource = values.map((v) => v.value as string);
          values.forEach((referral) => {
            if (referral.item?.children?.length) {
              referralSource = [
                ...referralSource,
                ...referral.item.children.map(({ name }) => name),
              ];
            }
          });
          doChange({
            ...filters,
            referralSource,
          });
        }}
        placeholder={translate('ccm.common.referralSources')}
        value={getSafeReferrals(referralSources, filters.referralSource).filter(
          (child) => child && !getDisabledReferral(child),
        )}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-state"
        disabled={disabled}
        items={Enum.toSelectable(States.asArray)}
        multiple
        onChange={(state: Selectable[]) =>
          doChange({
            ...filters,
            state: state
              .map(({ value }) => States.byKey[value])
              .filter(Boolean),
          })
        }
        placeholder={translate('ccm.common.state')}
        value={Enum.toSelectable(filters?.state || [])}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-language"
        disabled={disabled}
        grouped={({ preferred }) => {
          if (preferred === true) return 'Preferred';
          if (preferred === false) return 'Other';
          return '';
        }}
        items={languages}
        loading={loading}
        multiple
        onChange={(language: Selectable[]) => {
          doChange({
            ...filters,
            hasLanguage: language?.length
              ? !language?.find(({ value }) => value === UNASSIGNED.value)
              : undefined,
            language: language
              ?.map(({ value }) => Language.byKey[value])
              .filter(Boolean),
          });
        }}
        placeholder={translate('ccm.common.language')}
        value={[
          ...(filters.hasLanguage === false ? [UNASSIGNED] : []),
          ...Enum.toSelectable(filters.language || []),
        ]}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="tasks-filter-brand"
        items={brands}
        limitTags={1}
        multiple
        onChange={(brand?: Selectable[]) => {
          onChange({
            ...filters,
            brandId: brand?.map(({ value }) => value as string),
          });
        }}
        placeholder={translate('ccm.common.brand')}
        size="small"
        value={brands.filter(({ value }) =>
          filters.brandId?.includes(value as string),
        )}
      />

      <Select
        className={classNameItem}
        data-cy="tasks-filter-rpm-adherence"
        items={Enum.toSelectable(RPMAdherenceLevel.asArray)}
        limitTags={1}
        multiple
        onChange={(rpmAdherences?: Selectable[]) => {
          onChange({
            ...filters,
            rpmAdherenceId: rpmAdherences?.map(({ value }) => value as string),
          });
        }}
        placeholder={translate('ccm.common.rpmAdherence')}
        size="small"
        value={Enum.toSelectable(
          RPMAdherenceLevel.asArray,
        ).filter(({ value }) =>
          filters.rpmAdherenceId?.includes(value as string),
        )}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filter-touchpoint-resasons"
        disabled={disabled}
        items={reasons}
        getItemLabel={(x) => x}
        loading={loading}
        multiple
        onChange={(items: string[]) =>
          doChange({
            ...filters,
            touchPointReason: items,
          })
        }
        placeholder={translate('ccm.common.touchpointReasons')}
        value={filters.touchPointReason || []}
        size={size}
      />
      <Select
        className={classNameItem}
        data-cy="ccm-filter-attempts"
        disabled={disabled}
        items={ATTEMPTS_OPTIONS}
        onChange={(item: Selectable) =>
          doChange({
            ...filters,
            numberOfCalls: item?.value as number,
          })
        }
        placeholder={translate('ccm.common.attempts')}
        value={ATTEMPTS_OPTIONS.find(
          ({ value }) => filters.numberOfCalls === value,
        )}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filters-care-team-schedule-days"
        disabled={disabled}
        items={Enum.toSelectable(DayOfWeek.asArray)}
        multiple
        onChange={(newValue: Selectable[]) => {
          doChange({
            ...filters,
            aideScheduleDay: newValue?.length
              ? newValue
                  .map(({ value }) => DayOfWeek.byKey[value])
                  .filter(Boolean)
              : undefined,
          });
        }}
        placeholder={translate('ccm.common.aideScheduleDays')}
        value={Enum.toSelectable(filters.aideScheduleDay || [])}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filters-care-team-schedule-time"
        disabled={disabled}
        items={TIME_OPTIONS}
        multiple
        onChange={(newValue: Selectable[]) => {
          doChange({
            ...filters,
            hasAideScheduleInfo: newValue?.length
              ? !newValue?.find(
                  ({ value }) => value === TIME_OPTION_UNKNOWN.value,
                )
              : undefined,
            aideScheduleTime: newValue?.length
              ? newValue
                  .map(({ value }) => MemberContactTimeType.byKey[value])
                  .filter(Boolean)
              : undefined,
          });
        }}
        placeholder={translate('ccm.common.aideScheduleTime')}
        value={[
          ...(filters.hasAideScheduleInfo === false
            ? [TIME_OPTION_UNKNOWN]
            : []),
          ...Enum.toSelectable(filters.aideScheduleTime || []),
        ]}
        size={size}
      />

      <Select.Choice
        className={classNameItem}
        data-cy="ccm-filters-hasValidCCM"
        disabled={disabled}
        onChange={(hasValidCCM?: boolean) => {
          doChange({
            ...filters,
            hasValidCCM,
          });
        }}
        placeholder={translate('ccm.common.hasValidCCM')}
        value={filters.hasValidCCM}
        size={size}
      />

      <Select.Choice
        className={classNameItem}
        data-cy="ccm-filters-initialCP"
        disabled={disabled}
        onChange={(initialCPDue?: boolean) => {
          doChange({
            ...filters,
            initialCPDue,
          });
        }}
        placeholder={translate('ccm.common.initialCP')}
        value={filters.initialCPDue}
        size={size}
      />

      <Select.Choice
        className={classNameItem}
        data-cy="ccm-filters-carePlanDue"
        disabled={disabled}
        items={[
          {
            label: translate('ccm.common.carePlanDueYes'),
            value: true,
          },
          {
            label: translate('ccm.common.carePlanDueNo'),
            value: false,
          },
        ]}
        onChange={(carePlanDue?: boolean) => {
          doChange({
            ...filters,
            carePlanDue,
          });
        }}
        placeholder={translate('ccm.common.carePlanDue')}
        value={filters.carePlanDue}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filters-careTeamDiscovery"
        disabled={disabled}
        items={[{ label: translate('global.yes'), value: true }]}
        onChange={(careTeamDiscoveryDue?: { value: boolean }) => {
          doChange({
            ...filters,
            careTeamDiscoveryDue: careTeamDiscoveryDue?.value as boolean,
          });
        }}
        placeholder={translate('ccm.common.careTeamDiscovery')}
        value={
          filters.careTeamDiscoveryDue
            ? { label: translate('global.yes'), value: true }
            : undefined
        }
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filters-monthly-ccm-status"
        disabled={disabled}
        items={MONTHLY_CCM_STATUSES}
        onChange={(isComplete?: { value: boolean }) => {
          doChange({
            ...filters,
            isComplete: isComplete?.value,
          });
        }}
        placeholder={translate('ccm.common.monthlyCCMStatus')}
        value={MONTHLY_CCM_STATUSES.find(
          ({ value }) => value === filters.isComplete,
        )}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filters-ccm-status"
        disabled={disabled}
        items={CCM_STATUSES}
        limitTags={1}
        multiple
        onChange={(ccmCallStatus?: Selectable[]) => {
          doChange({
            ...filters,
            ccmCallStatus: ccmCallStatus?.map(({ value }) => value as string),
          });
        }}
        placeholder={translate('ccm.common.ccmStatus')}
        value={CCM_STATUSES.filter(({ value }) =>
          filters.ccmCallStatus?.includes(value),
        )}
        size={size}
      />

      <Select
        className={classNameItem}
        data-cy="ccm-filters-bianual-vv-status"
        disabled={disabled}
        items={BIANUAL_VV_STATUSES}
        onChange={(completed?: { value: boolean }) => {
          doChange({
            ...filters,
            isBiannualComplete: completed?.value,
          });
        }}
        placeholder={translate('ccm.common.bianualVVStatus')}
        value={BIANUAL_VV_STATUSES.find(
          ({ value }) => value === filters.isBiannualComplete,
        )}
        size={size}
      />

      <Select.Choice
        className={classNameItem}
        data-cy="ccm-filters-hasProfessionalTime"
        disabled={disabled}
        onChange={(hasProfessionalTime?: boolean) => {
          doChange({
            ...filters,
            hasProfessionalTime,
          });
        }}
        placeholder={translate('ccm.common.hasProfessionalTime')}
        value={filters.hasProfessionalTime}
        size={size}
      />

      <ToggleDateRange
        className={classNames(classNameItem, styles.dateRange)}
        data-cy="ccm-last-care-plan"
        disabled={disabled}
        defaultValue={
          filters.carePlanDateFrom
            ? transformDateToDaysRange(
                filters.carePlanDateFrom?.toDate(),
                new Date(),
                [7, 30, 90],
              )
            : undefined
        }
        items={[-1, 7, 30, 90]}
        label={translate('ccm.common.lastCarePlan')}
        onChange={(from, to) => {
          if (from && to) {
            doChange({
              ...filters,
              carePlanDateFrom: moment(from.getTime()),
            });
          } else {
            doChange({
              ...filters,
              carePlanDateFrom: undefined,
            });
          }
        }}
        ref={refLastCarePlan}
      />

      <div
        className={`${classNameItem} grid-wrapper`}
        style={{ gap: '0 1rem' }}
      >
        <Label className="grid-span-12">
          {translate('ccm.common.vestaStartDate')}
        </Label>
        <Select
          className="grid-span-6"
          data-cy="ccm-filters-cg-schedule-time"
          disabled={disabled}
          items={VESTA_START_DATE_OPTIONS}
          onChange={(item?: Selectable) =>
            setVestaStartDateAfter(item?.value as number | undefined)
          }
          value={getStartDateValue()}
          size={size}
        />

        <DateTimePicker
          className={`grid-span-6 ${styles.dateInput}`}
          dateFormat="M/d/yy"
          disabled={disabled || (!vestaStartDateAfter && !getStartDateValue())}
          maxDate={Date.now()}
          onChange={setVestaStartDate}
          value={
            filters.startDateFrom?.toDate() || filters.startDateTo?.toDate()
          }
          size={size}
        />
      </div>

      <div
        className={`${classNameItem} grid-wrapper`}
        style={{ gap: '0 1rem' }}
      >
        <Label className="grid-span-12">
          {translate('ccm.common.totalMinutes')}
        </Label>
        <TextInput
          className="grid-span-6"
          disabled={disabled}
          maxLength={4}
          onChange={(newValue?: string) => {
            const number =
              newValue !== undefined ? parseInt(newValue, 10) : NaN;
            setTotalMinutesFrom(isNaN(number) ? undefined : number);
          }}
          placeholder={translate('global.from')}
          value={
            filters.totalMinutesFrom !== undefined
              ? String(filters.totalMinutesFrom)
              : undefined
          }
          size={size}
        />
        <TextInput
          className="grid-span-6"
          disabled={disabled}
          maxLength={4}
          onChange={(newValue?: string) => {
            const number =
              newValue !== undefined ? parseInt(newValue, 10) : NaN;
            setTotalMinutesTo(isNaN(number) ? undefined : number);
          }}
          placeholder={translate('global.to')}
          value={
            filters.totalMinutesTo !== undefined
              ? String(filters.totalMinutesTo)
              : undefined
          }
          size={size}
        />
      </div>

      <Select.Choice
        className={classNameItem}
        data-cy="ccm-filters-unreachable"
        disabled={disabled}
        label={translate('ccm.common.unreachable')}
        onChange={(isUnreachable?: boolean) => {
          doChange({
            ...filters,
            isUnreachable: isUnreachable || undefined,
          });
        }}
        value={filters.isUnreachable || false}
        size={size}
      />

      <div className="grid-span-9" />
      <Button
        className={classNameItem}
        data-cy="ccm-clear-filters"
        disabled={disabled}
        color="secondary"
        type="outlined"
        onClick={() => {
          setVestaStartDate(undefined);
          setVestaStartDateAfter(undefined);
          onChange({});
          setTimeout(() => refLastCarePlan.current?.init(), 0);
        }}
        size="s"
      >
        {translate('global.clearFilters')}
      </Button>
    </div>
  );
};
