import moment, { Moment } from 'moment';
import React from 'react';

import { IssueDetailFieldType } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import IssueDetailValue from '@vestahealthcare/common/models/IssueDetailValue';
import {
  DATE_FORMAT,
  SERVER_DATE_FORMAT_ZONED,
  SERVER_DATE_TIME_FORMAT_ZONED,
} from '@vestahealthcare/common/utils/constants';

import {
  DateTimePicker,
  Select,
  SwitchGroup,
  TextArea,
  TextInput,
} from 'styleguide-v2';
import { SwitchItem } from 'styleguide-v2/src/components/Switch/SwitchGroup';

type Props = {
  className?: string;
  items?: Selectable[];
  label?: string;
  onChange: (value?: string | number | boolean | null) => void;
  required?: boolean;
  submitted?: boolean;
  tooltip?: string | number | boolean | null;
  type: IssueDetailFieldType;
  value?: string | number | boolean | null;
};

const TOOLTIP_WIDTH = 20;

export const EventCustomField = ({
  className,
  items,
  label,
  onChange,
  required,
  submitted,
  tooltip,
  type,
  value,
}: Props) => {
  if (type === IssueDetailFieldType.TEXT) {
    return (
      <TextInput
        className={className}
        error={submitted && required && !value}
        label={label}
        onChange={onChange}
        required={required}
        tooltip={
          tooltip && (value as string)?.length > TOOLTIP_WIDTH
            ? (tooltip as string)
            : undefined
        }
        value={value as string}
      />
    );
  }

  if (type === IssueDetailFieldType.TEXT_AREA) {
    return (
      <TextArea
        className={className}
        error={submitted && required && !value}
        label={label}
        maxRows={5}
        onChange={onChange}
        required={required}
        value={value as string}
      />
    );
  }

  if (type === IssueDetailFieldType.DATE) {
    return (
      <DateTimePicker
        className={className}
        error={submitted && required && !value}
        label={label}
        onChange={(val) =>
          onChange(val ? moment(val.getTime()).startOf('day').format() : null)
        }
        required={required}
        value={
          value ? moment(value as string, DATE_FORMAT).toDate() : undefined
        }
      />
    );
  }

  if (type === IssueDetailFieldType.DATE_TIME) {
    const hasTime = IssueDetailValue.hasTime(type, value as string);
    const date = value
      ? moment(
          value as string,
          hasTime ? SERVER_DATE_TIME_FORMAT_ZONED : DATE_FORMAT,
        )
      : undefined;
    const time =
      value && hasTime
        ? moment(value as string, SERVER_DATE_TIME_FORMAT_ZONED)
        : undefined;
    const labelSplitted = label?.split(' ')[0];
    return (
      <>
        <DateTimePicker
          className={className}
          error={submitted && (required || time) && !date}
          label={`${labelSplitted} date`}
          onChange={(val) => {
            let newDate: Moment | undefined;
            if (val) {
              newDate = moment(val.getTime());
              if (hasTime && time) {
                newDate
                  .hours(time?.hours())
                  .minutes(time?.minutes())
                  .seconds(0);
              }
            }
            onChange(
              newDate?.format(
                hasTime
                  ? SERVER_DATE_TIME_FORMAT_ZONED
                  : SERVER_DATE_FORMAT_ZONED,
              ),
            );
          }}
          required={required || !!time}
          value={date?.toDate()}
        />
        <DateTimePicker
          className={className}
          interval={5}
          label={`${labelSplitted} time`}
          onChange={(val) => {
            let newDate = date;
            if (val) {
              const newTime = moment(val.getTime());
              newDate = (newDate || moment())
                .hours(newTime?.hours())
                .minutes(newTime?.minutes())
                .seconds(0);
            }
            onChange(newDate?.format());
          }}
          type="time"
          value={time?.toDate()}
        />
      </>
    );
  }

  if (
    type === IssueDetailFieldType.SELECT ||
    type === IssueDetailFieldType.SKILLED_AGENCY
  ) {
    const currentValue = items?.find(({ value: val }) => value === val);
    const currentItems = items?.filter(({ disabled }) => !disabled) || [];
    return (
      <Select
        className={className}
        error={submitted && required && !currentValue}
        items={currentItems}
        multiple={type === IssueDetailFieldType.MULTI_SELECT}
        label={label}
        onChange={(val: Selectable) => onChange(val ? val.value : undefined)}
        required={required}
        value={currentValue}
      />
    );
  }

  if (type === IssueDetailFieldType.MULTI_SELECT) {
    const valSplitted = (value as string)?.split(',');
    const currentValue = items?.filter(
      ({ value: val }) =>
        valSplitted && valSplitted.indexOf(val as string) !== -1,
    );
    const currentItems = items?.filter(({ disabled }) => !disabled) || [];
    return (
      <Select
        className={className}
        error={submitted && required && !currentValue?.length}
        items={currentItems}
        limitTags={1}
        label={label}
        multiple
        onChange={(val: Selectable[]) =>
          onChange(
            val?.length ? val.map(({ value }) => value).join(',') : undefined,
          )
        }
        required={required}
        value={currentValue}
      />
    );
  }

  if (type === IssueDetailFieldType.TOGGLE) {
    return (
      <SwitchGroup
        className={className}
        items={[{ label, checked: value === 'true' }]}
        onChange={(val: SwitchItem[]) =>
          onChange(val[0].checked ? 'true' : 'false')
        }
      />
    );
  }

  return (
    <p>
      {label}: Type {type.value} not implemented yet
    </p>
  );
};

export default EventCustomField;
