import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import makeStyles from '@mui/styles/makeStyles';

import { EventReporterType } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CareTeamMember,
  EventDetail,
  EventType,
  IssueDetailFieldGroup,
  IssueType,
  MemberCommunityResource,
  Patient,
  SourceChannel,
} from '@vestahealthcare/common/models';
import { EventIssue } from '@vestahealthcare/common/models/EventIssue';

import {
  Button,
  CheckboxWithLabel,
  CollapsableSidebar,
  Colors,
  DateTimePicker,
  Label,
  PanelInfo,
  Select,
  TextArea,
  TextInput,
} from 'styleguide-v2';
import { SelectHandle } from 'styleguide-v2/src/components/Select/Select';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CareTeamQuickAddModal } from 'dash/src/pages/CareTeam/modals/CareTeamQuickAdd';
import { onCareTeamMembersUpdated } from 'dash/src/redux/slices/MemberInfoSlice';
import { CacheServices } from 'dash/src/services';
import {
  fetchMemberCareTeamMembers,
  quickAddCareTeam,
} from 'dash/src/services/CareTeamServices';
import {
  CreateEventParams,
  IssueDetail,
} from 'dash/src/services/EventServices';

import { EventDetailIssues } from '../components/EventDetailIssues';
import { EventReporter } from '../components/EventReporter';
import { MemberEventEditIssue } from './MemberEventEditIssue';

interface Props {
  event?: EventDetail;
  issues?: EventIssue[];
  open: boolean;
  onClose: () => void;
  onSubmit: (
    params: CreateEventParams,
    event?: EventDetail,
    issues?: EventIssue[],
  ) => Promise<boolean>;
  patient?: Patient;
}

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    gap: '0.5rem',
    flexFlow: 'row',
    flexWrap: 'wrap',
  },
  documentation: {
    marginLeft: '-0.75rem',
  },
  noMarginTop: {
    marginTop: '-3rem',
  },
  note: {
    color: Colors.iconGray,
    fontSize: '0.875em',
    fontStyle: 'italic',
    marginBottom: '1rem',
    marginRight: '0.25rem',
  },
  unknownTime: {
    alignItems: 'flex-end',
    height: '4rem',
    position: 'relative',
    '& label': {
      margin: 0,
      '& > span:first-child': {
        padding: '0.5rem',
      },
    },
  },
  unknownTimeRequired: {
    top: '-2.25rem',
  },
});

export const MemberEventAddModal = ({
  event,
  issues,
  onClose,
  onSubmit,
  open,
  patient,
}: Props) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const eventTypeRef = useRef<SelectHandle>(null);

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openAddIssue, setOpenAddIssue] = useState(false);
  const [openAddCTM, setOpenAddCTM] = useState<boolean>(false);

  const [chiefComplaint, setChiefComplaint] = useState<string>();
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [eventType, setEventType] = useState<EventType>();
  const [issueTypes, setIssueTypes] = useState<IssueType[]>([]);
  const [issueType, setIssueType] = useState<IssueDetail>({});
  const [issueTypeValue, setIssueTypeValue] = useState<Selectable>();
  const [eventDate, setEventDate] = useState<Date>(new Date());
  const [eventTime, setEventTime] = useState<Date>();
  const [isTimeUnknown, setUnkownTime] = useState<boolean>(false);
  const [isDocumentationOnly, setDocumentationOnly] = useState<boolean>(false);
  const [eventSources, setEventSources] = useState<SourceChannel[]>([]);
  const [eventSource, setEventSource] = useState<SourceChannel>();
  const [eventSourceOther, setEventSourceOther] = useState<string>();
  const [eventDirections, setEventDirections] = useState<BaseEnum[]>([]);
  const [eventDirection, setEventDirection] = useState<BaseEnum>();
  const [reporterType, setReporterType] = useState(EventReporterType.MEMBER);
  const [careTeamMember, setCareTeamMember] = useState<CareTeamMember>();
  const [communityResource, setCommunityResource] = useState<
    MemberCommunityResource
  >();

  const [issueDetailFields, setIssueDetailFields] = useState<
    IssueDetailFieldGroup[]
  >([]);
  const [issueModalFields, setIssueModalFields] = useState<
    IssueDetailFieldGroup[]
  >([]);
  const [issueModal, setIssueModal] = useState<EventIssue>();

  const getInitialData = async () => {
    setLoadingData(true);
    const [
      eventTypes,
      issueTypes,
      eventSources,
      issueDetailFields,
      directions,
    ] = await Promise.all([
      await CacheServices.getEventTypes(),
      await CacheServices.getIssueTypes(),
      await CacheServices.getSourceChannels(),
      await CacheServices.getIssueDetailFields(),
      await CacheServices.getSourceChannelDirections(),
    ]);

    setEventTypes(eventTypes?.filter(({ active }) => active));
    setIssueTypes(issueTypes);
    setEventSources(
      eventSources.filter(({ isManual }: SourceChannel) => isManual),
    );
    setIssueDetailFields(issueDetailFields);
    setEventDirections(directions);
    setLoadingData(false);
  };

  useEffect(() => {
    if (open && !eventTypes?.length) {
      getInitialData();
    }
  }, [open]);

  const reset = () => {
    setSubmitted(false);
    setChiefComplaint(event?.chiefComplaint);
    setEventType(event?.type);
    setIssueType({});
    setEventDate(new Date());
    setEventTime(undefined);
    setUnkownTime(false);
    setDocumentationOnly(false);
    setEventSource(undefined);
    setEventDirection(undefined);
    setEventSourceOther('');
    setReporterType(EventReporterType.MEMBER);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const validate = () =>
    chiefComplaint &&
    eventType &&
    (event || Object.keys(issueType || {}).length !== 0) &&
    eventDate &&
    (eventTime || isTimeUnknown) &&
    (event || eventSource) &&
    (event || eventDirection) &&
    (event ||
      ((!eventSource?.isOther() || eventSourceOther) &&
        (reporterType !== EventReporterType.CARE_TEAM || careTeamMember) &&
        (reporterType !== EventReporterType.COMMUNITY_RESOURCE ||
          communityResource)));

  const doSubmit = async () => {
    setSubmitted(true);
    if (patient && chiefComplaint && eventType && validate()) {
      setLoading(true);
      const dateTime = moment(eventDate.getTime());
      if (isTimeUnknown) {
        dateTime.utcOffset(0, true).hours(12).minutes(0).seconds(0);
      } else {
        dateTime
          .hours(eventTime?.getHours() || 12)
          .minutes(eventTime?.getMinutes() || 0)
          .seconds(0);
      }

      try {
        const result = await onSubmit(
          {
            chiefComplaint,
            typeId: eventType,
            issues: issueType,
            dateTime: dateTime.format(),
            documentationOnly: isDocumentationOnly,
            hasTime: !isTimeUnknown,
            memberId: patient?.id,
            reporterCareTeamMember:
              reporterType === EventReporterType.CARE_TEAM
                ? careTeamMember?.id
                : undefined,
            reporterCommunityResource:
              reporterType === EventReporterType.COMMUNITY_RESOURCE
                ? communityResource?.id
                : undefined,
            reporterMember:
              reporterType === EventReporterType.MEMBER
                ? patient?.id
                : undefined,
            source: eventSource,
            direction: eventDirection,
            sourceOther: eventSourceOther,
          },
          event,
          issues,
        );
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  const checkTypeHasExtraFields = (its?: IssueType[]) => {
    if (its?.length) {
      const it = its && its[its.length - 1];
      if (it) {
        const extraFields = issueDetailFields?.filter(
          ({ issueTypeId }) => issueTypeId === it.id,
        );
        if (extraFields?.length) {
          setIssueModal(new EventIssue({ issueType: it }));
          setIssueModalFields(extraFields);
          setOpenAddIssue(true);
        } else {
          issueType[it.id] = [];
          setIssueType({ ...issueType });
        }
      }
    } else {
      setIssueType({});
    }
    setIssueTypeValue({
      value: Number(issueTypeValue?.value || 0) + 1,
    } as Selectable);
  };

  const editExtraFieldType = (it?: IssueType) => {
    if (it) {
      const extraFields = issueDetailFields?.filter(
        ({ issueTypeId }) => issueTypeId === it.id,
      );
      if (extraFields?.length) {
        setIssueModal(
          new EventIssue({
            issueType: it,
            details: issueType[it.id].map(
              ({ issueDetailFieldId: id, value }) => ({
                issueDetailField: {
                  id,
                },
                value,
              }),
            ),
          }),
        );
        setIssueModalFields(extraFields);
        setOpenAddIssue(true);
      }
    }
  };

  const issueTypesFiltered = useMemo(
    () =>
      issueTypes.filter(
        ({ id, eventType: issueET }) =>
          issueET?.id === eventType?.id && !issueType[id],
      ),
    [issueTypes, eventType],
  );

  const fillIssueType = () => {
    if (issueTypesFiltered?.length === 1) {
      checkTypeHasExtraFields(issueTypesFiltered);
    }
  };

  useEffect(() => {
    fillIssueType();
  }, [eventType]);

  return (
    <>
      <CollapsableSidebar
        onClose={onClose}
        open={open}
        title={
          <h2>
            {event
              ? translate('memberEvents.unlinkModalTitle', {
                  count: issues?.length,
                })
              : translate('memberEvents.addModalTitle')}
          </h2>
        }
        size={550}
      >
        <CollapsableSidebar.Body>
          {event && (
            <PanelInfo
              type="warning"
              title={translate('memberEvents.unlinkAlert')}
            />
          )}
          <TextArea
            error={submitted && !chiefComplaint}
            label={translate('memberEvents.chiefComplaint')}
            onChange={setChiefComplaint}
            required
            rows={3}
            value={chiefComplaint}
          />

          <div className="grid-wrapper fit">
            <Select
              className={event ? 'grid-span-12' : 'grid-span-6'}
              error={submitted && !eventType}
              getItemLabel={({ name }: EventType) => name}
              items={eventTypes}
              label={translate('memberEvents.event')}
              loading={loadingData}
              onChange={(val: EventType) => {
                setEventType(val);
                setIssueType({});
              }}
              required
              value={eventType}
              ref={eventTypeRef}
            />
            {!event && (
              <Select
                className="grid-span-6"
                disabled={!eventType}
                disableClearItem
                error={submitted && !Object.keys(issueType)?.length}
                getItemLabel={({ description }: IssueType) => (
                  <span className="flex min-gap">
                    <span className="ellipsis">{description}</span>
                    <ArrowForwardIcon
                      sx={{ color: '#000', fontSize: '14px!important' }}
                    />
                  </span>
                )}
                items={issueTypesFiltered}
                label={translate('memberEvents.issueType')}
                limitTags={1}
                loading={loadingData}
                multiple
                onChange={checkTypeHasExtraFields}
                onClickItem={editExtraFieldType}
                renderOption={({ description }: IssueType) => description}
                required
                value={
                  issueTypesFiltered?.filter(({ id }) =>
                    Object.keys(issueType)?.includes(id),
                  ) || []
                }
              />
            )}
            <span className={classnames('grid-span-12', styles.note)}>
              {translate('memberEvents.issueTypeNote')}
            </span>
          </div>

          <div className="grid-wrapper fit no-vertical-gap">
            <DateTimePicker
              className="grid-span-6"
              error={submitted && !eventDate}
              label={translate('memberEvents.eventDate')}
              maxDate={new Date()}
              onChange={setEventDate}
              required
              type="date"
              value={eventDate}
            />
            <DateTimePicker
              className="grid-span-6"
              disabled={isTimeUnknown}
              error={submitted && !isTimeUnknown && !eventTime}
              label={translate('memberEvents.eventTime')}
              maxTime={
                eventDate && moment(eventDate.getTime()).isSame(moment(), 'day')
                  ? new Date()
                  : undefined
              }
              onChange={setEventTime}
              required={!isTimeUnknown}
              type="time"
              value={eventTime}
            />
            <CheckboxWithLabel
              className={classnames(
                'grid-span-12',
                styles.unknownTime,
                submitted &&
                  ((!isTimeUnknown && !eventTime) || !eventDate) &&
                  styles.unknownTimeRequired,
              )}
              label={translate('memberEvents.unknownTime')}
              checked={isTimeUnknown}
              onChange={(val) => setUnkownTime(!!val)}
            />
            <div className="grid-span-12" />
          </div>

          {!event && (
            <div className={classnames('grid-wrapper fit', styles.noMarginTop)}>
              <Select
                className={
                  eventSource?.isOther() ? 'grid-span-6' : 'grid-span-12'
                }
                error={submitted && !eventSource}
                getItemLabel={({ description }: SourceChannel) => description}
                items={eventSources}
                label={translate('memberEvents.method')}
                loading={loadingData}
                onChange={setEventSource}
                required
                value={eventSource}
              />
              {eventSource?.isOther() && (
                <TextInput
                  className="grid-span-6"
                  error={submitted && !eventSourceOther}
                  label={translate('memberEvents.methodOther')}
                  onChange={setEventSourceOther}
                  required
                  value={eventSourceOther}
                />
              )}
              <Select
                className="grid-span-12"
                error={submitted && !eventDirection}
                getItemLabel={({ description }: BaseEnum) => description}
                items={eventDirections}
                label={translate('memberEvents.direction')}
                loading={loadingData}
                onChange={setEventDirection}
                required
                value={eventDirection}
              />
            </div>
          )}
          {!event && (
            <EventReporter
              addCareTeamMember
              onAddCareTeamMember={() => setOpenAddCTM(true)}
              onChange={(type, reporter) => {
                setReporterType(type);
                if (type === EventReporterType.CARE_TEAM) {
                  setCareTeamMember(reporter as CareTeamMember);
                } else if (type === EventReporterType.COMMUNITY_RESOURCE) {
                  setCommunityResource(reporter as MemberCommunityResource);
                }
              }}
              patient={patient}
              submitted={submitted}
            />
          )}
          {!!issues?.length && (
            <div className={styles.noMarginTop}>
              <EventDetailIssues issues={issues} />
            </div>
          )}
          <div className="grid-span-12">
            <Label>{translate('memberEvents.modals.status')}</Label>
            <CheckboxWithLabel
              className={styles.documentation}
              label={translate('memberEvents.documentationOnly')}
              note={translate('memberEvents.documentationOnlyNote')}
              checked={isDocumentationOnly}
              onChange={(val) => setDocumentationOnly(!!val)}
            />
          </div>
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button color="tertiary" data-cy="edit-event-close" onClick={onClose}>
            {translate('global.cancel')}
          </Button>
          <Button
            color="secondary"
            data-cy="edit-event-submit"
            disabled={!eventType}
            loading={loading}
            onClick={doSubmit}
          >
            {eventType?.id === EventType.FALL && translate('global.next')}
            {eventType?.id !== EventType.FALL &&
              event &&
              translate('memberEvents.createEvent')}
            {eventType?.id !== EventType.FALL &&
              !event &&
              translate('global.add')}
          </Button>
        </CollapsableSidebar.Buttons>
        <MemberEventEditIssue
          open={openAddIssue}
          issue={issueModal}
          issueFields={issueModalFields}
          onClose={() => setOpenAddIssue(false)}
          onSubmit={async (issue, { details }) => {
            if (issue && details) {
              issueType[issue.issueType.id] = details;
              setIssueType({ ...issueType });
            }
            eventTypeRef.current?.close();
            return true;
          }}
        />
      </CollapsableSidebar>
      <CareTeamQuickAddModal
        hideExtraButtons
        open={openAddCTM}
        onClose={() => setOpenAddCTM(false)}
        onSubmit={async (params) => {
          let ctm: CareTeamMember | undefined;
          const patientId = patient instanceof Patient ? patient.id : patient;
          try {
            if (patientId) {
              ctm = await quickAddCareTeam(params);
              const { items } = await fetchMemberCareTeamMembers(patientId);
              dispatch(onCareTeamMembersUpdated(items));
              setOpenAddCTM(false);
            }
          } catch (e) {
            showGlobalError(e as string);
          }
          return ctm?.careTeamPerson || false;
        }}
        selectedMember={patient instanceof Patient ? patient : undefined}
      />
    </>
  );
};

export default MemberEventAddModal;
