import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

import { PhoneType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberCommunityResource } from '@vestahealthcare/common/models';

import {
  Button,
  CollapseIcon,
  Colors,
  IconButton,
  Panel,
  PhoneLink,
  Table,
} from 'styleguide-v2';

type Props = {
  className?: string;
  communityResources: MemberCommunityResource[];
  onAdd: () => void;
  onDelete: (mcr: MemberCommunityResource) => void;
  onEdit: (mcr: MemberCommunityResource) => void;
};

const COMMUNITY_RESOURCE_COLUMNS = (
  { onEdit, onDelete }: Props,
  active: boolean,
) => [
  {
    headerName: translate('communityResources.table.type'),
    component: ({
      communityResource: { type, specialities },
    }: MemberCommunityResource) => (
      <>
        <p className="no-margin">{type.description}</p>
        {specialities?.length && (
          <p className="no-margin gray">
            {specialities?.map((s) => s.description).join(', ')}
          </p>
        )}
      </>
    ),
    width: 300,
  },
  {
    headerName: translate('communityResources.table.name'),
    field: 'communityResource.name',
    width: 200,
  },
  {
    headerName: translate('communityResources.table.phone'),
    component: ({
      communityResource: { phone, fax },
    }: MemberCommunityResource) =>
      phone || fax ? (
        <PhoneLink
          phone={phone || fax}
          type={
            phone ? PhoneType.BUSINESS.toString() : PhoneType.FAX.toString()
          }
        />
      ) : (
        ''
      ),
  },
  {
    headerName: translate('communityResources.table.address'),
    component: ({ communityResource: { address } }: MemberCommunityResource) =>
      address ? (
        <>
          <p className="no-margin">
            {[address?.address1, address.address2].filter(Boolean).join(', ')}
          </p>
          <p className="no-margin">
            {[address.city, address.state?.value, address.zipCode]
              .filter(Boolean)
              .join(', ')}
          </p>
        </>
      ) : (
        ''
      ),
  },
  {
    headerName: translate('communityResources.table.notes'),
    component: ({ notes }: MemberCommunityResource) => notes || '',
  },
  {
    headerName: ' ',
    component: (mcr: MemberCommunityResource) =>
      active ? (
        <div className="flex">
          <IconButton
            size="small"
            onClick={() => {
              onEdit(mcr);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(mcr)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ) : (
        <></>
      ),
    width: 50,
  },
];

const useStyles = makeStyles({
  table: {
    '&&.table-empty': {
      padding: '1rem 0',
      textAlign: 'start',
    },
  },
  showInactiveButton: {
    '&&': {
      display: 'flex',
      margin: '0.5rem 0 0 auto',
    },
  },
  inactiveRow: {
    '&&': {
      backgroundColor: `${Colors.lighterGray}!important`,
    },
  },
});

export const CommunityResourcesTable = (props: Props) => {
  const styles = useStyles();
  const { className, communityResources, onDelete, onEdit } = props;

  const activeCRs = communityResources.filter(({ active }) => active);
  const inactiveCRs = communityResources.filter(({ active }) => !active);

  const [showInactive, setShowInactive] = useState<boolean>(false);

  const activecolumns = useMemo(() => COMMUNITY_RESOURCE_COLUMNS(props, true), [
    onDelete,
    onEdit,
  ]);

  const inactivecolumns = useMemo(
    () => COMMUNITY_RESOURCE_COLUMNS(props, false),
    [onDelete, onEdit],
  );

  return (
    <>
      <Table
        className={classNames(className, styles.table)}
        config={{
          columns: activecolumns,
          compact: true,
          data: activeCRs,
          paginationOptions: [-1],
        }}
        empty
        emptyLink={translate('memberProfile.network.communityResourcesAdd')}
        onClickEmptyLink={props.onAdd}
      />
      {!!inactiveCRs?.length && (
        <>
          <Button
            className={styles.showInactiveButton}
            onClick={() => setShowInactive(!showInactive)}
            color="quaternary"
            size="xs"
          >
            {showInactive
              ? 'Hide Inactive Community Resources'
              : 'Show Inactive Community Resources'}
            <CollapseIcon open={showInactive} color="disabled" />
          </Button>
          <Panel.Collapse open={showInactive}>
            <Table
              className={classNames(className, styles.table)}
              config={{
                columns: inactivecolumns,
                compact: true,
                data: inactiveCRs,
                getRowClass: () => styles.inactiveRow,
                paginationOptions: [-1],
              }}
            />
          </Panel.Collapse>
        </>
      )}
    </>
  );
};

export default CommunityResourcesTable;
