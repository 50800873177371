import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  Enum,
  MemberContactTimeType,
  PhoneType,
  States,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Address,
  BaseEnum,
  CommunityResource,
  EventInterventionType,
  InsurancePlan,
  MemberContactInfoTimes,
  Organization,
  StateCities,
  UserPhone,
} from '@vestahealthcare/common/models';
import {
  isValidAddress,
  isValidCity,
  isValidMail,
  isValidURL,
  isValidZipCode,
} from '@vestahealthcare/common/utils/format';

import { MChip, PanelInfo, Select, TextArea, TextInput } from 'styleguide-v2';

import {
  DatesTimeItem,
  EditDateTimes,
} from 'dash/src/components/EditDateTimes';
import { EditPhones } from 'dash/src/components/EditPhones';
import { EditStateCities } from 'dash/src/components/EditStateCities';
import { CacheServices } from 'dash/src/services';
import {
  CommunityResourceCreateParams,
  fetchCommunityResourceSpecialities,
} from 'dash/src/services/CommunityResourcesServices';

type Props = {
  className?: string;
  communityResource?: CommunityResource;
  disableFields?: boolean;
  hideFields?: boolean;
  onChange: (ctp: Partial<CommunityResourceCreateParams>) => void;
  showNotes?: boolean;
  submitted: boolean;
};

export const InfoStep = ({
  className,
  communityResource,
  disableFields,
  hideFields,
  onChange,
  showNotes,
  submitted,
}: Props) => {
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [types, setTypes] = useState<BaseEnum[]>([]);
  const [specialities, setSpecialities] = useState<BaseEnum[]>([]);
  const [referrals, setReferrals] = useState<Organization[]>([]);
  const [interventionCategories, setInterventionCategories] = useState<
    BaseEnum[]
  >([]);
  const [interventionTypes, setInterventionTypes] = useState<
    EventInterventionType[]
  >([]);
  const [insurances, setInsurances] = useState<InsurancePlan[]>([]);

  const [type, setType] = useState<BaseEnum>();
  const [speciality, setSpeciality] = useState<BaseEnum[]>([]);
  const [name, setName] = useState<string>('');
  const [address1, setAddress1] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<States>();
  const [zip, setZIP] = useState<string>();
  const [phone, setPhone] = useState<UserPhone>();
  const [operationHours, setOperationHours] = useState<DatesTimeItem[]>([]);
  const [website, setWebsite] = useState<string>();
  const [pocName, setPOCName] = useState<string>();
  const [pocPhone, setPOCPhone] = useState<string>();
  const [pocPhoneExt, setPOCPhoneExt] = useState<string>();
  const [pocEmail, setPOCEmail] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [coverageArea, setCoverageArea] = useState<StateCities[]>([]);
  const [referral, setReferral] = useState<Organization[]>([]);
  const [interventionCategory, setInterventionCategory] = useState<BaseEnum>();
  const [interventionType, setInterventionType] = useState<
    EventInterventionType[]
  >([]);
  const [insurance, setInsurance] = useState<InsurancePlan[]>([]);

  const getInitialData = async () => {
    setLoadingData(true);
    const [
      types,
      referrals,
      interventionCategories,
      interventionTypes,
      insurances,
    ] = await Promise.all([
      CacheServices.getCommunityResourceTypes(),
      CacheServices.getOrganizations(),
      CacheServices.getEventInterventionCategories(),
      CacheServices.getEventInterventionTypes(),
      CacheServices.getInsurancePlans(),
    ]);
    setTypes(types);
    setReferrals(referrals.filter(({ isActiveReferral }) => isActiveReferral));
    setInterventionCategories(interventionCategories);
    setInterventionTypes(interventionTypes);
    setInsurances(insurances);

    setTimeout(() => {
      setLoadingData(false);
    }, 0);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getSpecialities = async () => {
    if (type) {
      const specialities = await fetchCommunityResourceSpecialities(type);
      setSpecialities(specialities);
      const specialityIds = communityResource?.specialities.map(({ id }) => id);
      setSpeciality(
        specialities?.filter(({ id }) => specialityIds?.includes(id)) || [],
      );
    }
  };

  useEffect(() => {
    getSpecialities();
  }, [type]);

  useEffect(() => {
    setLoadingData(false);
  }, []);

  useEffect(() => {
    setName(communityResource?.name || '');
    setType(communityResource?.type);
    setSpeciality(communityResource?.specialities || []);
    setAddress1(communityResource?.address?.address1);
    setAddress2(communityResource?.address?.address2);
    setCity(communityResource?.address?.city);
    setState(communityResource?.address?.state);
    setZIP(communityResource?.address?.zipCode);
    if (communityResource?.phone) {
      setPhone(
        new UserPhone({
          phone: {
            number: communityResource?.phone,
            type: PhoneType.BUSINESS.value,
          },
        }),
      );
    } else if (communityResource?.fax) {
      setPhone(
        new UserPhone({
          phone: {
            number: communityResource?.fax,
            type: PhoneType.FAX.value,
          },
        }),
      );
    } else {
      setPhone(undefined);
    }
    setOperationHours(
      communityResource?.getHoursOfOperation() as DatesTimeItem[],
    );
    setWebsite(communityResource?.website);
    setPOCName(communityResource?.pocName);
    setPOCPhone(communityResource?.pocNumber);
    setPOCPhoneExt(communityResource?.pocExtension);
    setPOCEmail(communityResource?.pocEmail);
    setNotes(communityResource?.notes);
    setCoverageArea(communityResource?.states || []);
    setReferral(communityResource?.referrals || []);
    setInterventionCategory(
      (communityResource?.interventions?.length &&
        communityResource?.interventions[0]?.category) ||
        undefined,
    );
    setInterventionType(communityResource?.interventions || []);
    setInsurance(communityResource?.insurances || []);
  }, [communityResource]);

  const validateAddress = () =>
    !(address1 || address2 || city || state || zip) ||
    isValidAddress({
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state,
      zipCode: zip || '',
    } as Address);

  useEffect(() => {
    const submitAddress = {
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state: state?.value,
      zipCode: zip || '',
    } as Address;

    const times: MemberContactInfoTimes[] = [];
    operationHours?.forEach(
      ({ dates, type, startTime, endTime }: DatesTimeItem) => {
        if (!dates?.length) {
          if (type) {
            times.push({
              type: type || MemberContactTimeType.ANY_TIME,
              startTime: startTime ? moment(startTime.getTime()) : undefined,
              endTime: endTime ? moment(endTime.getTime()) : undefined,
            } as MemberContactInfoTimes);
          }
        } else {
          dates.forEach((day) => {
            times.push({
              dayOfWeek: day,
              type: type || MemberContactTimeType.ANY_TIME,
              startTime: startTime ? moment(startTime.getTime()) : undefined,
              endTime: endTime ? moment(endTime.getTime()) : undefined,
            } as MemberContactInfoTimes);
          });
        }
      },
    );

    onChange({
      address: submitAddress,
      fax: phone?.phone?.type === PhoneType.FAX ? phone?.phone?.number : '',
      insurances: insurance,
      interventions: interventionType,
      name,
      notes,
      phone:
        phone?.phone?.type === PhoneType.BUSINESS ? phone?.phone?.number : '',
      pocEmail,
      pocName,
      pocNumber: pocPhone,
      pocExtension: pocPhoneExt,
      referrals: referral,
      specialities: speciality,
      states: coverageArea,
      times,
      type,
      website,
    });
  }, [
    address1,
    address2,
    city,
    zip,
    state,
    phone,
    insurance,
    interventionType,
    name,
    notes,
    pocEmail,
    pocName,
    pocPhone,
    pocPhoneExt,
    referral,
    speciality,
    coverageArea,
    operationHours,
    website,
  ]);

  const currentTypes = interventionTypes.filter(
    (item) => item?.category?.id === interventionCategory?.id && item.active,
  );

  return (
    <div className={classNames(className, 'grid-wrapper fit')}>
      {!!communityResource?.id &&
        !communityResource?.states?.length &&
        !coverageArea?.filter(({ state }) => !!state)?.length && (
          <PanelInfo className="grid-span-12" type="warning">
            {translate('communityResources.modal.coverageAreaWarning')}
          </PanelInfo>
        )}
      {!hideFields && (
        <>
          <Select
            className="grid-span-6"
            data-cy="community-resources-modal-types"
            disabled={disableFields}
            error={submitted && !type}
            getItemLabel={({ description }: BaseEnum) => description}
            items={types}
            loading={loadingData}
            label={translate('communityResources.modal.type')}
            onChange={setType}
            value={type}
            required
          />

          <Select
            className="grid-span-6"
            data-cy="community-resources-modal-specialities"
            disabled={!type || disableFields}
            error={submitted && !speciality}
            getItemLabel={({ description }: BaseEnum) => description}
            items={specialities}
            label={translate('communityResources.modal.speciality')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setSpeciality}
            value={speciality}
            required
          />

          <TextInput
            className="grid-span-12"
            data-cy="community-resources-modal-name"
            disabled={disableFields}
            error={submitted && !name}
            label={translate('communityResources.modal.name')}
            onChange={(val) => setName(val || '')}
            value={name}
            required
          />

          <TextInput
            className="grid-span-9"
            data-cy="community-resources-modal-address-1"
            disabled={disableFields}
            error={submitted && !validateAddress()}
            label={translate('communityResources.modal.address1')}
            onChange={setAddress1}
            value={address1}
          />
          <TextInput
            className="grid-span-3"
            data-cy="community-resources-modal-address-2"
            disabled={disableFields}
            label={translate('communityResources.modal.address2')}
            onChange={setAddress2}
            value={address2}
          />
          <TextInput
            className="grid-span-6"
            data-cy="community-resources-modal-city"
            disabled={disableFields}
            error={
              (submitted &&
                city &&
                !isValidCity(city) &&
                translate('global.cityFormatError')) ||
              (submitted && !validateAddress())
            }
            label={translate('communityResources.modal.city')}
            onChange={setCity}
            value={city}
          />

          <Select
            className="grid-span-3"
            data-cy="community-resources-modal-state"
            disabled={disableFields}
            error={submitted && !validateAddress()}
            getItemLabel={(item: Selectable) => item.value}
            items={Enum.toSelectable(States.asArray)}
            label={translate('communityResources.modal.state')}
            onChange={(val?: Selectable) => {
              const state = val ? States.byKey[val.value] : undefined;
              setState(state);
            }}
            value={state ? Enum.toSelectable([state])[0] : undefined}
          />

          <TextInput
            className="grid-span-3"
            data-cy="community-resources-modal-zip"
            disabled={disableFields}
            error={
              (submitted &&
                zip &&
                !isValidZipCode(zip) &&
                translate('global.zipFormatError')) ||
              (submitted && !validateAddress())
            }
            label={translate('communityResources.modal.zip')}
            onChange={setZIP}
            value={zip}
          />

          <EditPhones
            className="grid-span-12"
            disabled={disableFields}
            multiple={false}
            phones={phone ? [phone] : []}
            onChange={(items) => {
              setPhone(items[0]);
            }}
            hideCellType
            hideHomeType
            required
            showBusinessType
            submitted={submitted}
          />

          <EditDateTimes
            className="grid-span-12"
            disabled={disableFields}
            defaultValue={operationHours}
            title={translate('communityResources.modal.date')}
            onChange={setOperationHours}
            showType
            size="s"
            submitted={submitted}
          />

          <TextInput
            className="grid-span-12"
            data-cy="community-resources-modal-website"
            disabled={disableFields}
            error={
              submitted && website && !isValidURL(website)
                ? translate('common.errors.invalidURL')
                : undefined
            }
            label={translate('communityResources.modal.website')}
            onChange={setWebsite}
            value={website}
          />
        </>
      )}
      <TextInput
        className="grid-span-6"
        data-cy="community-resources-modal-poc-name"
        label={translate('communityResources.modal.pocName')}
        onChange={setPOCName}
        value={pocName}
      />

      <TextInput
        className="grid-span-6"
        data-cy="community-resources-modal-poc-email"
        error={
          submitted && pocEmail && !isValidMail(pocEmail)
            ? translate('common.errors.invalidEmail')
            : undefined
        }
        label={translate('communityResources.modal.pocEmail')}
        onChange={setPOCEmail}
        value={pocEmail}
      />

      <TextInput
        className="grid-span-6"
        data-cy="community-resources-modal-poc-phone"
        label={translate('communityResources.modal.pocPhone')}
        onChange={setPOCPhone}
        value={pocPhone}
      />

      <TextInput
        className="grid-span-6"
        data-cy="community-resources-modal-poc-phone-ext"
        label={translate('communityResources.modal.pocPhoneExt')}
        onChange={setPOCPhoneExt}
        value={pocPhoneExt}
      />

      {showNotes && (
        <TextArea
          className="grid-span-12"
          data-cy="community-resources-modal-notes"
          label={translate('communityResources.modal.notes')}
          onChange={(notes) => setNotes(notes || '')}
          rows={3}
          value={notes}
        />
      )}

      {!hideFields && (
        <>
          <EditStateCities
            className="grid-span-12"
            data-cy="community-resources-modal-coverage-area"
            disabled={disableFields}
            onChange={setCoverageArea}
            value={coverageArea}
            required
            submitted={submitted}
          />

          <Select
            className="grid-span-12"
            data-cy="community-resources-modal-referrals"
            disabled={disableFields}
            getItemLabel={(item: Organization) => item.name}
            items={referrals}
            label={translate('communityResources.modal.referrals')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setReferral}
            value={referral}
          />
          <div className="grid-span-12 flex gap wrap">
            {referral?.map((ref, index) => (
              <MChip
                key={`issue-type-${ref?.id}`}
                label={ref?.name}
                onDelete={
                  disableFields
                    ? undefined
                    : () => {
                        referral?.splice(index, 1);
                        setReferral([...referral]);
                      }
                }
                color="primary"
              />
            ))}
          </div>

          <Select
            className="grid-span-12"
            disableClearable
            disabled={disableFields}
            getItemLabel={({ description }: BaseEnum) => description}
            items={interventionCategories}
            loading={loadingData}
            label={translate('communityResources.modal.categoryType')}
            onChange={setInterventionCategory}
            value={interventionCategory}
          />

          {interventionCategory && (
            <Select
              className="grid-span-12"
              disabled={disableFields}
              getItemLabel={({ description }: EventInterventionType) =>
                description
              }
              filterOptions={(data, options) =>
                data.filter(({ description }) =>
                  description
                    .toLocaleLowerCase()
                    .includes(options.inputValue?.toLocaleLowerCase()),
                )
              }
              items={currentTypes}
              label={interventionCategory?.description}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={(types: EventInterventionType[]) => {
                currentTypes.forEach((item) => {
                  const findIdx = types.findIndex((it) => it.id === item.id);
                  const valueIdx = interventionType.findIndex(
                    (it) => it.id === item.id,
                  );
                  if (findIdx !== -1) {
                    if (valueIdx === -1) {
                      setInterventionType([...interventionType, item]);
                    }
                  } else if (valueIdx !== -1) {
                    interventionType.splice(valueIdx, 1);
                    setInterventionType([...interventionType]);
                  }
                });
              }}
              value={interventionType}
            />
          )}
          <div className="grid-span-12 flex gap wrap">
            {interventionType?.map((it, index) => (
              <MChip
                key={`intervention-type-${it?.id}`}
                label={it?.description}
                onDelete={
                  disableFields
                    ? undefined
                    : () => {
                        interventionType?.splice(index, 1);
                        setInterventionType([...interventionType]);
                      }
                }
                color="primary"
              />
            ))}
          </div>

          <Select
            className="grid-span-12"
            data-cy="community-resources-modal-insurance"
            disabled={disableFields}
            getItemLabel={(item: Organization) => item.name}
            items={insurances}
            label={translate('communityResources.modal.insurances')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setInsurance}
            value={insurance}
          />
          <div className="grid-span-12 flex gap wrap">
            {insurance?.map((ref, index) => (
              <MChip
                key={`issue-type-${ref?.id}`}
                label={ref?.name}
                onDelete={
                  disableFields
                    ? undefined
                    : () => {
                        insurance?.splice(index, 1);
                        setInsurance([...insurance]);
                      }
                }
                color="primary"
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
