import { Language } from '../enums';
import { MemberProgramExtension } from './MemberProgramExtension';
import { makeArray } from './Model';
import { PatientBase } from './PatientBase';

export class EventMember extends PatientBase {
  language?: Language;

  programExtensions: MemberProgramExtension[] = [];

  state?: string;

  constructor(obj: any) {
    super(obj);
    this.language = obj.language ? Language.byKey[obj.language] : undefined;
    this.programExtensions = makeArray(
      obj.programExtensions || obj.memberProgramExtensions,
      MemberProgramExtension,
    );
    this.state = obj.state;
  }
}

export default EventMember;
