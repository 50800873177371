import moment, { Moment } from 'moment';

import { ProgramExtensionStatus } from '../enums';
import { DATE_FORMAT } from '../utils/constants';

export class MemberDashboardBHI {
  programExtensionStatus: ProgramExtensionStatus;

  bhiTotalMinutes = 0;

  lastCompletedDate?: Moment;

  lastCompletedEmployee = '';

  lastCarePlanDate?: Moment;

  lastCarePlanEmployee = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.programExtensionStatus =
      ProgramExtensionStatus.byKey[obj.programExtensionStatus];
    this.lastCompletedDate = obj.lastCompletedDate
      ? moment(obj.lastCompletedDate, DATE_FORMAT)
      : undefined;
    this.lastCarePlanDate = obj.lastCarePlanDate
      ? moment(obj.lastCarePlanDate, DATE_FORMAT)
      : undefined;
  }
}

export default MemberDashboardBHI;
